import React from 'react'
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import moment from 'moment'
import { PaperModal } from '../../../components/PaperModal'
import { FHWScreen } from '../../../components/FHWScreen'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { formikTextFieldArgs } from '../../../utils/formUtils'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import {
  useGetAppointmentQuery,
  useRegisterForAppointmentMutation,
  useRequestChangeForAppointmentMutation,
} from '../../../generated/graphql-hooks'
import { AppointmentStatus } from '../../../generated/graphql-types'
import { useNotifications } from '../../../utils/notificationUtils'

interface RequestChangeForAppointmentProps {
  uuid: string
}

interface RequestChangeForAppointmentGuardProps {
  uuid: string
  children: React.ReactElement
}

export const RequestChangeForAppointmentGuard: React.FC<RequestChangeForAppointmentGuardProps> = (props) => {
  const query = useGetAppointmentQuery({ variables: { uuid: props.uuid } })
  const appointment = query.data?.getAppointment
  if (query.loading) {
    return <LoadingBackdrop loading={query.loading} />
  }
  if (!appointment) {
    return (
      <FHWScreen>
        <PaperModal>
          <Typography>Ihr Termin konnte nicht gefunden werden.</Typography>
        </PaperModal>
      </FHWScreen>
    )
  }
  if (appointment?.status !== AppointmentStatus.Registered) {
    return (
      <FHWScreen>
        <PaperModal>
          <Typography>
            Sie können im Moment keine Änderung beantragen. Stellen Sie sicher, dass Sie sich registriert haben, der
            Termin nicht abgesagt wurde und noch kein anderer Änderungsantrag gestellt wurde.
          </Typography>
        </PaperModal>
      </FHWScreen>
    )
  }
  return props.children
}

interface ChangeAppointmentProps {
  uuid: string
}
export const RequestChangeForAppointmentPage: React.FC<ChangeAppointmentProps> = (props) => {
  const { splashError, splashSuccess } = useNotifications()

  const [requestChangeForAppointment, { data, loading, error }] = useRequestChangeForAppointmentMutation({
    refetchQueries: ['getAppointments', 'getAppointment'],
  })

  const validationSchema = yup.object({
    reason: yup.string().required('Bitte geben Sie einen Grund an.'),
  })

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      requestChangeForAppointment({
        variables: {
          changeRequest: {
            uuid: props.uuid,
            comment: values.reason,
          },
        },
      })
        .then(() =>
          splashSuccess('Änderungsantrag wurde erfolgreich versendet. Sie können das Fenster jetzt schließen.')
        )
        .catch((err) => {
          console.error(err)
          splashError('Änderungsantrag konnte nicht versendet werden.')
        })
    },
  })

  const query = useGetAppointmentQuery({ variables: { uuid: props.uuid } })
  const appointment = query.data?.getAppointment
  if (query.loading) {
    return <LoadingBackdrop loading={query.loading} />
  }

  const date = moment(appointment?.date).format('DD.MM.YYYY')
  const time = moment(appointment?.date).format('HH:mm')
  const manufacturer = appointment?.salesRepresentative?.manufacturer?.name
  const park = appointment?.salesRepresentative?.park?.name

  return (
    <FHWScreen>
      <PaperModal>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h5'}>Mein Termin</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body1'}>
                Ich habe einen Termin am {date} um {time} bei {manufacturer} im Park {park}.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline={true}
                fullWidth={true}
                rows={5}
                variant={'outlined'}
                placeholder={'Grund zur Änderung'}
                {...formikTextFieldArgs('reason', formik)}
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={{ textAlign: 'right' }}>
                <Button type="submit" variant={'contained'} color={'primary'}>
                  Neuen Termin vereinbaren
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
        {/*<LoadingBackdrop loading={loading} />*/}
      </PaperModal>
    </FHWScreen>
  )
}
