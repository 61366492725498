import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { useCancelAppointmentMutation } from '../../../generated/graphql-hooks'
import { deSerializeDate, formatDate, formatTime } from '../../../utils/dateUtils'
import { useNotifications } from '../../../utils/notificationUtils'
import { useAuthData } from '../../../utils/auth/AuthenticationProvider'
import { Appointment } from '../../../generated/graphql-types'
import { DialogProps } from './UpdateAppointmentDialog'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'

export const CancelAppointmentDialog: React.FC<DialogProps<Appointment>> = (props) => {
  const { showError, showSuccess } = useNotifications()

  const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
    refetchQueries: ['getAppointments', 'getAppointment'],
  })

  const authData = useAuthData()
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      props.handleClose()
      cancelAppointmentMutation({
        variables: {
          uuid: props.selectedRow?.uuid || '',
        },
      })
        .then(() => showSuccess('Termin wurde erfolgreich abgesagt.'))
        .catch((err) => {
          console.error(err)
          showError('Termin konnte nicht abgesagt werden.')
        })
    },
  })

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle
            title={'Termin Absagen'}
            subtitle={[
              formatDate(deSerializeDate(props.selectedRow?.date || '')) || '',
              'um',
              formatTime(deSerializeDate(props.selectedRow?.date || '')),
              'Uhr.',
            ].join(' ')}
          />
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant={'caption'}>Email:</Typography>
                <Typography>{props.selectedRow?.visitor?.email || ''}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Absagen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
