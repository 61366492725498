import React, { useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  useCreateSalesRepresentativeMutation,
  useGetManufacturersQuery,
  useGetParksQuery,
} from '../../../generated/graphql-hooks'
import { useNotifications } from '../../../utils/notificationUtils'
import { RemoteSelect, SelectOption } from '../../../components/RemoteSelect'
import { formikTextFieldArgs } from '../../../utils/formUtils'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'

export const CreateSalesRepresentativeButton: React.FC = () => {
  const { showError, showSuccess } = useNotifications()
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const validationSchema = yup.object({
    email: yup.string().required('Email Adresse ist erforderlich'),
    firstName: yup.string().required('Vorname ist erforderlich'),
    name: yup.string().required('Nachname ist erforderlich'),
    manufacturerUuid: yup.string().required('Hersteller ist erforderlich'),
    parkUuid: yup.string().required('Park ist erforderlich'),
  })

  const [createSalesRepresentativeMutation, { data, loading, error }] = useCreateSalesRepresentativeMutation({
    refetchQueries: ['getSalesRepresentatives'],
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      name: '',
      manufacturerUuid: '',
      parkUuid: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClose()
      createSalesRepresentativeMutation({
        variables: {
          createSalesRepresentative: {
            email: values.email,
            firstName: values.firstName,
            name: values.name,
            manufacturerUuid: values.manufacturerUuid,
            parkUuid: values.parkUuid,
          },
        },
      })
        .then(() => {
          showSuccess('Handelsvertreter wurde erfolgreich erstellt.')
          formik.resetForm()
        })
        .catch((err) => {
          console.log(err)
          showError('Handelsvertreter konnte nicht erstellt werden.')
        })
    },
  })

  const useManufacturersSelectQuery = (search?: string) =>
    useGetManufacturersQuery({
      variables: { pageQuery: { page: 0, pageSize: 10, search } },
    })

  const useParksSelectQuery = (search?: string) =>
    useGetParksQuery({
      variables: { pageQuery: { page: 0, pageSize: 10, search } },
    })

  return (
    <>
      <Button variant={'contained'} color={'primary'} onClick={handleClickOpen}>
        Handelsvertreter anlegen
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle title={'Neuen Handelsvertreter anlegen'} />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField label="Email Adresse" {...formikTextFieldArgs('email', formik)} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Vorname" {...formikTextFieldArgs('firstName', formik)} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Nachname" {...formikTextFieldArgs('name', formik)} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <RemoteSelect
                  label={'Hersteller'}
                  useQuery={useManufacturersSelectQuery}
                  queryResultToOptions={(queryResult) =>
                    queryResult?.getManufacturers.manufacturers.map<SelectOption>((x) => ({
                      label: x?.name ?? '',
                      value: x?.uuid ?? '',
                    })) ?? []
                  }
                  formik={formik}
                  name={'manufacturerUuid'}
                />
              </Grid>
              <Grid item xs={12}>
                <RemoteSelect
                  label={'Park'}
                  useQuery={useParksSelectQuery}
                  queryResultToOptions={(queryResult) =>
                    queryResult?.getParks.parks.map<SelectOption>((x) => ({
                      label: x?.name ?? '',
                      value: x?.uuid ?? '',
                    })) ?? []
                  }
                  formik={formik}
                  name={'parkUuid'}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Anlegen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
