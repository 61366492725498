import FullCalendar, { EventContentArg } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useGetTicketsQuery } from '../../../../../generated/graphql-hooks'
import React, { useEffect, useRef } from 'react'
import { deSerializeDate, getBeginDate, incrementDate, serializeDate } from '../../../../../utils/dateUtils'
import { Ticket, TicketType } from '../../../../../generated/graphql-types'
import { statusColorMap } from '../../../../../utils/entities/ticketUtils'
import { TicketsViewProps } from './index'
import { DEFAULT_POLLING_INTERVAL } from '../../../../../utils/apolloUtils'

interface EventInput {
  id: string
  title: string
  start: Date
  type: string
}

export type ViewMode = 'day' | 'week'

export const TicketsCalendar: React.FC<TicketsViewProps> = (props) => {
  const calendarRef = useRef<FullCalendar | null>(null)
  useEffect(() => {
    calendarRef.current?.getApi().changeView(props.view === 'day' ? 'timeGridDay' : 'timeGridWeek')
    calendarRef.current?.getApi().gotoDate(props.selectedDate)
  }, [props.view, props.selectedDate])

  const data = useGetTicketsQuery({
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: {
      pageQuery: { page: 0, pageSize: 100 },
      dateQuery: {
        beginDate: serializeDate(getBeginDate(props.selectedDate, props.view)),
        endDate: serializeDate(incrementDate(getBeginDate(props.selectedDate, props.view), props.view, false)),
      },
    },
  })
  const tickets = data.data?.getTickets?.tickets ?? ([] as Ticket[])

  const events: EventInput[] = tickets.map((value) => {
    const date = deSerializeDate(value.expectedEntryDate || '')
    return {
      id: value.uuid,
      start: date,
      allDay: value.type === TicketType.Online,
      title: value.visitor?.name ?? value.visitor?.email ?? '',
      type: value.type || '?',
      numVisitors: (value.visitor?.numAdults ?? 1) + (value.visitor?.numChildren ?? 0),
      salesRepresentativesAndManufacturer: value.visitor?.appointments
        ?.map(
          (appointment) =>
            `${appointment?.salesRepresentative?.name} (${appointment?.salesRepresentative?.manufacturer?.name})`
        )
        .join(', '),
    }
  })

  const renderEventContent = function (eventContent: EventContentArg) {
    const color = statusColorMap[eventContent.event._def.extendedProps['type'] as TicketType]
    eventContent.backgroundColor = color
    eventContent.borderColor = color
    const numVisitors = eventContent.event._def.extendedProps['numVisitors']
    const salesRepresentativesAndManufacturer =
      eventContent.event._def.extendedProps['salesRepresentativesAndManufacturer']
    const numVisitorsString =
      numVisitors > 1 ? `${numVisitors} Personen` : numVisitors === 1 ? '1 Person' : 'Keine Angabe zur Personenzahl'
    return (
      <>
        <b>{eventContent.event.title} </b>
        {salesRepresentativesAndManufacturer}
        <i>{numVisitorsString}</i>
      </>
    )
  }
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        headerToolbar={false}
        initialView={props.view === 'day' ? 'timeGridDay' : 'timeGridWeek'}
        firstDay={1} // Mo-So
        weekends={true}
        allDaySlot={true}
        dayHeaderFormat={{ month: 'numeric', day: 'numeric', weekday: 'long' }}
        slotLabelInterval="02:00" // show only even hours
        slotLabelFormat={{ hour: 'numeric', omitZeroMinute: false }}
        slotMinTime="08:00"
        slotMaxTime="20:30"
        allDayText={'Online-Tickets'}
        events={events}
        eventContent={renderEventContent}
        locale={'de'}
        ref={(ref) => (calendarRef.current = ref)}
      />
    </div>
  )
}
