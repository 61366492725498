import React, { useState } from 'react'
import { useGetCenterManagersQuery } from '../../../generated/graphql-hooks'
import { GenericDataTable, usePageQueryState } from '../../../components/GenericDataTable'
import { Delete, Edit } from '@material-ui/icons'
import { CenterManager } from '../../../generated/graphql-types'
import { UpdateCenterManagerDialog } from '../dialogs/UpdateCenterManagerDialog'
import { DeleteCenterManagerDialog } from '../dialogs/DeleteCenterManagerDialog'
import { useTableState } from './useTableState'
import { DEFAULT_POLLING_INTERVAL } from '../../../utils/apolloUtils'

export const CenterManagersTab: React.FC = () => {
  const [queryState, setQueryState] = usePageQueryState()
  const data = useGetCenterManagersQuery({
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: {
      pageQuery: queryState,
    },
  })
  const CenterManagers = data.data?.getCenterManagers?.centerManagers ?? []
  const {
    editDialogOpen,
    setEditDialogOpen,
    cancelDialogOpen,
    setCancelDialogOpen,
    selectedRow,
    setSelectedRow,
  } = useTableState<CenterManager>()

  return (
    <>
      <GenericDataTable
        data={CenterManagers}
        onPageQueryChange={setQueryState}
        pageQuery={queryState}
        pageInfo={data.data?.getCenterManagers.pageInfo}
        isLoading={data.loading}
        columns={[
          { title: 'ManagerID', field: 'uuid', hidden: true },
          { title: 'Vorname', field: 'firstName' },
          { title: 'Nachname', field: 'name' },
          { title: 'Email', field: 'email' },
          {
            title: 'Park',
            field: 'parks',
            render: (data) => data?.parks?.map((park) => park?.name).join(', ') || '',
          },
        ]}
        title={'Center Manager'}
        actions={[
          {
            icon: Delete,
            tooltip: 'Löschen',
            onClick: (event, rowData) => {
              setCancelDialogOpen(true)
              setSelectedRow(rowData as CenterManager)
            },
          },
          {
            icon: Edit,
            tooltip: 'Bearbeiten',
            onClick: (event, rowData) => {
              setEditDialogOpen(true)
              setSelectedRow(rowData as CenterManager)
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
      <UpdateCenterManagerDialog
        selectedRow={selectedRow}
        open={editDialogOpen}
        handleClose={() => setEditDialogOpen(false)}
      />
      <DeleteCenterManagerDialog
        selectedRow={selectedRow}
        open={cancelDialogOpen}
        handleClose={() => setCancelDialogOpen(false)}
      />
    </>
  )
}
