import { getEnvironment } from '../envUtils'
import Printd from 'printd'
import { TicketType } from '../../generated/graphql-types'

export const statusColorMap: { [key in TicketType]: string } = {
  [TicketType.Online]: 'gray',
  [TicketType.Appointment]: 'orange',
  [TicketType.GiftCard]: 'yellow',
  [TicketType.UnknownPark]: 'white',
  [TicketType.Unlimited]: 'blue',
  [TicketType.Cash]: 'green',
}

// TODO: Duplicated in backend, could possibly removed from frontend
export const maxAllowedEntries: Record<TicketType, number> = {
  [TicketType.Appointment]: 1,
  [TicketType.Cash]: 2,
  [TicketType.GiftCard]: 1,
  [TicketType.Online]: 2,
  [TicketType.UnknownPark]: 1,
  [TicketType.Unlimited]: Infinity,
}

const throwResponseError = (status: string | number, body?: string) => {
  throw new Error(`Error fetching from backend, status: ${status}, message: "${body || '<empty response>'}"`)
}

// Keep single instance to ensure max one hidden iframe gets appended to the dom
const printdInstance = new Printd()

export const printTicket = async (uuid: string, token: string): Promise<void> => {
  return fetch(`${getEnvironment().RestEndpoint}/internal/print/${uuid}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
    .then(async (res) => {
      let body = ''
      try {
        body = await res.text()
      } catch (bodyParseError) {
        throwResponseError(res.status)
      }
      if (!res.ok) {
        throwResponseError(res.status, body)
      }
      return body
    })
    .then((body) => {
      const tempContainer = document.createElement('div')
      tempContainer.innerHTML = body
      const printCss = `
      @media print {
        @page { margin: 0; }
        body { margin: 1.6cm; }
      }`
      printdInstance.print(tempContainer, [printCss])
    })
}
