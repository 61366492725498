import React, { useState } from 'react'
import { Edit, HighlightOff, MailRounded as Mail } from '@material-ui/icons'
import { useGetAppointmentsQuery, useResendAppointmentInvitationMutation } from '../../../../../generated/graphql-hooks'
import { GenericDataTable, usePageQueryState } from '../../../../../components/GenericDataTable'
import {
  deSerializeDate,
  formatDate,
  formatTime,
  getBeginDate,
  incrementDate,
  serializeDate,
} from '../../../../../utils/dateUtils'
import { AppointmentsCalendarProps } from './AppointmentsCalendar'
import { Appointment, AppointmentStatus } from '../../../../../generated/graphql-types'
import { CancelAppointmentDialog } from '../../../dialogs/CancelAppointmentDialog'
import { useTableState } from '../../useTableState'
import { UpdateAppointmentDialog } from '../../../dialogs/UpdateAppointmentDialog'
import { useNotifications } from '../../../../../utils/notificationUtils'
import { LoadingBackdrop } from '../../../../../components/LoadingBackdrop'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../../utils/routeUtils'
import { AppointmentStatusBadge } from '../../../../../components/AppointmentStatusBadge'
import { DEFAULT_POLLING_INTERVAL } from '../../../../../utils/apolloUtils'

export const AppointmentsTable: React.FC<AppointmentsCalendarProps> = (props) => {
  const { showSuccess, showError } = useNotifications()
  // Query data
  const [queryState, setQueryState] = usePageQueryState()
  const history = useHistory()
  const query = useGetAppointmentsQuery({
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: {
      pageQuery: queryState,
      dateQuery: {
        beginDate: serializeDate(getBeginDate(props.selectedDate, props.view)),
        endDate: serializeDate(incrementDate(getBeginDate(props.selectedDate, props.view), props.view, false)),
      },
      showAll: props.showAllAppointments,
    },
  })
  const [
    resendAppointmentInvitation,
    { loading: resendAppointmentInvitationLoading },
  ] = useResendAppointmentInvitationMutation({ refetchQueries: ['getAppointments'] })
  const Appointments = query.data?.getAppointments?.appointments ?? []

  const handleResendInvitation = (appointment: Appointment) => {
    resendAppointmentInvitation({
      variables: {
        uuid: appointment.uuid,
      },
    })
      .then(() => showSuccess('Die Termineinladung wurde erfolgreich neu versendet'))
      .catch((error) => {
        console.error(error)
        showError('Fehler beim Versenden der Einladung')
      })
  }

  const {
    editDialogOpen,
    setEditDialogOpen,
    cancelDialogOpen,
    setCancelDialogOpen,
    selectedRow,
    setSelectedRow,
  } = useTableState<Appointment>()

  return (
    <>
      <GenericDataTable
        data={Appointments}
        pageInfo={query.data?.getAppointments.pageInfo}
        pageQuery={queryState}
        onPageQueryChange={setQueryState}
        isLoading={query.loading}
        columns={[
          { title: 'ID', field: 'uuid', hidden: true },
          { title: 'Datum', field: 'date', render: (data) => formatDate(deSerializeDate(data.date || '')) },
          { title: 'Uhrzeit', field: 'time', render: (data) => formatTime(deSerializeDate(data.date || '')) },
          { title: 'Email', field: 'visitor.email' },
          { title: 'Vorname', field: 'visitor.firstName' },
          { title: 'Nachname', field: 'visitor.name' },
          {
            title: 'Personen',
            field: 'numVisitors',
            render: (data) => {
              return (data.visitor?.numAdults || 0) + (data.visitor?.numChildren || 0) > 0
                ? (data.visitor?.numAdults || 0) + (data.visitor?.numChildren || 0)
                : 'keine Angabe'
            },
          },
          {
            title: 'Status',
            field: 'status',
            render: (data) => <AppointmentStatusBadge status={data.status} />,
          },
        ]}
        title={'Termine'}
        actions={[
          (rowData) => ({
            icon: HighlightOff,
            tooltip: 'Absagen',
            onClick: (event, rowData) => {
              setCancelDialogOpen(true)
              setSelectedRow(rowData as Appointment)
            },
            disabled: rowData.status === AppointmentStatus.Missed || rowData.status === AppointmentStatus.Canceled,
          }),
          (rowData) => ({
            icon: Edit,
            tooltip: 'Bearbeiten',
            onClick: (event, rowData) => {
              history.push(routes.authenticated.Appointments.Detail.get((rowData as Appointment).uuid))
              // setEditDialogOpen(true)
              // setSelectedRow(rowData as Appointment)
            },
            disabled: rowData.status === AppointmentStatus.Missed || rowData.status === AppointmentStatus.Canceled,
          }),
          (rowData) => ({
            icon: Mail,
            tooltip: 'Einladung neu senden',
            onClick: (event, rowData) => {
              handleResendInvitation(rowData as Appointment)
            },
            disabled:
              resendAppointmentInvitationLoading ||
              rowData.status === AppointmentStatus.Missed ||
              rowData.status === AppointmentStatus.Canceled ||
              rowData.status === AppointmentStatus.Registered ||
              rowData.status === AppointmentStatus.ChangeRequested,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
      <LoadingBackdrop loading={resendAppointmentInvitationLoading} />
      <UpdateAppointmentDialog
        selectedRow={selectedRow}
        open={editDialogOpen}
        handleClose={() => setEditDialogOpen(false)}
      />
      <CancelAppointmentDialog
        selectedRow={selectedRow}
        open={cancelDialogOpen}
        handleClose={() => setCancelDialogOpen(false)}
      />
    </>
  )
}
