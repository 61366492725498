import React from 'react'
import { useReportStyles } from './index'

export type RevenueReportResult = {
  name: 'REVENUE'
  report: {
    label: string
    clusterDate: string
    data: {
      parkName: string
      revenue: string
    }[]
  }[]
}

export const RevenueReport: React.FC<{ reportResult: RevenueReportResult }> = ({ reportResult: { report } }) => {
  const classes = useReportStyles()
  return (
    <>
      <h2>Umsatz Report verkaufte Online Tickets</h2>
      {report.map((cluster) => (
        <div key={cluster.clusterDate}>
          <h3>{cluster.label}</h3>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>Parks</th>
                <th>Umsatz</th>
              </tr>
            </thead>
            <tbody>
              {cluster.data.map((park) => (
                <tr key={park.parkName}>
                  <td>{park.parkName}</td>
                  <td>{park.revenue} &euro;</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  )
}
