import FullCalendar, { EventContentArg } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { useGetAppointmentsQuery } from '../../../../../generated/graphql-hooks'
import React, { useEffect, useRef } from 'react'
import { Appointment } from '../../../../../generated/graphql-hooks'
import { deSerializeDate, getBeginDate, incrementDate, serializeDate } from '../../../../../utils/dateUtils'
import { AppointmentStatus } from '../../../../../generated/graphql-types'
import { statusColorMap } from '../../../../../utils/entities/appointmentUtils'
import { DEFAULT_POLLING_INTERVAL } from '../../../../../utils/apolloUtils'

interface EventInput {
  id: string
  title: string
  start: Date
  status: string
}

export type ViewMode = 'day' | 'week'

export interface AppointmentsCalendarProps {
  view: ViewMode
  selectedDate: Date
  showAllAppointments: boolean
}

export const AppointmentsCalendar: React.FC<AppointmentsCalendarProps> = (props) => {
  const calendarRef = useRef<FullCalendar | null>(null)
  useEffect(() => {
    calendarRef.current?.getApi().changeView(props.view === 'day' ? 'timeGridDay' : 'timeGridWeek')
    calendarRef.current?.getApi().gotoDate(props.selectedDate)
  }, [props.view, props.selectedDate])

  const data = useGetAppointmentsQuery({
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: {
      pageQuery: { page: 0, pageSize: 100 },
      dateQuery: {
        beginDate: serializeDate(getBeginDate(props.selectedDate, props.view)),
        endDate: serializeDate(incrementDate(getBeginDate(props.selectedDate, props.view), props.view, false)),
      },
      showAll: props.showAllAppointments,
    },
  })
  const Appointments = data.data?.getAppointments?.appointments ?? ([] as Appointment[])

  const events: EventInput[] = Appointments.map((value) => ({
    id: value.uuid,
    start: deSerializeDate(value.date || ''),
    title: value.visitor?.name ?? value.visitor?.email ?? '',
    status: value.status || '?',
    numVisitors: (value.visitor?.numAdults ?? 1) + (value.visitor?.numChildren ?? 0),
    salesRepresentativesAndManufacturer: `${value.salesRepresentative?.name} (${value.salesRepresentative?.manufacturer?.name})`,
  }))

  const renderEventContent = function (eventContent: EventContentArg) {
    const color = statusColorMap[eventContent.event._def.extendedProps['status'] as AppointmentStatus]
    eventContent.backgroundColor = color
    eventContent.borderColor = color
    const numVisitors = eventContent.event._def.extendedProps['numVisitors']
    const salesRepresentativesAndManufacturer =
      eventContent.event._def.extendedProps['salesRepresentativesAndManufacturer']
    const numVisitorsString =
      numVisitors > 1 ? `${numVisitors} Personen` : numVisitors === 1 ? '1 Person sss' : 'Keine Angabe zur Personenzahl'
    return (
      <>
        <b>{eventContent.event.title}</b>
        {salesRepresentativesAndManufacturer}
        {/*<br />*/}
        {/*<i>{eventContent.timeText}</i>*/}
        <br />
        <i>{numVisitorsString}</i>
      </>
    )
  }
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin]}
        headerToolbar={false}
        initialView={props.view === 'day' ? 'timeGridDay' : 'timeGridWeek'}
        firstDay={1} // Mo-So
        weekends={true}
        allDaySlot={false} // hide all-day appointments bar
        dayHeaderFormat={{ month: 'numeric', day: 'numeric', weekday: 'long' }}
        slotLabelInterval="02:00" // show only even hours
        slotLabelFormat={{ hour: 'numeric', omitZeroMinute: false }}
        slotMinTime="08:00"
        slotMaxTime="20:30"
        events={events}
        eventContent={renderEventContent}
        locale={'de'}
        ref={(ref) => (calendarRef.current = ref)}
      />
    </div>
  )
}
