import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useCreateManufacturerMutation } from '../../../generated/graphql-hooks'
import { useNotifications } from '../../../utils/notificationUtils'
import { useAuthData } from '../../../utils/auth/AuthenticationProvider'
import { formikTextFieldArgs } from '../../../utils/formUtils'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'

export const CreateManufacturerButton: React.FC = () => {
  const { showError, showSuccess } = useNotifications()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const validationSchema = yup.object({
    name: yup.string().required('Nachname ist erforderlich'),
    street: yup.string(),
    streetNumber: yup.string().when(['street'], {
      is: (value: string) => !!value,
      then: yup.string().required('Bitte vollständige Adresse eingeben, oder alle Adressfelder frei lassen.'),
      otherwise: yup.string(),
    }),
    postalCode: yup.string().when(['streetNumber'], {
      is: (value: string) => !!value,
      then: yup.string().required('Bitte vollständige Adresse eingeben, oder alle Adressfelder frei lassen.'),
      otherwise: yup.string(),
    }),
    city: yup.string().when(['postalCode'], {
      is: (value: string) => !!value,
      then: yup.string().required('Bitte vollständige Adresse eingeben, oder alle Adressfelder frei lassen.'),
      otherwise: yup.string(),
    }),
  })

  const [createManufacturerMutation, { data, loading, error }] = useCreateManufacturerMutation({
    refetchQueries: ['getManufacturers'],
  })
  const authData = useAuthData()
  const formik = useFormik({
    initialValues: {
      name: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      city: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClose()
      createManufacturerMutation({
        variables: {
          createManufacturer: {
            name: values.name,
            address: {
              street: values.street,
              streetNumber: values.streetNumber,
              postalCode: values.postalCode,
              city: values.city,
            },
          },
        },
      })
        .then(() => {
          showSuccess('Hersteller wurde erfolgreich erstellt.')
          formik.resetForm()
        })
        .catch((err) => {
          console.error(err)
          showError('Hersteller konnte nicht erstellt werden.')
        })
    },
  })

  return (
    <>
      <Button variant={'contained'} color={'primary'} onClick={handleClickOpen}>
        Hersteller anlegen
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle title={'Neuen Hersteller anlegen'} />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField label="Name" {...formikTextFieldArgs('name', formik)} fullWidth />
              </Grid>
              <Grid item xs={8}>
                <TextField label="Straße" {...formikTextFieldArgs('street', formik)} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextField label="Hausnummer" {...formikTextFieldArgs('streetNumber', formik)} fullWidth />
              </Grid>
              <Grid item xs={8}>
                <TextField label="Stadt" {...formikTextFieldArgs('city', formik)} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextField label="Postleitzahl" {...formikTextFieldArgs('postalCode', formik)} fullWidth />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Anlegen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
