import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export enum PageSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Query = {
  __typename?: 'Query'
  getAppointments: AppointmentsPage
  getAppointment?: Maybe<Appointment>
  getSalesRepresentatives: SalesRepresentativesPage
  getSalesRepresentative?: Maybe<SalesRepresentative>
  getManufacturers: ManufacturersPage
  getManufacturersByPark: Array<Manufacturer>
  getManufacturer?: Maybe<Manufacturer>
  getParks: ParksPage
  getParksByManufacturer: Array<Park>
  getPark?: Maybe<Park>
  getCenterManagers: CenterManagersPage
  getCenterManager?: Maybe<CenterManager>
  getTickets: TicketsPage
  getTicketsForAdmin: TicketsPage
  getTicket?: Maybe<Ticket>
}

export type QueryGetAppointmentsArgs = {
  pageQuery: PageQuery
  dateQuery?: Maybe<DateQuery>
  showAll?: Maybe<Scalars['Boolean']>
}

export type QueryGetAppointmentArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetSalesRepresentativesArgs = {
  pageQuery: PageQuery
}

export type QueryGetSalesRepresentativeArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetManufacturersArgs = {
  pageQuery: PageQuery
}

export type QueryGetManufacturersByParkArgs = {
  parkUuid: Scalars['String']
}

export type QueryGetManufacturerArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetParksArgs = {
  pageQuery: PageQuery
  manufacturerUuid?: Maybe<Scalars['String']>
}

export type QueryGetParksByManufacturerArgs = {
  manufacturerUuid: Scalars['String']
}

export type QueryGetParkArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetCenterManagersArgs = {
  pageQuery: PageQuery
}

export type QueryGetCenterManagerArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetTicketsArgs = {
  pageQuery: PageQuery
  dateQuery?: Maybe<DateQuery>
}

export type QueryGetTicketsForAdminArgs = {
  pageQuery: PageQuery
  dateQuery?: Maybe<DateQuery>
}

export type QueryGetTicketArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type PageQuery = {
  pageSize: Scalars['Int']
  page: Scalars['Int']
  sort?: Maybe<PageSort>
  search?: Maybe<Scalars['String']>
}

export type PageSort = {
  dir: PageSortDirection
  prop: Scalars['String']
}

export type DateQuery = {
  beginDate?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
}

export type AppointmentsPage = {
  __typename?: 'AppointmentsPage'
  pageInfo: PageInfo
  appointments: Array<Appointment>
}

export type Appointment = {
  __typename?: 'Appointment'
  uuid: Scalars['String']
  date?: Maybe<Scalars['String']>
  status?: Maybe<AppointmentStatus>
  changeRequestComment?: Maybe<Scalars['String']>
  visitor?: Maybe<Visitor>
  salesRepresentative?: Maybe<SalesRepresentative>
  bill?: Maybe<Scalars['Boolean']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  totalCount: Scalars['Int']
  totalPages: Scalars['Int']
  elementsInPage: Scalars['Int']
}

export enum AppointmentStatus {
  Created = 'CREATED',
  InvitationSent = 'INVITATION_SENT',
  ChangeRequested = 'CHANGE_REQUESTED',
  Registered = 'REGISTERED',
  Canceled = 'CANCELED',
  Missed = 'MISSED',
}

export type Visitor = {
  __typename?: 'Visitor'
  uuid: Scalars['String']
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  address?: Maybe<Address>
  numChildren?: Maybe<Scalars['Int']>
  numAdults?: Maybe<Scalars['Int']>
  appointments?: Maybe<Array<Maybe<Appointment>>>
}

export type Address = {
  __typename?: 'Address'
  street?: Maybe<Scalars['String']>
  streetNumber?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
}

export type SalesRepresentative = {
  __typename?: 'SalesRepresentative'
  uuid: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  manufacturer?: Maybe<Manufacturer>
  park?: Maybe<Park>
}

export type Manufacturer = {
  __typename?: 'Manufacturer'
  uuid: Scalars['String']
  name: Scalars['String']
  address?: Maybe<Address>
}

export type Park = {
  __typename?: 'Park'
  uuid: Scalars['String']
  name?: Maybe<Scalars['String']>
  address?: Maybe<Address>
}

export type SalesRepresentativesPage = {
  __typename?: 'SalesRepresentativesPage'
  pageInfo: PageInfo
  salesRepresentatives: Array<SalesRepresentative>
}

export type ManufacturersPage = {
  __typename?: 'ManufacturersPage'
  pageInfo: PageInfo
  manufacturers: Array<Manufacturer>
}

export type ParksPage = {
  __typename?: 'ParksPage'
  pageInfo: PageInfo
  parks: Array<Park>
}

export type CenterManagersPage = {
  __typename?: 'CenterManagersPage'
  pageInfo: PageInfo
  centerManagers: Array<CenterManager>
}

export type CenterManager = {
  __typename?: 'CenterManager'
  uuid: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  parks: Array<Park>
}

export type TicketsPage = {
  __typename?: 'TicketsPage'
  pageInfo: PageInfo
  tickets: Array<Ticket>
}

export type Ticket = {
  __typename?: 'Ticket'
  uuid: Scalars['String']
  remainingEntries: Scalars['Int']
  expectedEntryDate?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  type: TicketType
  visitor?: Maybe<Visitor>
  isValid: Scalars['Boolean']
  hasIncompleteVisitorData: Scalars['Boolean']
  park?: Maybe<Park>
}

export enum TicketType {
  Unlimited = 'UNLIMITED',
  GiftCard = 'GIFT_CARD',
  Online = 'ONLINE',
  Appointment = 'APPOINTMENT',
  Cash = 'CASH',
  UnknownPark = 'UNKNOWN_PARK',
}

export type Mutation = {
  __typename?: 'Mutation'
  createAppointment: Appointment
  updateAppointment: Appointment
  registerForAppointment: Appointment
  requestChangeForAppointment: Appointment
  cancelAppointment: Appointment
  markAppointmentAsMissed: Appointment
  resendAppointmentInvitation: Appointment
  updateVisitor: Visitor
  createManufacturer: Manufacturer
  updateManufacturer: Manufacturer
  deleteManufacturer: Manufacturer
  createSalesRepresentative: SalesRepresentative
  updateSalesRepresentative: SalesRepresentative
  deleteSalesRepresentative: SalesRepresentative
  resendRegistrationEmailSalesRepresentative: SalesRepresentative
  createCenterManager: CenterManager
  updateCenterManager: CenterManager
  deleteCenterManager: CenterManager
  createPark: Park
  updatePark: Park
  deletePark: Park
  createTicket: Ticket
  printTicketAsPdf: Scalars['String']
  sendTicketEmail: Scalars['Boolean']
  checkinTicket: TicketCheckinResult
}

export type MutationCreateAppointmentArgs = {
  appointment: CreateAppointment
}

export type MutationUpdateAppointmentArgs = {
  appointment: UpdateAppointment
}

export type MutationRegisterForAppointmentArgs = {
  appointment: RegisterForAppointment
}

export type MutationRequestChangeForAppointmentArgs = {
  changeRequest: RequestChangeForAppointment
}

export type MutationCancelAppointmentArgs = {
  uuid: Scalars['String']
}

export type MutationMarkAppointmentAsMissedArgs = {
  uuid: Scalars['String']
}

export type MutationResendAppointmentInvitationArgs = {
  uuid: Scalars['String']
}

export type MutationUpdateVisitorArgs = {
  visitor: UpdateVisitor
}

export type MutationCreateManufacturerArgs = {
  manufacturer: CreateManufacturer
}

export type MutationUpdateManufacturerArgs = {
  manufacturer: UpdateManufacturer
}

export type MutationDeleteManufacturerArgs = {
  manufacturer: DeleteManufacturer
}

export type MutationCreateSalesRepresentativeArgs = {
  salesRepresentative: CreateSalesRepresentative
}

export type MutationUpdateSalesRepresentativeArgs = {
  salesRepresentative: UpdateSalesRepresentative
}

export type MutationDeleteSalesRepresentativeArgs = {
  salesRepresentative: DeleteSalesRepresentative
}

export type MutationResendRegistrationEmailSalesRepresentativeArgs = {
  salesRepresentative: ResendRegistrationEmailSalesRepresentative
}

export type MutationCreateCenterManagerArgs = {
  centerManager: CreateCenterManager
}

export type MutationUpdateCenterManagerArgs = {
  centerManager: UpdateCenterManager
}

export type MutationDeleteCenterManagerArgs = {
  centerManager: DeleteCenterManager
}

export type MutationCreateParkArgs = {
  park: CreatePark
}

export type MutationUpdateParkArgs = {
  park: UpdatePark
}

export type MutationDeleteParkArgs = {
  park: DeletePark
}

export type MutationCreateTicketArgs = {
  ticket: CreateTicket
}

export type MutationPrintTicketAsPdfArgs = {
  uuid: Scalars['String']
}

export type MutationSendTicketEmailArgs = {
  uuid: Scalars['String']
}

export type MutationCheckinTicketArgs = {
  uuid: Scalars['String']
}

export type CreateAppointment = {
  email: Scalars['String']
  date: Scalars['String']
  salesRepresentativeUuid: Scalars['String']
  bill: Scalars['Boolean']
}

export type UpdateAppointment = {
  uuid: Scalars['String']
  date?: Maybe<Scalars['String']>
}

export type RegisterForAppointment = {
  uuid: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  numAdults: Scalars['Int']
  numChildren: Scalars['Int']
  address: AddressInput
}

export type AddressInput = {
  street: Scalars['String']
  streetNumber: Scalars['String']
  postalCode: Scalars['String']
  city: Scalars['String']
}

export type RequestChangeForAppointment = {
  uuid: Scalars['String']
  comment: Scalars['String']
}

export type UpdateVisitor = {
  uuid: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  address: AddressInput
  numChildren: Scalars['Int']
  numAdults: Scalars['Int']
}

export type CreateManufacturer = {
  name: Scalars['String']
  address?: Maybe<AddressInput>
}

export type UpdateManufacturer = {
  uuid: Scalars['String']
  name: Scalars['String']
  address?: Maybe<AddressInput>
}

export type DeleteManufacturer = {
  uuid: Scalars['String']
}

export type CreateSalesRepresentative = {
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  manufacturerUuid: Scalars['String']
  parkUuid: Scalars['String']
}

export type UpdateSalesRepresentative = {
  uuid: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  manufacturerUuid: Scalars['String']
  parkUuid: Scalars['String']
}

export type DeleteSalesRepresentative = {
  uuid: Scalars['String']
}

export type ResendRegistrationEmailSalesRepresentative = {
  uuid: Scalars['String']
}

export type CreateCenterManager = {
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  parkUuids: Array<Scalars['String']>
}

export type UpdateCenterManager = {
  uuid: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  parkUuids: Array<Scalars['String']>
}

export type DeleteCenterManager = {
  uuid: Scalars['String']
}

export type CreatePark = {
  name: Scalars['String']
  address?: Maybe<AddressInput>
}

export type UpdatePark = {
  uuid: Scalars['String']
  name: Scalars['String']
  address?: Maybe<AddressInput>
}

export type DeletePark = {
  uuid: Scalars['String']
}

export type CreateTicket = {
  comment: Scalars['String']
  type: TicketType
}

export type TicketCheckinResult = {
  __typename?: 'TicketCheckinResult'
  uuid: Scalars['String']
  success: Scalars['Boolean']
}

export type CreateAppointmentMutationVariables = Exact<{
  createAppointment: CreateAppointment
}>

export type CreateAppointmentMutation = { __typename?: 'Mutation' } & {
  createAppointment: { __typename?: 'Appointment' } & Pick<Appointment, 'uuid' | 'status'>
}

export type RegisterForAppointmentMutationVariables = Exact<{
  registerForAppointment: RegisterForAppointment
}>

export type RegisterForAppointmentMutation = { __typename?: 'Mutation' } & {
  registerForAppointment: { __typename?: 'Appointment' } & Pick<Appointment, 'uuid' | 'status'>
}

export type RequestChangeForAppointmentMutationVariables = Exact<{
  changeRequest: RequestChangeForAppointment
}>

export type RequestChangeForAppointmentMutation = { __typename?: 'Mutation' } & {
  requestChangeForAppointment: { __typename?: 'Appointment' } & Pick<Appointment, 'uuid' | 'status'>
}

export type UpdateAppointmentMutationVariables = Exact<{
  updateAppointment: UpdateAppointment
}>

export type UpdateAppointmentMutation = { __typename?: 'Mutation' } & {
  updateAppointment: { __typename?: 'Appointment' } & Pick<Appointment, 'uuid' | 'status'>
}

export type CancelAppointmentMutationVariables = Exact<{
  uuid: Scalars['String']
}>

export type CancelAppointmentMutation = { __typename?: 'Mutation' } & {
  cancelAppointment: { __typename?: 'Appointment' } & Pick<Appointment, 'uuid' | 'status'>
}

export type MarkAppointmentAsMissedMutationVariables = Exact<{
  uuid: Scalars['String']
}>

export type MarkAppointmentAsMissedMutation = { __typename?: 'Mutation' } & {
  markAppointmentAsMissed: { __typename?: 'Appointment' } & Pick<Appointment, 'uuid' | 'status'>
}

export type ResendAppointmentInvitationMutationVariables = Exact<{
  uuid: Scalars['String']
}>

export type ResendAppointmentInvitationMutation = { __typename?: 'Mutation' } & {
  resendAppointmentInvitation: { __typename?: 'Appointment' } & Pick<Appointment, 'uuid' | 'status'>
}

export type CreateCenterManagerMutationVariables = Exact<{
  createCenterManager: CreateCenterManager
}>

export type CreateCenterManagerMutation = { __typename?: 'Mutation' } & {
  createCenterManager: { __typename?: 'CenterManager' } & Pick<CenterManager, 'uuid' | 'email' | 'firstName' | 'name'>
}

export type UpdateCenterManagerMutationVariables = Exact<{
  updateCenterManager: UpdateCenterManager
}>

export type UpdateCenterManagerMutation = { __typename?: 'Mutation' } & {
  updateCenterManager: { __typename?: 'CenterManager' } & Pick<CenterManager, 'uuid' | 'email' | 'firstName' | 'name'>
}

export type DeleteCenterManagerMutationVariables = Exact<{
  deleteCenterManager: DeleteCenterManager
}>

export type DeleteCenterManagerMutation = { __typename?: 'Mutation' } & {
  deleteCenterManager: { __typename?: 'CenterManager' } & Pick<CenterManager, 'uuid' | 'email' | 'firstName' | 'name'>
}

export type CreateManufacturerMutationVariables = Exact<{
  createManufacturer: CreateManufacturer
}>

export type CreateManufacturerMutation = { __typename?: 'Mutation' } & {
  createManufacturer: { __typename?: 'Manufacturer' } & Pick<Manufacturer, 'uuid' | 'name'>
}

export type UpdateManufacturerMutationVariables = Exact<{
  updateManufacturer: UpdateManufacturer
}>

export type UpdateManufacturerMutation = { __typename?: 'Mutation' } & {
  updateManufacturer: { __typename?: 'Manufacturer' } & Pick<Manufacturer, 'uuid' | 'name'>
}

export type DeleteManufacturerMutationVariables = Exact<{
  deleteManufacturer: DeleteManufacturer
}>

export type DeleteManufacturerMutation = { __typename?: 'Mutation' } & {
  deleteManufacturer: { __typename?: 'Manufacturer' } & Pick<Manufacturer, 'uuid' | 'name'>
}

export type CreateParkMutationVariables = Exact<{
  createPark: CreatePark
}>

export type CreateParkMutation = { __typename?: 'Mutation' } & {
  createPark: { __typename?: 'Park' } & Pick<Park, 'uuid' | 'name'>
}

export type UpdateParkMutationVariables = Exact<{
  updatePark: UpdatePark
}>

export type UpdateParkMutation = { __typename?: 'Mutation' } & {
  updatePark: { __typename?: 'Park' } & Pick<Park, 'uuid' | 'name'>
}

export type DeleteParkMutationVariables = Exact<{
  deletePark: DeletePark
}>

export type DeleteParkMutation = { __typename?: 'Mutation' } & {
  deletePark: { __typename?: 'Park' } & Pick<Park, 'uuid' | 'name'>
}

export type CreateSalesRepresentativeMutationVariables = Exact<{
  createSalesRepresentative: CreateSalesRepresentative
}>

export type CreateSalesRepresentativeMutation = { __typename?: 'Mutation' } & {
  createSalesRepresentative: { __typename?: 'SalesRepresentative' } & Pick<
    SalesRepresentative,
    'uuid' | 'email' | 'firstName' | 'name'
  >
}

export type UpdateSalesRepresentativeMutationVariables = Exact<{
  updateSalesRepresentative: UpdateSalesRepresentative
}>

export type UpdateSalesRepresentativeMutation = { __typename?: 'Mutation' } & {
  updateSalesRepresentative: { __typename?: 'SalesRepresentative' } & Pick<
    SalesRepresentative,
    'uuid' | 'email' | 'firstName' | 'name'
  >
}

export type DeleteSalesRepresentativeMutationVariables = Exact<{
  deleteSalesRepresentative: DeleteSalesRepresentative
}>

export type DeleteSalesRepresentativeMutation = { __typename?: 'Mutation' } & {
  deleteSalesRepresentative: { __typename?: 'SalesRepresentative' } & Pick<
    SalesRepresentative,
    'uuid' | 'email' | 'firstName' | 'name'
  >
}

export type ResendRegistrationEmailSalesRepresentativeMutationVariables = Exact<{
  resendRegistrationEmailSalesRepresentative: ResendRegistrationEmailSalesRepresentative
}>

export type ResendRegistrationEmailSalesRepresentativeMutation = { __typename?: 'Mutation' } & {
  resendRegistrationEmailSalesRepresentative: { __typename?: 'SalesRepresentative' } & Pick<SalesRepresentative, 'uuid'>
}

export type CreateTicketMutationVariables = Exact<{
  createTicket: CreateTicket
}>

export type CreateTicketMutation = { __typename?: 'Mutation' } & {
  createTicket: { __typename?: 'Ticket' } & Pick<Ticket, 'uuid'>
}

export type CheckInTicketMutationVariables = Exact<{
  uuid: Scalars['String']
}>

export type CheckInTicketMutation = { __typename?: 'Mutation' } & {
  checkinTicket: { __typename?: 'TicketCheckinResult' } & Pick<TicketCheckinResult, 'uuid' | 'success'>
}

export type SendTicketEmailMutationVariables = Exact<{
  uuid: Scalars['String']
}>

export type SendTicketEmailMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'sendTicketEmail'>

export type PrintTicketAsPdfMutationVariables = Exact<{
  uuid: Scalars['String']
}>

export type PrintTicketAsPdfMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'printTicketAsPdf'>

export type UpdateVisitorMutationVariables = Exact<{
  visitor: UpdateVisitor
}>

export type UpdateVisitorMutation = { __typename?: 'Mutation' } & {
  updateVisitor: { __typename?: 'Visitor' } & Pick<
    Visitor,
    'uuid' | 'email' | 'firstName' | 'name' | 'numAdults' | 'numChildren'
  > & { address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'postalCode' | 'street' | 'streetNumber'>> }
}

export type GetAppointmentsQueryVariables = Exact<{
  pageQuery: PageQuery
  dateQuery?: Maybe<DateQuery>
  showAll?: Maybe<Scalars['Boolean']>
}>

export type GetAppointmentsQuery = { __typename?: 'Query' } & {
  getAppointments: { __typename?: 'AppointmentsPage' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages' | 'totalCount' | 'elementsInPage'>
    appointments: Array<
      { __typename?: 'Appointment' } & Pick<Appointment, 'uuid' | 'date' | 'status'> & {
          salesRepresentative?: Maybe<
            { __typename?: 'SalesRepresentative' } & Pick<SalesRepresentative, 'name'> & {
                manufacturer?: Maybe<{ __typename?: 'Manufacturer' } & Pick<Manufacturer, 'name'>>
              }
          >
          visitor?: Maybe<
            { __typename?: 'Visitor' } & Pick<Visitor, 'email' | 'firstName' | 'name' | 'numAdults' | 'numChildren'>
          >
        }
    >
  }
}

export type GetAppointmentQueryVariables = Exact<{
  uuid: Scalars['String']
}>

export type GetAppointmentQuery = { __typename?: 'Query' } & {
  getAppointment?: Maybe<
    { __typename?: 'Appointment' } & Pick<Appointment, 'uuid' | 'date' | 'status' | 'changeRequestComment'> & {
        visitor?: Maybe<
          { __typename?: 'Visitor' } & Pick<
            Visitor,
            'uuid' | 'email' | 'firstName' | 'name' | 'numAdults' | 'numChildren'
          > & {
              address?: Maybe<
                { __typename?: 'Address' } & Pick<Address, 'city' | 'postalCode' | 'street' | 'streetNumber'>
              >
            }
        >
        salesRepresentative?: Maybe<
          { __typename?: 'SalesRepresentative' } & Pick<
            SalesRepresentative,
            'uuid' | 'email' | 'name' | 'firstName'
          > & {
              manufacturer?: Maybe<{ __typename?: 'Manufacturer' } & Pick<Manufacturer, 'uuid' | 'name'>>
              park?: Maybe<{ __typename?: 'Park' } & Pick<Park, 'uuid' | 'name'>>
            }
        >
      }
  >
}

export type GetCenterManagersQueryVariables = Exact<{
  pageQuery: PageQuery
}>

export type GetCenterManagersQuery = { __typename?: 'Query' } & {
  getCenterManagers: { __typename?: 'CenterManagersPage' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages' | 'totalCount' | 'elementsInPage'>
    centerManagers: Array<
      { __typename?: 'CenterManager' } & Pick<CenterManager, 'uuid' | 'email' | 'name' | 'firstName'> & {
          parks: Array<{ __typename?: 'Park' } & Pick<Park, 'uuid' | 'name'>>
        }
    >
  }
}

export type GetCenterManagerQueryVariables = Exact<{
  uuid: Scalars['String']
}>

export type GetCenterManagerQuery = { __typename?: 'Query' } & {
  getCenterManager?: Maybe<
    { __typename?: 'CenterManager' } & Pick<CenterManager, 'uuid' | 'email' | 'name' | 'firstName'> & {
        parks: Array<{ __typename?: 'Park' } & Pick<Park, 'uuid' | 'name'>>
      }
  >
}

export type GetManufacturersQueryVariables = Exact<{
  pageQuery: PageQuery
}>

export type GetManufacturersQuery = { __typename?: 'Query' } & {
  getManufacturers: { __typename?: 'ManufacturersPage' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages' | 'totalCount' | 'elementsInPage'>
    manufacturers: Array<
      { __typename?: 'Manufacturer' } & Pick<Manufacturer, 'uuid' | 'name'> & {
          address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'postalCode' | 'street' | 'streetNumber'>>
        }
    >
  }
}

export type GetManufacturersByParkQueryVariables = Exact<{
  parkUuid: Scalars['String']
}>

export type GetManufacturersByParkQuery = { __typename?: 'Query' } & {
  getManufacturersByPark: Array<
    { __typename?: 'Manufacturer' } & Pick<Manufacturer, 'uuid' | 'name'> & {
        address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'postalCode' | 'street' | 'streetNumber'>>
      }
  >
}

export type GetManufacturerQueryVariables = Exact<{
  uuid: Scalars['String']
}>

export type GetManufacturerQuery = { __typename?: 'Query' } & {
  getManufacturer?: Maybe<
    { __typename?: 'Manufacturer' } & Pick<Manufacturer, 'uuid' | 'name'> & {
        address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'postalCode' | 'street' | 'streetNumber'>>
      }
  >
}

export type GetParksQueryVariables = Exact<{
  pageQuery: PageQuery
}>

export type GetParksQuery = { __typename?: 'Query' } & {
  getParks: { __typename?: 'ParksPage' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages' | 'totalCount' | 'elementsInPage'>
    parks: Array<
      { __typename?: 'Park' } & Pick<Park, 'uuid' | 'name'> & {
          address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'postalCode' | 'street' | 'streetNumber'>>
        }
    >
  }
}

export type GetParksByManufaturerQueryVariables = Exact<{
  manufacturerUuid: Scalars['String']
}>

export type GetParksByManufaturerQuery = { __typename?: 'Query' } & {
  getParksByManufacturer: Array<
    { __typename?: 'Park' } & Pick<Park, 'uuid' | 'name'> & {
        address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'postalCode' | 'street' | 'streetNumber'>>
      }
  >
}

export type GetParkQueryVariables = Exact<{
  uuid: Scalars['String']
}>

export type GetParkQuery = { __typename?: 'Query' } & {
  getPark?: Maybe<
    { __typename?: 'Park' } & Pick<Park, 'uuid' | 'name'> & {
        address?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'city' | 'postalCode' | 'street' | 'streetNumber'>>
      }
  >
}

export type GetSalesRepresentativesQueryVariables = Exact<{
  pageQuery: PageQuery
}>

export type GetSalesRepresentativesQuery = { __typename?: 'Query' } & {
  getSalesRepresentatives: { __typename?: 'SalesRepresentativesPage' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages' | 'totalCount' | 'elementsInPage'>
    salesRepresentatives: Array<
      { __typename?: 'SalesRepresentative' } & Pick<SalesRepresentative, 'uuid' | 'email' | 'firstName' | 'name'> & {
          manufacturer?: Maybe<{ __typename?: 'Manufacturer' } & Pick<Manufacturer, 'name' | 'uuid'>>
          park?: Maybe<{ __typename?: 'Park' } & Pick<Park, 'name' | 'uuid'>>
        }
    >
  }
}

export type GetSalesRepresentativeQueryVariables = Exact<{
  uuid: Scalars['String']
}>

export type GetSalesRepresentativeQuery = { __typename?: 'Query' } & {
  getSalesRepresentative?: Maybe<
    { __typename?: 'SalesRepresentative' } & Pick<SalesRepresentative, 'uuid' | 'email' | 'firstName' | 'name'> & {
        manufacturer?: Maybe<{ __typename?: 'Manufacturer' } & Pick<Manufacturer, 'name' | 'uuid'>>
        park?: Maybe<{ __typename?: 'Park' } & Pick<Park, 'name' | 'uuid'>>
      }
  >
}

export type GetTicketsQueryVariables = Exact<{
  pageQuery: PageQuery
  dateQuery?: Maybe<DateQuery>
}>

export type GetTicketsQuery = { __typename?: 'Query' } & {
  getTickets: { __typename?: 'TicketsPage' } & {
    pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'totalPages' | 'totalCount' | 'elementsInPage'>
    tickets: Array<
      { __typename?: 'Ticket' } & Pick<
        Ticket,
        'uuid' | 'comment' | 'expectedEntryDate' | 'remainingEntries' | 'isValid' | 'type'
      > & {
          visitor?: Maybe<
            { __typename?: 'Visitor' } & Pick<
              Visitor,
              'uuid' | 'email' | 'firstName' | 'name' | 'numAdults' | 'numChildren'
            > & {
                appointments?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'Appointment' } & {
                        salesRepresentative?: Maybe<
                          { __typename?: 'SalesRepresentative' } & Pick<SalesRepresentative, 'name'> & {
                              manufacturer?: Maybe<{ __typename?: 'Manufacturer' } & Pick<Manufacturer, 'name'>>
                            }
                        >
                      }
                    >
                  >
                >
              }
          >
        }
    >
  }
}

export type GetTicketQueryVariables = Exact<{
  uuid: Scalars['String']
}>

export type GetTicketQuery = { __typename?: 'Query' } & {
  getTicket?: Maybe<
    { __typename?: 'Ticket' } & Pick<
      Ticket,
      'uuid' | 'comment' | 'expectedEntryDate' | 'hasIncompleteVisitorData' | 'remainingEntries' | 'isValid' | 'type'
    > & {
        visitor?: Maybe<
          { __typename?: 'Visitor' } & Pick<
            Visitor,
            'uuid' | 'email' | 'firstName' | 'name' | 'numAdults' | 'numChildren'
          > & {
              address?: Maybe<
                { __typename?: 'Address' } & Pick<Address, 'city' | 'postalCode' | 'street' | 'streetNumber'>
              >
            }
        >
      }
  >
}

export const CreateAppointmentDocument = gql`
  mutation createAppointment($createAppointment: CreateAppointment!) {
    createAppointment(appointment: $createAppointment) {
      uuid
      status
    }
  }
`
export type CreateAppointmentMutationFn = Apollo.MutationFunction<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      createAppointment: // value for 'createAppointment'
 *   },
 * });
 */
export function useCreateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>
) {
  return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(
    CreateAppointmentDocument,
    baseOptions
  )
}
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>
export const RegisterForAppointmentDocument = gql`
  mutation registerForAppointment($registerForAppointment: RegisterForAppointment!) {
    registerForAppointment(appointment: $registerForAppointment) {
      uuid
      status
    }
  }
`
export type RegisterForAppointmentMutationFn = Apollo.MutationFunction<
  RegisterForAppointmentMutation,
  RegisterForAppointmentMutationVariables
>

/**
 * __useRegisterForAppointmentMutation__
 *
 * To run a mutation, you first call `useRegisterForAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterForAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerForAppointmentMutation, { data, loading, error }] = useRegisterForAppointmentMutation({
 *   variables: {
 *      registerForAppointment: // value for 'registerForAppointment'
 *   },
 * });
 */
export function useRegisterForAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterForAppointmentMutation, RegisterForAppointmentMutationVariables>
) {
  return Apollo.useMutation<RegisterForAppointmentMutation, RegisterForAppointmentMutationVariables>(
    RegisterForAppointmentDocument,
    baseOptions
  )
}
export type RegisterForAppointmentMutationHookResult = ReturnType<typeof useRegisterForAppointmentMutation>
export type RegisterForAppointmentMutationResult = Apollo.MutationResult<RegisterForAppointmentMutation>
export type RegisterForAppointmentMutationOptions = Apollo.BaseMutationOptions<
  RegisterForAppointmentMutation,
  RegisterForAppointmentMutationVariables
>
export const RequestChangeForAppointmentDocument = gql`
  mutation requestChangeForAppointment($changeRequest: RequestChangeForAppointment!) {
    requestChangeForAppointment(changeRequest: $changeRequest) {
      uuid
      status
    }
  }
`
export type RequestChangeForAppointmentMutationFn = Apollo.MutationFunction<
  RequestChangeForAppointmentMutation,
  RequestChangeForAppointmentMutationVariables
>

/**
 * __useRequestChangeForAppointmentMutation__
 *
 * To run a mutation, you first call `useRequestChangeForAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestChangeForAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestChangeForAppointmentMutation, { data, loading, error }] = useRequestChangeForAppointmentMutation({
 *   variables: {
 *      changeRequest: // value for 'changeRequest'
 *   },
 * });
 */
export function useRequestChangeForAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestChangeForAppointmentMutation,
    RequestChangeForAppointmentMutationVariables
  >
) {
  return Apollo.useMutation<RequestChangeForAppointmentMutation, RequestChangeForAppointmentMutationVariables>(
    RequestChangeForAppointmentDocument,
    baseOptions
  )
}
export type RequestChangeForAppointmentMutationHookResult = ReturnType<typeof useRequestChangeForAppointmentMutation>
export type RequestChangeForAppointmentMutationResult = Apollo.MutationResult<RequestChangeForAppointmentMutation>
export type RequestChangeForAppointmentMutationOptions = Apollo.BaseMutationOptions<
  RequestChangeForAppointmentMutation,
  RequestChangeForAppointmentMutationVariables
>
export const UpdateAppointmentDocument = gql`
  mutation updateAppointment($updateAppointment: UpdateAppointment!) {
    updateAppointment(appointment: $updateAppointment) {
      uuid
      status
    }
  }
`
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      updateAppointment: // value for 'updateAppointment'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>
) {
  return Apollo.useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(
    UpdateAppointmentDocument,
    baseOptions
  )
}
export type UpdateAppointmentMutationHookResult = ReturnType<typeof useUpdateAppointmentMutation>
export type UpdateAppointmentMutationResult = Apollo.MutationResult<UpdateAppointmentMutation>
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>
export const CancelAppointmentDocument = gql`
  mutation cancelAppointment($uuid: String!) {
    cancelAppointment(uuid: $uuid) {
      uuid
      status
    }
  }
`
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>
) {
  return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(
    CancelAppointmentDocument,
    baseOptions
  )
}
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>
export const MarkAppointmentAsMissedDocument = gql`
  mutation markAppointmentAsMissed($uuid: String!) {
    markAppointmentAsMissed(uuid: $uuid) {
      uuid
      status
    }
  }
`
export type MarkAppointmentAsMissedMutationFn = Apollo.MutationFunction<
  MarkAppointmentAsMissedMutation,
  MarkAppointmentAsMissedMutationVariables
>

/**
 * __useMarkAppointmentAsMissedMutation__
 *
 * To run a mutation, you first call `useMarkAppointmentAsMissedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAppointmentAsMissedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAppointmentAsMissedMutation, { data, loading, error }] = useMarkAppointmentAsMissedMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMarkAppointmentAsMissedMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkAppointmentAsMissedMutation, MarkAppointmentAsMissedMutationVariables>
) {
  return Apollo.useMutation<MarkAppointmentAsMissedMutation, MarkAppointmentAsMissedMutationVariables>(
    MarkAppointmentAsMissedDocument,
    baseOptions
  )
}
export type MarkAppointmentAsMissedMutationHookResult = ReturnType<typeof useMarkAppointmentAsMissedMutation>
export type MarkAppointmentAsMissedMutationResult = Apollo.MutationResult<MarkAppointmentAsMissedMutation>
export type MarkAppointmentAsMissedMutationOptions = Apollo.BaseMutationOptions<
  MarkAppointmentAsMissedMutation,
  MarkAppointmentAsMissedMutationVariables
>
export const ResendAppointmentInvitationDocument = gql`
  mutation resendAppointmentInvitation($uuid: String!) {
    resendAppointmentInvitation(uuid: $uuid) {
      uuid
      status
    }
  }
`
export type ResendAppointmentInvitationMutationFn = Apollo.MutationFunction<
  ResendAppointmentInvitationMutation,
  ResendAppointmentInvitationMutationVariables
>

/**
 * __useResendAppointmentInvitationMutation__
 *
 * To run a mutation, you first call `useResendAppointmentInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendAppointmentInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendAppointmentInvitationMutation, { data, loading, error }] = useResendAppointmentInvitationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useResendAppointmentInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendAppointmentInvitationMutation,
    ResendAppointmentInvitationMutationVariables
  >
) {
  return Apollo.useMutation<ResendAppointmentInvitationMutation, ResendAppointmentInvitationMutationVariables>(
    ResendAppointmentInvitationDocument,
    baseOptions
  )
}
export type ResendAppointmentInvitationMutationHookResult = ReturnType<typeof useResendAppointmentInvitationMutation>
export type ResendAppointmentInvitationMutationResult = Apollo.MutationResult<ResendAppointmentInvitationMutation>
export type ResendAppointmentInvitationMutationOptions = Apollo.BaseMutationOptions<
  ResendAppointmentInvitationMutation,
  ResendAppointmentInvitationMutationVariables
>
export const CreateCenterManagerDocument = gql`
  mutation createCenterManager($createCenterManager: CreateCenterManager!) {
    createCenterManager(centerManager: $createCenterManager) {
      uuid
      email
      firstName
      name
    }
  }
`
export type CreateCenterManagerMutationFn = Apollo.MutationFunction<
  CreateCenterManagerMutation,
  CreateCenterManagerMutationVariables
>

/**
 * __useCreateCenterManagerMutation__
 *
 * To run a mutation, you first call `useCreateCenterManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCenterManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCenterManagerMutation, { data, loading, error }] = useCreateCenterManagerMutation({
 *   variables: {
 *      createCenterManager: // value for 'createCenterManager'
 *   },
 * });
 */
export function useCreateCenterManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCenterManagerMutation, CreateCenterManagerMutationVariables>
) {
  return Apollo.useMutation<CreateCenterManagerMutation, CreateCenterManagerMutationVariables>(
    CreateCenterManagerDocument,
    baseOptions
  )
}
export type CreateCenterManagerMutationHookResult = ReturnType<typeof useCreateCenterManagerMutation>
export type CreateCenterManagerMutationResult = Apollo.MutationResult<CreateCenterManagerMutation>
export type CreateCenterManagerMutationOptions = Apollo.BaseMutationOptions<
  CreateCenterManagerMutation,
  CreateCenterManagerMutationVariables
>
export const UpdateCenterManagerDocument = gql`
  mutation updateCenterManager($updateCenterManager: UpdateCenterManager!) {
    updateCenterManager(centerManager: $updateCenterManager) {
      uuid
      email
      firstName
      name
    }
  }
`
export type UpdateCenterManagerMutationFn = Apollo.MutationFunction<
  UpdateCenterManagerMutation,
  UpdateCenterManagerMutationVariables
>

/**
 * __useUpdateCenterManagerMutation__
 *
 * To run a mutation, you first call `useUpdateCenterManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCenterManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCenterManagerMutation, { data, loading, error }] = useUpdateCenterManagerMutation({
 *   variables: {
 *      updateCenterManager: // value for 'updateCenterManager'
 *   },
 * });
 */
export function useUpdateCenterManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCenterManagerMutation, UpdateCenterManagerMutationVariables>
) {
  return Apollo.useMutation<UpdateCenterManagerMutation, UpdateCenterManagerMutationVariables>(
    UpdateCenterManagerDocument,
    baseOptions
  )
}
export type UpdateCenterManagerMutationHookResult = ReturnType<typeof useUpdateCenterManagerMutation>
export type UpdateCenterManagerMutationResult = Apollo.MutationResult<UpdateCenterManagerMutation>
export type UpdateCenterManagerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCenterManagerMutation,
  UpdateCenterManagerMutationVariables
>
export const DeleteCenterManagerDocument = gql`
  mutation deleteCenterManager($deleteCenterManager: DeleteCenterManager!) {
    deleteCenterManager(centerManager: $deleteCenterManager) {
      uuid
      email
      firstName
      name
    }
  }
`
export type DeleteCenterManagerMutationFn = Apollo.MutationFunction<
  DeleteCenterManagerMutation,
  DeleteCenterManagerMutationVariables
>

/**
 * __useDeleteCenterManagerMutation__
 *
 * To run a mutation, you first call `useDeleteCenterManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCenterManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCenterManagerMutation, { data, loading, error }] = useDeleteCenterManagerMutation({
 *   variables: {
 *      deleteCenterManager: // value for 'deleteCenterManager'
 *   },
 * });
 */
export function useDeleteCenterManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCenterManagerMutation, DeleteCenterManagerMutationVariables>
) {
  return Apollo.useMutation<DeleteCenterManagerMutation, DeleteCenterManagerMutationVariables>(
    DeleteCenterManagerDocument,
    baseOptions
  )
}
export type DeleteCenterManagerMutationHookResult = ReturnType<typeof useDeleteCenterManagerMutation>
export type DeleteCenterManagerMutationResult = Apollo.MutationResult<DeleteCenterManagerMutation>
export type DeleteCenterManagerMutationOptions = Apollo.BaseMutationOptions<
  DeleteCenterManagerMutation,
  DeleteCenterManagerMutationVariables
>
export const CreateManufacturerDocument = gql`
  mutation createManufacturer($createManufacturer: CreateManufacturer!) {
    createManufacturer(manufacturer: $createManufacturer) {
      uuid
      name
    }
  }
`
export type CreateManufacturerMutationFn = Apollo.MutationFunction<
  CreateManufacturerMutation,
  CreateManufacturerMutationVariables
>

/**
 * __useCreateManufacturerMutation__
 *
 * To run a mutation, you first call `useCreateManufacturerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManufacturerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManufacturerMutation, { data, loading, error }] = useCreateManufacturerMutation({
 *   variables: {
 *      createManufacturer: // value for 'createManufacturer'
 *   },
 * });
 */
export function useCreateManufacturerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateManufacturerMutation, CreateManufacturerMutationVariables>
) {
  return Apollo.useMutation<CreateManufacturerMutation, CreateManufacturerMutationVariables>(
    CreateManufacturerDocument,
    baseOptions
  )
}
export type CreateManufacturerMutationHookResult = ReturnType<typeof useCreateManufacturerMutation>
export type CreateManufacturerMutationResult = Apollo.MutationResult<CreateManufacturerMutation>
export type CreateManufacturerMutationOptions = Apollo.BaseMutationOptions<
  CreateManufacturerMutation,
  CreateManufacturerMutationVariables
>
export const UpdateManufacturerDocument = gql`
  mutation updateManufacturer($updateManufacturer: UpdateManufacturer!) {
    updateManufacturer(manufacturer: $updateManufacturer) {
      uuid
      name
    }
  }
`
export type UpdateManufacturerMutationFn = Apollo.MutationFunction<
  UpdateManufacturerMutation,
  UpdateManufacturerMutationVariables
>

/**
 * __useUpdateManufacturerMutation__
 *
 * To run a mutation, you first call `useUpdateManufacturerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManufacturerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManufacturerMutation, { data, loading, error }] = useUpdateManufacturerMutation({
 *   variables: {
 *      updateManufacturer: // value for 'updateManufacturer'
 *   },
 * });
 */
export function useUpdateManufacturerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateManufacturerMutation, UpdateManufacturerMutationVariables>
) {
  return Apollo.useMutation<UpdateManufacturerMutation, UpdateManufacturerMutationVariables>(
    UpdateManufacturerDocument,
    baseOptions
  )
}
export type UpdateManufacturerMutationHookResult = ReturnType<typeof useUpdateManufacturerMutation>
export type UpdateManufacturerMutationResult = Apollo.MutationResult<UpdateManufacturerMutation>
export type UpdateManufacturerMutationOptions = Apollo.BaseMutationOptions<
  UpdateManufacturerMutation,
  UpdateManufacturerMutationVariables
>
export const DeleteManufacturerDocument = gql`
  mutation deleteManufacturer($deleteManufacturer: DeleteManufacturer!) {
    deleteManufacturer(manufacturer: $deleteManufacturer) {
      uuid
      name
    }
  }
`
export type DeleteManufacturerMutationFn = Apollo.MutationFunction<
  DeleteManufacturerMutation,
  DeleteManufacturerMutationVariables
>

/**
 * __useDeleteManufacturerMutation__
 *
 * To run a mutation, you first call `useDeleteManufacturerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManufacturerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManufacturerMutation, { data, loading, error }] = useDeleteManufacturerMutation({
 *   variables: {
 *      deleteManufacturer: // value for 'deleteManufacturer'
 *   },
 * });
 */
export function useDeleteManufacturerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteManufacturerMutation, DeleteManufacturerMutationVariables>
) {
  return Apollo.useMutation<DeleteManufacturerMutation, DeleteManufacturerMutationVariables>(
    DeleteManufacturerDocument,
    baseOptions
  )
}
export type DeleteManufacturerMutationHookResult = ReturnType<typeof useDeleteManufacturerMutation>
export type DeleteManufacturerMutationResult = Apollo.MutationResult<DeleteManufacturerMutation>
export type DeleteManufacturerMutationOptions = Apollo.BaseMutationOptions<
  DeleteManufacturerMutation,
  DeleteManufacturerMutationVariables
>
export const CreateParkDocument = gql`
  mutation createPark($createPark: CreatePark!) {
    createPark(park: $createPark) {
      uuid
      name
    }
  }
`
export type CreateParkMutationFn = Apollo.MutationFunction<CreateParkMutation, CreateParkMutationVariables>

/**
 * __useCreateParkMutation__
 *
 * To run a mutation, you first call `useCreateParkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParkMutation, { data, loading, error }] = useCreateParkMutation({
 *   variables: {
 *      createPark: // value for 'createPark'
 *   },
 * });
 */
export function useCreateParkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateParkMutation, CreateParkMutationVariables>
) {
  return Apollo.useMutation<CreateParkMutation, CreateParkMutationVariables>(CreateParkDocument, baseOptions)
}
export type CreateParkMutationHookResult = ReturnType<typeof useCreateParkMutation>
export type CreateParkMutationResult = Apollo.MutationResult<CreateParkMutation>
export type CreateParkMutationOptions = Apollo.BaseMutationOptions<CreateParkMutation, CreateParkMutationVariables>
export const UpdateParkDocument = gql`
  mutation updatePark($updatePark: UpdatePark!) {
    updatePark(park: $updatePark) {
      uuid
      name
    }
  }
`
export type UpdateParkMutationFn = Apollo.MutationFunction<UpdateParkMutation, UpdateParkMutationVariables>

/**
 * __useUpdateParkMutation__
 *
 * To run a mutation, you first call `useUpdateParkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParkMutation, { data, loading, error }] = useUpdateParkMutation({
 *   variables: {
 *      updatePark: // value for 'updatePark'
 *   },
 * });
 */
export function useUpdateParkMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateParkMutation, UpdateParkMutationVariables>
) {
  return Apollo.useMutation<UpdateParkMutation, UpdateParkMutationVariables>(UpdateParkDocument, baseOptions)
}
export type UpdateParkMutationHookResult = ReturnType<typeof useUpdateParkMutation>
export type UpdateParkMutationResult = Apollo.MutationResult<UpdateParkMutation>
export type UpdateParkMutationOptions = Apollo.BaseMutationOptions<UpdateParkMutation, UpdateParkMutationVariables>
export const DeleteParkDocument = gql`
  mutation deletePark($deletePark: DeletePark!) {
    deletePark(park: $deletePark) {
      uuid
      name
    }
  }
`
export type DeleteParkMutationFn = Apollo.MutationFunction<DeleteParkMutation, DeleteParkMutationVariables>

/**
 * __useDeleteParkMutation__
 *
 * To run a mutation, you first call `useDeleteParkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParkMutation, { data, loading, error }] = useDeleteParkMutation({
 *   variables: {
 *      deletePark: // value for 'deletePark'
 *   },
 * });
 */
export function useDeleteParkMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteParkMutation, DeleteParkMutationVariables>
) {
  return Apollo.useMutation<DeleteParkMutation, DeleteParkMutationVariables>(DeleteParkDocument, baseOptions)
}
export type DeleteParkMutationHookResult = ReturnType<typeof useDeleteParkMutation>
export type DeleteParkMutationResult = Apollo.MutationResult<DeleteParkMutation>
export type DeleteParkMutationOptions = Apollo.BaseMutationOptions<DeleteParkMutation, DeleteParkMutationVariables>
export const CreateSalesRepresentativeDocument = gql`
  mutation createSalesRepresentative($createSalesRepresentative: CreateSalesRepresentative!) {
    createSalesRepresentative(salesRepresentative: $createSalesRepresentative) {
      uuid
      email
      firstName
      name
    }
  }
`
export type CreateSalesRepresentativeMutationFn = Apollo.MutationFunction<
  CreateSalesRepresentativeMutation,
  CreateSalesRepresentativeMutationVariables
>

/**
 * __useCreateSalesRepresentativeMutation__
 *
 * To run a mutation, you first call `useCreateSalesRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesRepresentativeMutation, { data, loading, error }] = useCreateSalesRepresentativeMutation({
 *   variables: {
 *      createSalesRepresentative: // value for 'createSalesRepresentative'
 *   },
 * });
 */
export function useCreateSalesRepresentativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSalesRepresentativeMutation,
    CreateSalesRepresentativeMutationVariables
  >
) {
  return Apollo.useMutation<CreateSalesRepresentativeMutation, CreateSalesRepresentativeMutationVariables>(
    CreateSalesRepresentativeDocument,
    baseOptions
  )
}
export type CreateSalesRepresentativeMutationHookResult = ReturnType<typeof useCreateSalesRepresentativeMutation>
export type CreateSalesRepresentativeMutationResult = Apollo.MutationResult<CreateSalesRepresentativeMutation>
export type CreateSalesRepresentativeMutationOptions = Apollo.BaseMutationOptions<
  CreateSalesRepresentativeMutation,
  CreateSalesRepresentativeMutationVariables
>
export const UpdateSalesRepresentativeDocument = gql`
  mutation updateSalesRepresentative($updateSalesRepresentative: UpdateSalesRepresentative!) {
    updateSalesRepresentative(salesRepresentative: $updateSalesRepresentative) {
      uuid
      email
      firstName
      name
    }
  }
`
export type UpdateSalesRepresentativeMutationFn = Apollo.MutationFunction<
  UpdateSalesRepresentativeMutation,
  UpdateSalesRepresentativeMutationVariables
>

/**
 * __useUpdateSalesRepresentativeMutation__
 *
 * To run a mutation, you first call `useUpdateSalesRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesRepresentativeMutation, { data, loading, error }] = useUpdateSalesRepresentativeMutation({
 *   variables: {
 *      updateSalesRepresentative: // value for 'updateSalesRepresentative'
 *   },
 * });
 */
export function useUpdateSalesRepresentativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSalesRepresentativeMutation,
    UpdateSalesRepresentativeMutationVariables
  >
) {
  return Apollo.useMutation<UpdateSalesRepresentativeMutation, UpdateSalesRepresentativeMutationVariables>(
    UpdateSalesRepresentativeDocument,
    baseOptions
  )
}
export type UpdateSalesRepresentativeMutationHookResult = ReturnType<typeof useUpdateSalesRepresentativeMutation>
export type UpdateSalesRepresentativeMutationResult = Apollo.MutationResult<UpdateSalesRepresentativeMutation>
export type UpdateSalesRepresentativeMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalesRepresentativeMutation,
  UpdateSalesRepresentativeMutationVariables
>
export const DeleteSalesRepresentativeDocument = gql`
  mutation deleteSalesRepresentative($deleteSalesRepresentative: DeleteSalesRepresentative!) {
    deleteSalesRepresentative(salesRepresentative: $deleteSalesRepresentative) {
      uuid
      email
      firstName
      name
    }
  }
`
export type DeleteSalesRepresentativeMutationFn = Apollo.MutationFunction<
  DeleteSalesRepresentativeMutation,
  DeleteSalesRepresentativeMutationVariables
>

/**
 * __useDeleteSalesRepresentativeMutation__
 *
 * To run a mutation, you first call `useDeleteSalesRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesRepresentativeMutation, { data, loading, error }] = useDeleteSalesRepresentativeMutation({
 *   variables: {
 *      deleteSalesRepresentative: // value for 'deleteSalesRepresentative'
 *   },
 * });
 */
export function useDeleteSalesRepresentativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSalesRepresentativeMutation,
    DeleteSalesRepresentativeMutationVariables
  >
) {
  return Apollo.useMutation<DeleteSalesRepresentativeMutation, DeleteSalesRepresentativeMutationVariables>(
    DeleteSalesRepresentativeDocument,
    baseOptions
  )
}
export type DeleteSalesRepresentativeMutationHookResult = ReturnType<typeof useDeleteSalesRepresentativeMutation>
export type DeleteSalesRepresentativeMutationResult = Apollo.MutationResult<DeleteSalesRepresentativeMutation>
export type DeleteSalesRepresentativeMutationOptions = Apollo.BaseMutationOptions<
  DeleteSalesRepresentativeMutation,
  DeleteSalesRepresentativeMutationVariables
>
export const ResendRegistrationEmailSalesRepresentativeDocument = gql`
  mutation resendRegistrationEmailSalesRepresentative(
    $resendRegistrationEmailSalesRepresentative: ResendRegistrationEmailSalesRepresentative!
  ) {
    resendRegistrationEmailSalesRepresentative(salesRepresentative: $resendRegistrationEmailSalesRepresentative) {
      uuid
    }
  }
`
export type ResendRegistrationEmailSalesRepresentativeMutationFn = Apollo.MutationFunction<
  ResendRegistrationEmailSalesRepresentativeMutation,
  ResendRegistrationEmailSalesRepresentativeMutationVariables
>

/**
 * __useResendRegistrationEmailSalesRepresentativeMutation__
 *
 * To run a mutation, you first call `useResendRegistrationEmailSalesRepresentativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendRegistrationEmailSalesRepresentativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendRegistrationEmailSalesRepresentativeMutation, { data, loading, error }] = useResendRegistrationEmailSalesRepresentativeMutation({
 *   variables: {
 *      resendRegistrationEmailSalesRepresentative: // value for 'resendRegistrationEmailSalesRepresentative'
 *   },
 * });
 */
export function useResendRegistrationEmailSalesRepresentativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendRegistrationEmailSalesRepresentativeMutation,
    ResendRegistrationEmailSalesRepresentativeMutationVariables
  >
) {
  return Apollo.useMutation<
    ResendRegistrationEmailSalesRepresentativeMutation,
    ResendRegistrationEmailSalesRepresentativeMutationVariables
  >(ResendRegistrationEmailSalesRepresentativeDocument, baseOptions)
}
export type ResendRegistrationEmailSalesRepresentativeMutationHookResult = ReturnType<
  typeof useResendRegistrationEmailSalesRepresentativeMutation
>
export type ResendRegistrationEmailSalesRepresentativeMutationResult = Apollo.MutationResult<ResendRegistrationEmailSalesRepresentativeMutation>
export type ResendRegistrationEmailSalesRepresentativeMutationOptions = Apollo.BaseMutationOptions<
  ResendRegistrationEmailSalesRepresentativeMutation,
  ResendRegistrationEmailSalesRepresentativeMutationVariables
>
export const CreateTicketDocument = gql`
  mutation createTicket($createTicket: CreateTicket!) {
    createTicket(ticket: $createTicket) {
      uuid
    }
  }
`
export type CreateTicketMutationFn = Apollo.MutationFunction<CreateTicketMutation, CreateTicketMutationVariables>

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreateTicketMutation({
 *   variables: {
 *      createTicket: // value for 'createTicket'
 *   },
 * });
 */
export function useCreateTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTicketMutation, CreateTicketMutationVariables>
) {
  return Apollo.useMutation<CreateTicketMutation, CreateTicketMutationVariables>(CreateTicketDocument, baseOptions)
}
export type CreateTicketMutationHookResult = ReturnType<typeof useCreateTicketMutation>
export type CreateTicketMutationResult = Apollo.MutationResult<CreateTicketMutation>
export type CreateTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateTicketMutation,
  CreateTicketMutationVariables
>
export const CheckInTicketDocument = gql`
  mutation checkInTicket($uuid: String!) {
    checkinTicket(uuid: $uuid) {
      uuid
      success
    }
  }
`
export type CheckInTicketMutationFn = Apollo.MutationFunction<CheckInTicketMutation, CheckInTicketMutationVariables>

/**
 * __useCheckInTicketMutation__
 *
 * To run a mutation, you first call `useCheckInTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInTicketMutation, { data, loading, error }] = useCheckInTicketMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCheckInTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<CheckInTicketMutation, CheckInTicketMutationVariables>
) {
  return Apollo.useMutation<CheckInTicketMutation, CheckInTicketMutationVariables>(CheckInTicketDocument, baseOptions)
}
export type CheckInTicketMutationHookResult = ReturnType<typeof useCheckInTicketMutation>
export type CheckInTicketMutationResult = Apollo.MutationResult<CheckInTicketMutation>
export type CheckInTicketMutationOptions = Apollo.BaseMutationOptions<
  CheckInTicketMutation,
  CheckInTicketMutationVariables
>
export const SendTicketEmailDocument = gql`
  mutation sendTicketEmail($uuid: String!) {
    sendTicketEmail(uuid: $uuid)
  }
`
export type SendTicketEmailMutationFn = Apollo.MutationFunction<
  SendTicketEmailMutation,
  SendTicketEmailMutationVariables
>

/**
 * __useSendTicketEmailMutation__
 *
 * To run a mutation, you first call `useSendTicketEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTicketEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTicketEmailMutation, { data, loading, error }] = useSendTicketEmailMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSendTicketEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendTicketEmailMutation, SendTicketEmailMutationVariables>
) {
  return Apollo.useMutation<SendTicketEmailMutation, SendTicketEmailMutationVariables>(
    SendTicketEmailDocument,
    baseOptions
  )
}
export type SendTicketEmailMutationHookResult = ReturnType<typeof useSendTicketEmailMutation>
export type SendTicketEmailMutationResult = Apollo.MutationResult<SendTicketEmailMutation>
export type SendTicketEmailMutationOptions = Apollo.BaseMutationOptions<
  SendTicketEmailMutation,
  SendTicketEmailMutationVariables
>
export const PrintTicketAsPdfDocument = gql`
  mutation printTicketAsPdf($uuid: String!) {
    printTicketAsPdf(uuid: $uuid)
  }
`
export type PrintTicketAsPdfMutationFn = Apollo.MutationFunction<
  PrintTicketAsPdfMutation,
  PrintTicketAsPdfMutationVariables
>

/**
 * __usePrintTicketAsPdfMutation__
 *
 * To run a mutation, you first call `usePrintTicketAsPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintTicketAsPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printTicketAsPdfMutation, { data, loading, error }] = usePrintTicketAsPdfMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePrintTicketAsPdfMutation(
  baseOptions?: Apollo.MutationHookOptions<PrintTicketAsPdfMutation, PrintTicketAsPdfMutationVariables>
) {
  return Apollo.useMutation<PrintTicketAsPdfMutation, PrintTicketAsPdfMutationVariables>(
    PrintTicketAsPdfDocument,
    baseOptions
  )
}
export type PrintTicketAsPdfMutationHookResult = ReturnType<typeof usePrintTicketAsPdfMutation>
export type PrintTicketAsPdfMutationResult = Apollo.MutationResult<PrintTicketAsPdfMutation>
export type PrintTicketAsPdfMutationOptions = Apollo.BaseMutationOptions<
  PrintTicketAsPdfMutation,
  PrintTicketAsPdfMutationVariables
>
export const UpdateVisitorDocument = gql`
  mutation updateVisitor($visitor: UpdateVisitor!) {
    updateVisitor(visitor: $visitor) {
      uuid
      email
      firstName
      name
      numAdults
      numChildren
      address {
        city
        postalCode
        street
        streetNumber
      }
    }
  }
`
export type UpdateVisitorMutationFn = Apollo.MutationFunction<UpdateVisitorMutation, UpdateVisitorMutationVariables>

/**
 * __useUpdateVisitorMutation__
 *
 * To run a mutation, you first call `useUpdateVisitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisitorMutation, { data, loading, error }] = useUpdateVisitorMutation({
 *   variables: {
 *      visitor: // value for 'visitor'
 *   },
 * });
 */
export function useUpdateVisitorMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVisitorMutation, UpdateVisitorMutationVariables>
) {
  return Apollo.useMutation<UpdateVisitorMutation, UpdateVisitorMutationVariables>(UpdateVisitorDocument, baseOptions)
}
export type UpdateVisitorMutationHookResult = ReturnType<typeof useUpdateVisitorMutation>
export type UpdateVisitorMutationResult = Apollo.MutationResult<UpdateVisitorMutation>
export type UpdateVisitorMutationOptions = Apollo.BaseMutationOptions<
  UpdateVisitorMutation,
  UpdateVisitorMutationVariables
>
export const GetAppointmentsDocument = gql`
  query getAppointments($pageQuery: PageQuery!, $dateQuery: DateQuery, $showAll: Boolean) {
    getAppointments(pageQuery: $pageQuery, dateQuery: $dateQuery, showAll: $showAll) {
      pageInfo {
        totalPages
        totalCount
        elementsInPage
      }
      appointments {
        uuid
        date
        status
        salesRepresentative {
          name
          manufacturer {
            name
          }
        }
        visitor {
          email
          firstName
          name
          numAdults
          numChildren
        }
      }
    }
  }
`

/**
 * __useGetAppointmentsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentsQuery({
 *   variables: {
 *      pageQuery: // value for 'pageQuery'
 *      dateQuery: // value for 'dateQuery'
 *      showAll: // value for 'showAll'
 *   },
 * });
 */
export function useGetAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAppointmentsQuery, GetAppointmentsQueryVariables>
) {
  return Apollo.useQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(GetAppointmentsDocument, baseOptions)
}
export function useGetAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentsQuery, GetAppointmentsQueryVariables>
) {
  return Apollo.useLazyQuery<GetAppointmentsQuery, GetAppointmentsQueryVariables>(GetAppointmentsDocument, baseOptions)
}
export type GetAppointmentsQueryHookResult = ReturnType<typeof useGetAppointmentsQuery>
export type GetAppointmentsLazyQueryHookResult = ReturnType<typeof useGetAppointmentsLazyQuery>
export type GetAppointmentsQueryResult = Apollo.QueryResult<GetAppointmentsQuery, GetAppointmentsQueryVariables>
export const GetAppointmentDocument = gql`
  query getAppointment($uuid: String!) {
    getAppointment(uuid: $uuid) {
      uuid
      date
      status
      changeRequestComment
      visitor {
        uuid
        address {
          city
          postalCode
          street
          streetNumber
        }
        email
        firstName
        name
        numAdults
        numChildren
      }
      salesRepresentative {
        uuid
        email
        name
        firstName
        manufacturer {
          uuid
          name
        }
        park {
          uuid
          name
        }
      }
    }
  }
`

/**
 * __useGetAppointmentQuery__
 *
 * To run a query within a React component, call `useGetAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<GetAppointmentQuery, GetAppointmentQueryVariables>
) {
  return Apollo.useQuery<GetAppointmentQuery, GetAppointmentQueryVariables>(GetAppointmentDocument, baseOptions)
}
export function useGetAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentQuery, GetAppointmentQueryVariables>
) {
  return Apollo.useLazyQuery<GetAppointmentQuery, GetAppointmentQueryVariables>(GetAppointmentDocument, baseOptions)
}
export type GetAppointmentQueryHookResult = ReturnType<typeof useGetAppointmentQuery>
export type GetAppointmentLazyQueryHookResult = ReturnType<typeof useGetAppointmentLazyQuery>
export type GetAppointmentQueryResult = Apollo.QueryResult<GetAppointmentQuery, GetAppointmentQueryVariables>
export const GetCenterManagersDocument = gql`
  query getCenterManagers($pageQuery: PageQuery!) {
    getCenterManagers(pageQuery: $pageQuery) {
      pageInfo {
        totalPages
        totalCount
        elementsInPage
      }
      centerManagers {
        uuid
        email
        name
        firstName
        parks {
          uuid
          name
        }
      }
    }
  }
`

/**
 * __useGetCenterManagersQuery__
 *
 * To run a query within a React component, call `useGetCenterManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCenterManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCenterManagersQuery({
 *   variables: {
 *      pageQuery: // value for 'pageQuery'
 *   },
 * });
 */
export function useGetCenterManagersQuery(
  baseOptions: Apollo.QueryHookOptions<GetCenterManagersQuery, GetCenterManagersQueryVariables>
) {
  return Apollo.useQuery<GetCenterManagersQuery, GetCenterManagersQueryVariables>(
    GetCenterManagersDocument,
    baseOptions
  )
}
export function useGetCenterManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCenterManagersQuery, GetCenterManagersQueryVariables>
) {
  return Apollo.useLazyQuery<GetCenterManagersQuery, GetCenterManagersQueryVariables>(
    GetCenterManagersDocument,
    baseOptions
  )
}
export type GetCenterManagersQueryHookResult = ReturnType<typeof useGetCenterManagersQuery>
export type GetCenterManagersLazyQueryHookResult = ReturnType<typeof useGetCenterManagersLazyQuery>
export type GetCenterManagersQueryResult = Apollo.QueryResult<GetCenterManagersQuery, GetCenterManagersQueryVariables>
export const GetCenterManagerDocument = gql`
  query getCenterManager($uuid: String!) {
    getCenterManager(uuid: $uuid) {
      uuid
      email
      name
      firstName
      parks {
        uuid
        name
      }
    }
  }
`

/**
 * __useGetCenterManagerQuery__
 *
 * To run a query within a React component, call `useGetCenterManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCenterManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCenterManagerQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetCenterManagerQuery(
  baseOptions: Apollo.QueryHookOptions<GetCenterManagerQuery, GetCenterManagerQueryVariables>
) {
  return Apollo.useQuery<GetCenterManagerQuery, GetCenterManagerQueryVariables>(GetCenterManagerDocument, baseOptions)
}
export function useGetCenterManagerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCenterManagerQuery, GetCenterManagerQueryVariables>
) {
  return Apollo.useLazyQuery<GetCenterManagerQuery, GetCenterManagerQueryVariables>(
    GetCenterManagerDocument,
    baseOptions
  )
}
export type GetCenterManagerQueryHookResult = ReturnType<typeof useGetCenterManagerQuery>
export type GetCenterManagerLazyQueryHookResult = ReturnType<typeof useGetCenterManagerLazyQuery>
export type GetCenterManagerQueryResult = Apollo.QueryResult<GetCenterManagerQuery, GetCenterManagerQueryVariables>
export const GetManufacturersDocument = gql`
  query getManufacturers($pageQuery: PageQuery!) {
    getManufacturers(pageQuery: $pageQuery) {
      pageInfo {
        totalPages
        totalCount
        elementsInPage
      }
      manufacturers {
        uuid
        name
        address {
          city
          postalCode
          street
          streetNumber
        }
      }
    }
  }
`

/**
 * __useGetManufacturersQuery__
 *
 * To run a query within a React component, call `useGetManufacturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManufacturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManufacturersQuery({
 *   variables: {
 *      pageQuery: // value for 'pageQuery'
 *   },
 * });
 */
export function useGetManufacturersQuery(
  baseOptions: Apollo.QueryHookOptions<GetManufacturersQuery, GetManufacturersQueryVariables>
) {
  return Apollo.useQuery<GetManufacturersQuery, GetManufacturersQueryVariables>(GetManufacturersDocument, baseOptions)
}
export function useGetManufacturersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManufacturersQuery, GetManufacturersQueryVariables>
) {
  return Apollo.useLazyQuery<GetManufacturersQuery, GetManufacturersQueryVariables>(
    GetManufacturersDocument,
    baseOptions
  )
}
export type GetManufacturersQueryHookResult = ReturnType<typeof useGetManufacturersQuery>
export type GetManufacturersLazyQueryHookResult = ReturnType<typeof useGetManufacturersLazyQuery>
export type GetManufacturersQueryResult = Apollo.QueryResult<GetManufacturersQuery, GetManufacturersQueryVariables>
export const GetManufacturersByParkDocument = gql`
  query getManufacturersByPark($parkUuid: String!) {
    getManufacturersByPark(parkUuid: $parkUuid) {
      uuid
      name
      address {
        city
        postalCode
        street
        streetNumber
      }
    }
  }
`

/**
 * __useGetManufacturersByParkQuery__
 *
 * To run a query within a React component, call `useGetManufacturersByParkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManufacturersByParkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManufacturersByParkQuery({
 *   variables: {
 *      parkUuid: // value for 'parkUuid'
 *   },
 * });
 */
export function useGetManufacturersByParkQuery(
  baseOptions: Apollo.QueryHookOptions<GetManufacturersByParkQuery, GetManufacturersByParkQueryVariables>
) {
  return Apollo.useQuery<GetManufacturersByParkQuery, GetManufacturersByParkQueryVariables>(
    GetManufacturersByParkDocument,
    baseOptions
  )
}
export function useGetManufacturersByParkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManufacturersByParkQuery, GetManufacturersByParkQueryVariables>
) {
  return Apollo.useLazyQuery<GetManufacturersByParkQuery, GetManufacturersByParkQueryVariables>(
    GetManufacturersByParkDocument,
    baseOptions
  )
}
export type GetManufacturersByParkQueryHookResult = ReturnType<typeof useGetManufacturersByParkQuery>
export type GetManufacturersByParkLazyQueryHookResult = ReturnType<typeof useGetManufacturersByParkLazyQuery>
export type GetManufacturersByParkQueryResult = Apollo.QueryResult<
  GetManufacturersByParkQuery,
  GetManufacturersByParkQueryVariables
>
export const GetManufacturerDocument = gql`
  query getManufacturer($uuid: String!) {
    getManufacturer(uuid: $uuid) {
      uuid
      name
      address {
        city
        postalCode
        street
        streetNumber
      }
    }
  }
`

/**
 * __useGetManufacturerQuery__
 *
 * To run a query within a React component, call `useGetManufacturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManufacturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManufacturerQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetManufacturerQuery(
  baseOptions: Apollo.QueryHookOptions<GetManufacturerQuery, GetManufacturerQueryVariables>
) {
  return Apollo.useQuery<GetManufacturerQuery, GetManufacturerQueryVariables>(GetManufacturerDocument, baseOptions)
}
export function useGetManufacturerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManufacturerQuery, GetManufacturerQueryVariables>
) {
  return Apollo.useLazyQuery<GetManufacturerQuery, GetManufacturerQueryVariables>(GetManufacturerDocument, baseOptions)
}
export type GetManufacturerQueryHookResult = ReturnType<typeof useGetManufacturerQuery>
export type GetManufacturerLazyQueryHookResult = ReturnType<typeof useGetManufacturerLazyQuery>
export type GetManufacturerQueryResult = Apollo.QueryResult<GetManufacturerQuery, GetManufacturerQueryVariables>
export const GetParksDocument = gql`
  query getParks($pageQuery: PageQuery!) {
    getParks(pageQuery: $pageQuery) {
      pageInfo {
        totalPages
        totalCount
        elementsInPage
      }
      parks {
        uuid
        name
        address {
          city
          postalCode
          street
          streetNumber
        }
      }
    }
  }
`

/**
 * __useGetParksQuery__
 *
 * To run a query within a React component, call `useGetParksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParksQuery({
 *   variables: {
 *      pageQuery: // value for 'pageQuery'
 *   },
 * });
 */
export function useGetParksQuery(baseOptions: Apollo.QueryHookOptions<GetParksQuery, GetParksQueryVariables>) {
  return Apollo.useQuery<GetParksQuery, GetParksQueryVariables>(GetParksDocument, baseOptions)
}
export function useGetParksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParksQuery, GetParksQueryVariables>) {
  return Apollo.useLazyQuery<GetParksQuery, GetParksQueryVariables>(GetParksDocument, baseOptions)
}
export type GetParksQueryHookResult = ReturnType<typeof useGetParksQuery>
export type GetParksLazyQueryHookResult = ReturnType<typeof useGetParksLazyQuery>
export type GetParksQueryResult = Apollo.QueryResult<GetParksQuery, GetParksQueryVariables>
export const GetParksByManufaturerDocument = gql`
  query getParksByManufaturer($manufacturerUuid: String!) {
    getParksByManufacturer(manufacturerUuid: $manufacturerUuid) {
      uuid
      name
      address {
        city
        postalCode
        street
        streetNumber
      }
    }
  }
`

/**
 * __useGetParksByManufaturerQuery__
 *
 * To run a query within a React component, call `useGetParksByManufaturerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParksByManufaturerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParksByManufaturerQuery({
 *   variables: {
 *      manufacturerUuid: // value for 'manufacturerUuid'
 *   },
 * });
 */
export function useGetParksByManufaturerQuery(
  baseOptions: Apollo.QueryHookOptions<GetParksByManufaturerQuery, GetParksByManufaturerQueryVariables>
) {
  return Apollo.useQuery<GetParksByManufaturerQuery, GetParksByManufaturerQueryVariables>(
    GetParksByManufaturerDocument,
    baseOptions
  )
}
export function useGetParksByManufaturerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetParksByManufaturerQuery, GetParksByManufaturerQueryVariables>
) {
  return Apollo.useLazyQuery<GetParksByManufaturerQuery, GetParksByManufaturerQueryVariables>(
    GetParksByManufaturerDocument,
    baseOptions
  )
}
export type GetParksByManufaturerQueryHookResult = ReturnType<typeof useGetParksByManufaturerQuery>
export type GetParksByManufaturerLazyQueryHookResult = ReturnType<typeof useGetParksByManufaturerLazyQuery>
export type GetParksByManufaturerQueryResult = Apollo.QueryResult<
  GetParksByManufaturerQuery,
  GetParksByManufaturerQueryVariables
>
export const GetParkDocument = gql`
  query getPark($uuid: String!) {
    getPark(uuid: $uuid) {
      uuid
      name
      address {
        city
        postalCode
        street
        streetNumber
      }
    }
  }
`

/**
 * __useGetParkQuery__
 *
 * To run a query within a React component, call `useGetParkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParkQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetParkQuery(baseOptions: Apollo.QueryHookOptions<GetParkQuery, GetParkQueryVariables>) {
  return Apollo.useQuery<GetParkQuery, GetParkQueryVariables>(GetParkDocument, baseOptions)
}
export function useGetParkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParkQuery, GetParkQueryVariables>) {
  return Apollo.useLazyQuery<GetParkQuery, GetParkQueryVariables>(GetParkDocument, baseOptions)
}
export type GetParkQueryHookResult = ReturnType<typeof useGetParkQuery>
export type GetParkLazyQueryHookResult = ReturnType<typeof useGetParkLazyQuery>
export type GetParkQueryResult = Apollo.QueryResult<GetParkQuery, GetParkQueryVariables>
export const GetSalesRepresentativesDocument = gql`
  query getSalesRepresentatives($pageQuery: PageQuery!) {
    getSalesRepresentatives(pageQuery: $pageQuery) {
      pageInfo {
        totalPages
        totalCount
        elementsInPage
      }
      salesRepresentatives {
        uuid
        email
        firstName
        name
        manufacturer {
          name
          uuid
        }
        park {
          name
          uuid
        }
      }
    }
  }
`

/**
 * __useGetSalesRepresentativesQuery__
 *
 * To run a query within a React component, call `useGetSalesRepresentativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesRepresentativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesRepresentativesQuery({
 *   variables: {
 *      pageQuery: // value for 'pageQuery'
 *   },
 * });
 */
export function useGetSalesRepresentativesQuery(
  baseOptions: Apollo.QueryHookOptions<GetSalesRepresentativesQuery, GetSalesRepresentativesQueryVariables>
) {
  return Apollo.useQuery<GetSalesRepresentativesQuery, GetSalesRepresentativesQueryVariables>(
    GetSalesRepresentativesDocument,
    baseOptions
  )
}
export function useGetSalesRepresentativesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSalesRepresentativesQuery, GetSalesRepresentativesQueryVariables>
) {
  return Apollo.useLazyQuery<GetSalesRepresentativesQuery, GetSalesRepresentativesQueryVariables>(
    GetSalesRepresentativesDocument,
    baseOptions
  )
}
export type GetSalesRepresentativesQueryHookResult = ReturnType<typeof useGetSalesRepresentativesQuery>
export type GetSalesRepresentativesLazyQueryHookResult = ReturnType<typeof useGetSalesRepresentativesLazyQuery>
export type GetSalesRepresentativesQueryResult = Apollo.QueryResult<
  GetSalesRepresentativesQuery,
  GetSalesRepresentativesQueryVariables
>
export const GetSalesRepresentativeDocument = gql`
  query getSalesRepresentative($uuid: String!) {
    getSalesRepresentative(uuid: $uuid) {
      uuid
      email
      firstName
      name
      manufacturer {
        name
        uuid
      }
      park {
        name
        uuid
      }
    }
  }
`

/**
 * __useGetSalesRepresentativeQuery__
 *
 * To run a query within a React component, call `useGetSalesRepresentativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesRepresentativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesRepresentativeQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetSalesRepresentativeQuery(
  baseOptions: Apollo.QueryHookOptions<GetSalesRepresentativeQuery, GetSalesRepresentativeQueryVariables>
) {
  return Apollo.useQuery<GetSalesRepresentativeQuery, GetSalesRepresentativeQueryVariables>(
    GetSalesRepresentativeDocument,
    baseOptions
  )
}
export function useGetSalesRepresentativeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSalesRepresentativeQuery, GetSalesRepresentativeQueryVariables>
) {
  return Apollo.useLazyQuery<GetSalesRepresentativeQuery, GetSalesRepresentativeQueryVariables>(
    GetSalesRepresentativeDocument,
    baseOptions
  )
}
export type GetSalesRepresentativeQueryHookResult = ReturnType<typeof useGetSalesRepresentativeQuery>
export type GetSalesRepresentativeLazyQueryHookResult = ReturnType<typeof useGetSalesRepresentativeLazyQuery>
export type GetSalesRepresentativeQueryResult = Apollo.QueryResult<
  GetSalesRepresentativeQuery,
  GetSalesRepresentativeQueryVariables
>
export const GetTicketsDocument = gql`
  query getTickets($pageQuery: PageQuery!, $dateQuery: DateQuery) {
    getTickets(pageQuery: $pageQuery, dateQuery: $dateQuery) {
      pageInfo {
        totalPages
        totalCount
        elementsInPage
      }
      tickets {
        uuid
        comment
        expectedEntryDate
        remainingEntries
        isValid
        type
        visitor {
          uuid
          email
          firstName
          name
          numAdults
          numChildren
          appointments {
            salesRepresentative {
              name
              manufacturer {
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetTicketsQuery__
 *
 * To run a query within a React component, call `useGetTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketsQuery({
 *   variables: {
 *      pageQuery: // value for 'pageQuery'
 *      dateQuery: // value for 'dateQuery'
 *   },
 * });
 */
export function useGetTicketsQuery(baseOptions: Apollo.QueryHookOptions<GetTicketsQuery, GetTicketsQueryVariables>) {
  return Apollo.useQuery<GetTicketsQuery, GetTicketsQueryVariables>(GetTicketsDocument, baseOptions)
}
export function useGetTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTicketsQuery, GetTicketsQueryVariables>
) {
  return Apollo.useLazyQuery<GetTicketsQuery, GetTicketsQueryVariables>(GetTicketsDocument, baseOptions)
}
export type GetTicketsQueryHookResult = ReturnType<typeof useGetTicketsQuery>
export type GetTicketsLazyQueryHookResult = ReturnType<typeof useGetTicketsLazyQuery>
export type GetTicketsQueryResult = Apollo.QueryResult<GetTicketsQuery, GetTicketsQueryVariables>
export const GetTicketDocument = gql`
  query getTicket($uuid: String!) {
    getTicket(uuid: $uuid) {
      uuid
      comment
      expectedEntryDate
      hasIncompleteVisitorData
      visitor {
        uuid
        email
        firstName
        name
        address {
          city
          postalCode
          street
          streetNumber
        }
        numAdults
        numChildren
      }
      hasIncompleteVisitorData
      remainingEntries
      isValid
      type
    }
  }
`

/**
 * __useGetTicketQuery__
 *
 * To run a query within a React component, call `useGetTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetTicketQuery(baseOptions: Apollo.QueryHookOptions<GetTicketQuery, GetTicketQueryVariables>) {
  return Apollo.useQuery<GetTicketQuery, GetTicketQueryVariables>(GetTicketDocument, baseOptions)
}
export function useGetTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTicketQuery, GetTicketQueryVariables>
) {
  return Apollo.useLazyQuery<GetTicketQuery, GetTicketQueryVariables>(GetTicketDocument, baseOptions)
}
export type GetTicketQueryHookResult = ReturnType<typeof useGetTicketQuery>
export type GetTicketLazyQueryHookResult = ReturnType<typeof useGetTicketLazyQuery>
export type GetTicketQueryResult = Apollo.QueryResult<GetTicketQuery, GetTicketQueryVariables>
