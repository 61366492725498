import { Box, Paper } from '@material-ui/core'
import React from 'react'

export const PaperModal: React.FC = (props) => {
  return (
    <Paper elevation={1} style={{ width: '60vw', maxWidth: '750px' }}>
      <Box p={2}>{props.children}</Box>
    </Paper>
  )
}
