import { useRouteMatch } from 'react-router-dom'

export const routes = {
  public: {
    RegisterForAppointment: {
      pathDef: '/public/register-appointment/:uuid',
      get: (uuid: string) => `/public/register-appointment/${uuid}`,
    },
    ChangeAppointment: {
      pathDef: '/public/change-appointment/:uuid',
      get: (uuid: string) => `/public/change-appointment/${uuid}`,
    },
  },
  authenticated: {
    Home: {
      pathDef: '/',
    },
    Appointments: {
      _group: '/appointments',
      CalendarAndTable: {
        pathDef: '/appointments/list',
      },
      Detail: {
        pathDef: '/appointments/detail/:uuid',
        get: (uuid: string) => `/appointments/detail/${uuid}`,
      },
    },
    Tickets: {
      _group: '/tickets',
      Table: {
        _group: '/tickets/list',
        List: {
          pathDef: '/tickets/list',
        },
        Detail: {
          pathDef: '/tickets/list/detail/:uuid',
          get: (uuid: string) => `/tickets/list/detail/${uuid}`,
        },
        RegisterVisitor: {
          pathDef: '/tickets/list/register/:uuid',
          get: (uuid: string) => `/tickets/list/register/${uuid}`,
        },
      },
      Scan: {
        _group: '/tickets/scan',
        TicketScan: {
          pathDef: '/tickets/scan',
        },
        Detail: {
          pathDef: '/tickets/scan/detail/:uuid',
          get: (uuid: string) => `/tickets/scan/detail/${uuid}`,
        },
        RegisterVisitor: {
          pathDef: '/tickets/scan/register/:uuid',
          get: (uuid: string) => `/tickets/scan/register/${uuid}`,
        },
      },
    },
    CenterManagers: {
      pathDef: '/center-managers',
    },
    Manufacturers: {
      pathDef: '/manufacturers',
    },
    Parks: {
      pathDef: '/parks',
    },
    SalesRepresentatives: {
      pathDef: '/sales-representatives',
    },
    Reports: {
      pathDef: '/reports',
    },
  },
}

export const useNestedRoutePath = () => {
  const insideTablePathMatch = useRouteMatch(routes.authenticated.Tickets.Table._group)
  const insideScanPathMatch = useRouteMatch(routes.authenticated.Tickets.Scan._group)

  return {
    isInsideTicketScanPath: !!insideScanPathMatch,
    isInsideTicketTablePath: !!insideTablePathMatch,
  }
}
