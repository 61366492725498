import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { routes, useNestedRoutePath } from '../../../../utils/routeUtils'
import { useGetTicketQuery, useUpdateVisitorMutation } from '../../../../generated/graphql-hooks'
import { useNotifications } from '../../../../utils/notificationUtils'
import { useHistory } from 'react-router-dom'
import { formikTextFieldArgs } from '../../../../utils/formUtils'
import { LoadingBackdrop } from '../../../../components/LoadingBackdrop'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { LimitedWidthGridContainer } from '../../../../components/LimitedWidthGridContainer'
import { TicketType } from '../../../../generated/graphql-types'

export const TicketRegistration: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { splashError, splashSuccess, showError } = useNotifications()
  const [getTicketQueryDone, setGetTicketQueryDone] = useState(false)
  const history = useHistory()
  const { data: getTicketData, loading: getTicketLoading } = useGetTicketQuery({
    onCompleted: () => setGetTicketQueryDone(true),
    variables: {
      uuid,
    },
  })

  const { isInsideTicketScanPath } = useNestedRoutePath()

  useEffect(() => {
    if (!getTicketLoading || !getTicketQueryDone) {
      return
    }

    if (!getTicketData?.getTicket) {
      splashError('Ticket konnte nicht gefunden werden.').then(() =>
        history.push(routes.authenticated.Tickets.Table.List.pathDef)
      )
      return
    }
    if (getTicketData?.getTicket?.type !== TicketType.Appointment) {
      splashError('Ticket ist kein Termin-Ticket').then(() =>
        history.push(routes.authenticated.Tickets.Table.List.pathDef)
      )
      return
    }
  }, [getTicketLoading, getTicketData, getTicketQueryDone])

  const validationSchema = yup.object({
    firstName: yup.string().required('Vorname ist erforderlich'),
    name: yup.string().required('Name ist erforderlich'),
    street: yup.string().required('Straße ist erforderlich'),
    streetNumber: yup.string().required('Hausnummer ist erforderlich'),
    postalCode: yup.string().required('Postleitzahl ist erforderlich'),
    city: yup.string().required('Stadt ist erforderlich'),
    numAdults: yup
      .number()
      .typeError('Bitte eine Zahl eingeben')
      .integer('Bitte eine Ganzzahl eingeben')
      .min(1, 'Mindestens ein Erwachsener')
      .required('Anzahl Erwachsene ist erforderlich'),
    numChildren: yup
      .number()
      .typeError('Bitte eine Zahl eingeben')
      .integer('Bitte eine Ganzzahl eingeben')
      .min(0)
      .required('Anzahl Kinder ist erforderlich'),
  })

  const [
    updateVisitorMutation,
    { loading: updateVisitorLoading, error: updateVisitorError },
  ] = useUpdateVisitorMutation({
    refetchQueries: ['getTicket', 'getTickets'],
  })
  const formik = useFormik({
    initialValues: {
      firstName: getTicketData?.getTicket?.visitor?.firstName || '',
      name: getTicketData?.getTicket?.visitor?.name || '',
      street: getTicketData?.getTicket?.visitor?.address?.street || '',
      streetNumber: getTicketData?.getTicket?.visitor?.address?.streetNumber || '',
      postalCode: getTicketData?.getTicket?.visitor?.address?.postalCode || '',
      city: getTicketData?.getTicket?.visitor?.address?.city || '',
      numAdults: getTicketData?.getTicket?.visitor?.numAdults || 1,
      numChildren: getTicketData?.getTicket?.visitor?.numChildren || 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateVisitorMutation({
        variables: {
          visitor: {
            uuid: getTicketData?.getTicket?.visitor?.uuid || '',
            firstName: values.firstName,
            name: values.name,
            numAdults: values.numAdults,
            numChildren: values.numChildren,
            address: {
              street: values.street,
              streetNumber: values.streetNumber,
              postalCode: values.postalCode,
              city: values.city,
            },
          },
        },
      })
        .then(() =>
          splashSuccess('Der Besucher wurde erfolgreich registriert.').then(() => {
            if (isInsideTicketScanPath) {
              history.push(routes.authenticated.Tickets.Scan.Detail.get(getTicketData?.getTicket?.uuid || ''))
            } else {
              history.push(routes.authenticated.Tickets.Table.Detail.get(getTicketData?.getTicket?.uuid || ''))
            }
          })
        )
        .catch((err) => {
          console.error(err)
          splashError('Besucher konnte nicht registriert werden.')
        })
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <LimitedWidthGridContainer>
          <Grid item xs={12}>
            <Typography variant={'h6'} component={'h2'}>
              Besucher registrieren
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={'outlined'}
              label={'Vorname'}
              placeholder={'Vorname'}
              {...formikTextFieldArgs('firstName', formik)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={'outlined'}
              label={'Nachname'}
              placeholder={'Nachname'}
              {...formikTextFieldArgs('name', formik)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={'outlined'}
              label={'Straße'}
              placeholder={'Straße'}
              {...formikTextFieldArgs('street', formik)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={'outlined'}
              label={'Hausnummer'}
              placeholder={'Hausnummer'}
              {...formikTextFieldArgs('streetNumber', formik)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={'outlined'}
              label={'Postleitzahl'}
              placeholder={'Postleitzahl'}
              {...formikTextFieldArgs('postalCode', formik)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={'outlined'}
              label={'Stadt'}
              placeholder={'Stadt'}
              {...formikTextFieldArgs('city', formik)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={'outlined'}
              type={'number'}
              InputProps={{ inputProps: { min: 1 } }}
              label={'Anzahl Erwachsene'}
              placeholder={'Anzahl Erwachsene'}
              {...formikTextFieldArgs('numAdults', formik)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant={'outlined'}
              type={'number'}
              InputProps={{ inputProps: { min: 0 } }}
              label={'Anzahl Kinder'}
              placeholder={'Anzahl Kinder'}
              {...formikTextFieldArgs('numChildren', formik)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box style={{ textAlign: 'right' }}>
              <Button
                type="submit"
                variant={'contained'}
                color={'primary'}
                disabled={getTicketLoading || updateVisitorLoading}
                fullWidth
              >
                Besucher registrieren
              </Button>
            </Box>
          </Grid>
        </LimitedWidthGridContainer>
      </form>
      <LoadingBackdrop loading={getTicketLoading || updateVisitorLoading} />
    </>
  )
}
