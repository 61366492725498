import { styled } from '../theme'
import { Tabs } from '@material-ui/core'

export const PillStyleTabs = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 12px;
  }
  .MuiTab-root {
    min-height: 12px;
    border-radius: 174px;
  }
  .MuiTab-wrapper {
    position: relative;
    z-index: 2;
  }
  .MuiTabs-indicator {
    height: 70%;
    border-radius: 174px;
    background-color: #88b4e2;
    z-index: 1;
    transform: translate(0, -20%);
  }
  .Mui-selected {
    color: #fff;
  }
`
