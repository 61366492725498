import { FormikValues } from 'formik/dist/types'

export const formikTextFieldArgs = (name: string, formik: FormikValues) => {
  return {
    name: name,
    id: name,
    value: formik.values[name],
    onChange: formik.handleChange,
    error: formik.touched[name] && Boolean(formik.errors[name]),
    helperText: formik.touched[name] && formik.errors[name],
  }
}
