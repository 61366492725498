import React from 'react'

export const useTableState = <T>() => {
  // Handle opening of edit row
  const [editDialogOpen, setEditDialogOpen] = React.useState(false)

  // Handle opening of cancel row
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false)

  // Handle selected row
  const [selectedRow, setSelectedRow] = React.useState<T | null>(null)
  return {
    editDialogOpen,
    setEditDialogOpen,
    cancelDialogOpen,
    setCancelDialogOpen,
    selectedRow,
    setSelectedRow,
  }
}
