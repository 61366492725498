import { styled } from '../theme'

export const TableActionWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.palette.grey[400]};
  height: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
`
