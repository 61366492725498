import React from 'react'
import { Box, Tab } from '@material-ui/core'
import { useAuthData, UserRole } from './auth/AuthenticationProvider'
import { matchPath, useHistory, useLocation } from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

export interface TabSpec {
  id: number
  action?: JSX.Element
  label: string
  content: (params: Record<string, unknown>) => JSX.Element
  authorizedRoles?: UserRole[]
  route: string
}

interface UseTabsValue {
  currentId: number
  change: (id: number) => void
  renderTabs: () => JSX.Element[]
  renderCurrent: () => JSX.Element
  renderAction: () => JSX.Element
}
export const useTabs = (tabSpecs: TabSpec[]): UseTabsValue => {
  const { pathname } = useLocation()
  const history = useHistory()
  const authData = useAuthData()
  const tabsToDisplay = tabSpecs.filter(
    (x) => !x.authorizedRoles || x.authorizedRoles.includes(authData.getUser().role)
  )

  const change = (id: number) => {
    const newTab = tabsToDisplay.find((x) => x.id === id)
    if (newTab) {
      history.push(newTab.route)
    }
  }

  const renderTabs = () => tabsToDisplay.map((tab) => <Tab key={tab.id} label={tab.label} value={tab.id} />)

  let current = tabsToDisplay[0]
  let params: Record<string, unknown> = {}
  for (const tab of tabsToDisplay) {
    const match = matchPath(pathname, {
      path: tab.route,
    })
    // console.log(`${pathname} and match for ${tab.route} is ${match}`)
    if (match) {
      params = match.params
      current = tab
    }
  }

  const renderCurrent = () => current.content(params)
  const renderAction = () => current.action || <></>

  return { currentId: current.id, change, renderTabs, renderCurrent, renderAction }
}
