import React, { useEffect, useMemo, useState } from 'react'
import { useNotifications } from '../../../../utils/notificationUtils'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../utils/routeUtils'
import { useGetAppointmentQuery } from '../../../../generated/graphql-hooks'
import { LoadingBackdrop } from '../../../../components/LoadingBackdrop'
import { Box, Button, Chip, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { labels } from '../../../../utils/labelUtils'
import { deSerializeDate, formatDate, formatTime } from '../../../../utils/dateUtils'
import { LimitedWidthGridContainer } from '../../../../components/LimitedWidthGridContainer'
import { UpdateAppointmentDialog } from '../../dialogs/UpdateAppointmentDialog'
import { CancelAppointmentDialog } from '../../dialogs/CancelAppointmentDialog'
import { MarkAppointmentAsMissedDialog } from '../../dialogs/MarkAppointmentAsMissedDialog'
import { ResendAppointmentInvitationDialog } from '../../dialogs/ResendAppointmentInvitationDialog'
import { AppointmentStatus } from '../../../../generated/graphql-types'
import { AppointmentStatusBadge } from '../../../../components/AppointmentStatusBadge'

export const AppointmentDetail: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { splashError, splashSuccess, showError } = useNotifications()
  interface DialogState {
    updateDialogOpen: boolean
    cancelDialogOpen: boolean
    resendInvitationDialogOpen: boolean
    markAsMissedDialogOpen: boolean
  }
  const [dialogState, setDialogState] = useState<DialogState>({
    updateDialogOpen: false,
    cancelDialogOpen: false,
    resendInvitationDialogOpen: false,
    markAsMissedDialogOpen: false,
  })

  const anyDialogIsOpen = useMemo(() => Object.values(dialogState).filter((isOpen) => isOpen).length > 0, [dialogState])

  const history = useHistory()
  const {
    data: getAppointmentData,
    loading: getAppointmentLoading,
    called: getAppointmentCalled,
  } = useGetAppointmentQuery({
    variables: {
      uuid,
    },
  })

  const closeDialogs = () => {
    setDialogState({
      cancelDialogOpen: false,
      updateDialogOpen: false,
      markAsMissedDialogOpen: false,
      resendInvitationDialogOpen: false,
    })
  }

  const openDialog = (dialog: keyof DialogState) => {
    setDialogState((prevState) => ({
      ...prevState,
      [dialog]: true,
    }))
  }

  useEffect(() => {
    if (getAppointmentCalled && !getAppointmentLoading && !getAppointmentData?.getAppointment) {
      splashError('Termin konnte nicht gefunden werden.').then(() =>
        history.push(routes.authenticated.Appointments.CalendarAndTable.pathDef)
      )
    }
  }, [getAppointmentCalled, getAppointmentLoading, getAppointmentData])

  const appointment = getAppointmentData?.getAppointment || null

  return (
    <>
      {appointment && (
        <LimitedWidthGridContainer>
          <Grid item xs={12}>
            <Typography variant={'h6'} component={'h2'}>
              Termin Details
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              disabled={true}
              variant={'outlined'}
              value={''}
              label={'Status'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AppointmentStatusBadge status={appointment.status} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              disabled={true}
              variant={'outlined'}
              value={formatDate(deSerializeDate(appointment.date || ''))}
              label={'Datum'}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              disabled={true}
              variant={'outlined'}
              value={formatTime(deSerializeDate(appointment.date || ''))}
              label={'Uhrzeit'}
            />
          </Grid>
          {appointment.status === AppointmentStatus.ChangeRequested && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline={true}
                rows={5}
                disabled={true}
                variant={'outlined'}
                value={appointment.changeRequestComment}
                label={'Grund für die Änderung'}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled={true}
              variant={'outlined'}
              value={appointment.visitor?.firstName || ''}
              label={'Vorname'}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled={true}
              variant={'outlined'}
              value={appointment.visitor?.name || ''}
              label={'Nachname'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={true}
              variant={'outlined'}
              value={appointment.visitor?.email || ''}
              label={'Email'}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled={true}
              variant={'outlined'}
              value={appointment.visitor?.numAdults || 1}
              label={'Anzahl Erwachsene'}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled={true}
              variant={'outlined'}
              value={appointment.visitor?.numChildren || 0}
              label={'Anzahl Kinder'}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={'outlined'}
              fullWidth
              disabled={anyDialogIsOpen}
              onClick={() => openDialog('markAsMissedDialogOpen')}
            >
              Termin verpasst
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={'outlined'}
              fullWidth
              disabled={
                anyDialogIsOpen ||
                appointment.status === AppointmentStatus.Missed ||
                appointment.status === AppointmentStatus.Canceled
              }
              onClick={() => openDialog('cancelDialogOpen')}
            >
              Absagen
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={'outlined'}
              fullWidth
              disabled={
                anyDialogIsOpen ||
                appointment.status === AppointmentStatus.Missed ||
                appointment.status === AppointmentStatus.Canceled
              }
              onClick={() => openDialog('updateDialogOpen')}
            >
              Bearbeiten
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant={'outlined'}
              fullWidth
              disabled={
                anyDialogIsOpen ||
                appointment.status === AppointmentStatus.Missed ||
                appointment.status === AppointmentStatus.Canceled ||
                appointment.status === AppointmentStatus.Registered ||
                appointment.status === AppointmentStatus.ChangeRequested
              }
              onClick={() => openDialog('resendInvitationDialogOpen')}
            >
              Einladung neu senden
            </Button>
          </Grid>
        </LimitedWidthGridContainer>
      )}
      <UpdateAppointmentDialog
        selectedRow={appointment}
        open={dialogState.updateDialogOpen}
        handleClose={closeDialogs}
      />
      <CancelAppointmentDialog
        selectedRow={appointment}
        open={dialogState.cancelDialogOpen}
        handleClose={closeDialogs}
      />
      <MarkAppointmentAsMissedDialog
        selectedRow={appointment}
        open={dialogState.markAsMissedDialogOpen}
        handleClose={closeDialogs}
      />
      <ResendAppointmentInvitationDialog
        selectedRow={appointment}
        open={dialogState.resendInvitationDialogOpen}
        handleClose={closeDialogs}
      />

      <LoadingBackdrop loading={getAppointmentLoading} />
    </>
  )
}
