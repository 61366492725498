import { DialogProps } from './UpdateAppointmentDialog'
import { Park } from '../../../generated/graphql-types'
import { useDeleteParkMutation } from '../../../generated/graphql-hooks'
import { useAuthData } from '../../../utils/auth/AuthenticationProvider'
import { useFormik } from 'formik'
import { useNotifications } from '../../../utils/notificationUtils'
import { Button, Dialog, DialogActions } from '@material-ui/core'
import React from 'react'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'

export const DeleteParkDialog: React.FC<DialogProps<Park>> = (props) => {
  const { showError, showSuccess } = useNotifications()
  const [deleteParkMutation, { data, loading, error }] = useDeleteParkMutation({
    refetchQueries: ['getParks'],
  })
  const authData = useAuthData()
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      props.handleClose()
      deleteParkMutation({
        variables: {
          deletePark: {
            uuid: props.selectedRow?.uuid || '',
          },
        },
      })
        .then(() => showSuccess('Park wurde erfolgreich gelöscht.'))
        .catch((err) => {
          console.error(err)
          showError('Park konnte nicht gelöscht werden.')
        })
    },
  })

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle title={'Park löschen'} subtitle={props.selectedRow?.name || ''} />
          <DialogActions>
            <Button onClick={props.handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Löschen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
