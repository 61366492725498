import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { useCancelAppointmentMutation, useResendAppointmentInvitationMutation } from '../../../generated/graphql-hooks'
import { deSerializeDate, formatDate, formatTime } from '../../../utils/dateUtils'
import { useNotifications } from '../../../utils/notificationUtils'
import { useAuthData } from '../../../utils/auth/AuthenticationProvider'
import { Appointment } from '../../../generated/graphql-types'
import { DialogProps } from './UpdateAppointmentDialog'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'

export const ResendAppointmentInvitationDialog: React.FC<DialogProps<Appointment>> = (props) => {
  const { showError, showSuccess } = useNotifications()

  const [resendAppointmentInvitation, { data, loading, error }] = useResendAppointmentInvitationMutation({
    refetchQueries: ['getAppointments', 'getAppointment'],
  })

  const onSubmit = () => {
    resendAppointmentInvitation({
      variables: {
        uuid: props.selectedRow?.uuid || '',
      },
    })
      .then(() => showSuccess('Termineinladung wurde erfolgreich neu versendet.'))
      .catch((err) => {
        console.error(err)
        showError('Fehler beim versenden der Termineinladung.')
      })
    props.handleClose()
  }

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <FHWDialogTitle
          title={'Termineinladung neu senden'}
          subtitle={[
            formatDate(deSerializeDate(props.selectedRow?.date || '')) || '',
            'um',
            formatTime(deSerializeDate(props.selectedRow?.date || '')),
            'Uhr.',
          ].join(' ')}
        />
        <DialogActions>
          <Button onClick={props.handleClose} variant="outlined" color="primary">
            Abbrechen
          </Button>
          <Button onClick={onSubmit} variant="contained" color="primary">
            Senden
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
