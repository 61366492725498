import { DialogProps } from './UpdateAppointmentDialog'
import * as yup from 'yup'
import { CenterManager } from '../../../generated/graphql-types'
import { useGetParksQuery, useUpdateCenterManagerMutation } from '../../../generated/graphql-hooks'
import { useAuthData } from '../../../utils/auth/AuthenticationProvider'
import { useFormik } from 'formik'
import { useNotifications } from '../../../utils/notificationUtils'
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core'
import { formikTextFieldArgs } from '../../../utils/formUtils'
import React from 'react'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { RemoteSelect, SelectOption } from '../../../components/RemoteSelect'

export const UpdateCenterManagerDialog: React.FC<DialogProps<CenterManager>> = (props) => {
  const { showError, showSuccess } = useNotifications()
  const validationSchema = yup.object({
    email: yup.string().required('Email Adresse ist erforderlich'),
    firstName: yup.string().required('Vorname ist erforderlich'),
    name: yup.string().required('Nachname ist erforderlich'),
    parkUuids: yup.array().min(1, 'Park ist erforderlich'),
  })

  const useParksSelectQuery = (search?: string) =>
    useGetParksQuery({
      variables: { pageQuery: { page: 0, pageSize: 10, search } },
    })

  const [updateCenterManagerMutation, { data, loading, error }] = useUpdateCenterManagerMutation({
    refetchQueries: ['getCenterManagers'],
  })

  const formik = useFormik({
    initialValues: {
      email: props.selectedRow?.email || '',
      firstName: props.selectedRow?.firstName || '',
      name: props.selectedRow?.name || '',
      parkUuids: props.selectedRow?.parks?.map((x) => x.uuid) || [],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.handleClose()
      updateCenterManagerMutation({
        variables: {
          updateCenterManager: {
            uuid: props.selectedRow?.uuid || '',
            email: values.email,
            firstName: values.firstName,
            name: values.name,
            parkUuids: values.parkUuids,
          },
        },
      })
        .then(() => showSuccess('Handelsvertreter wurde erfolgreich bearbeitet.'))
        .catch((err) => {
          console.error(err)
          showError('Handelsvertreter konnte nicht bearbeitet werden.')
        })
    },
  })

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle
            title={'Center Manager bearbeiten'}
            subtitle={[props.selectedRow?.firstName || '', props.selectedRow?.name || ''].join(' ')}
          />
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField label="Vorname" {...formikTextFieldArgs('firstName', formik)} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Nachname" {...formikTextFieldArgs('name', formik)} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Email" {...formikTextFieldArgs('email', formik)} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <RemoteSelect
                  label={'Parks'}
                  useQuery={useParksSelectQuery}
                  multiple={true}
                  queryResultToOptions={(queryResult) =>
                    queryResult?.getParks.parks.map<SelectOption>((x) => ({
                      label: x?.name ?? '',
                      value: x?.uuid ?? '',
                    })) ?? []
                  }
                  formik={formik}
                  name={'parkUuids'}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Bearbeiten
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
