import React, { useState } from 'react'
import { useGetSalesRepresentativesQuery } from '../../../generated/graphql-hooks'
import { Delete, Edit, Mail } from '@material-ui/icons'
import { GenericDataTable, usePageQueryState } from '../../../components/GenericDataTable'
import { SalesRepresentative } from '../../../generated/graphql-types'
import { UpdateSalesRepresentativeDialog } from '../dialogs/UpdateSalesRepresentativeDialog'
import { DeleteSalesRepresentativeDialog } from '../dialogs/DeleteSalesRepresentativeDialog'
import { useTableState } from './useTableState'
import { DEFAULT_POLLING_INTERVAL } from '../../../utils/apolloUtils'
import { ResendRegistrationEmailSalesRepresentativeDialog } from '../dialogs/ResendRegistrationEmailSalesRepresentativeDialog'

export const SalesRepresentativesTab: React.FC = () => {
  const [queryState, setQueryState] = usePageQueryState()
  const data = useGetSalesRepresentativesQuery({
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: { pageQuery: queryState },
  })
  const SalesRepresentatives = data.data?.getSalesRepresentatives.salesRepresentatives ?? []

  const {
    editDialogOpen,
    setEditDialogOpen,
    cancelDialogOpen,
    setCancelDialogOpen,
    selectedRow,
    setSelectedRow,
  } = useTableState<SalesRepresentative>()

  return (
    <>
      <GenericDataTable
        data={SalesRepresentatives}
        pageQuery={queryState}
        isLoading={data.loading}
        onPageQueryChange={setQueryState}
        pageInfo={data.data?.getSalesRepresentatives.pageInfo}
        columns={[
          { title: 'Email', field: 'email' },
          { title: 'Vorname', field: 'firstName' },
          { title: 'Nachname', field: 'name' },
          { title: 'Hersteller', field: 'manufacturer', render: (data) => data.manufacturer?.name ?? '' },
          { title: 'Park', field: 'park', render: (data) => data.park?.name ?? '' },
          { title: 'Vertreter ID', field: 'uuid', hidden: true },
        ]}
        title={'Handelsvertreter'}
        actions={[
          {
            icon: Mail,
            tooltip: 'Einladung erneut senden',
            onClick: (event, rowData) => {
              setCancelDialogOpen(true)
              setSelectedRow(rowData as SalesRepresentative)
            },
          },
          {
            icon: Delete,
            tooltip: 'Löschen',
            onClick: (event, rowData) => {
              setCancelDialogOpen(true)
              setSelectedRow(rowData as SalesRepresentative)
            },
          },
          {
            icon: Edit,
            tooltip: 'Bearbeiten',
            onClick: (event, rowData) => {
              setEditDialogOpen(true)
              setSelectedRow(rowData as SalesRepresentative)
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
      <UpdateSalesRepresentativeDialog
        selectedRow={selectedRow}
        open={editDialogOpen}
        handleClose={() => setEditDialogOpen(false)}
      />
      <DeleteSalesRepresentativeDialog
        selectedRow={selectedRow}
        open={cancelDialogOpen}
        handleClose={() => setCancelDialogOpen(false)}
      />
      <ResendRegistrationEmailSalesRepresentativeDialog
        selectedRow={selectedRow}
        open={cancelDialogOpen}
        handleClose={() => setCancelDialogOpen(false)}
      />
    </>
  )
}
