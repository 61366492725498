import React, { HTMLAttributes } from 'react'
import fhwLogo from './FHWLogo.png'
import { useTheme } from '@material-ui/core'

const Logo: React.FC<HTMLAttributes<HTMLImageElement>> = ({ children, ...props }) => {
  return <img alt="Fertighauswelt" src={fhwLogo} {...props} />
}

export const FHWLogo = React.memo(Logo)

const LogoSvg = (props: React.SVGProps<SVGSVGElement>) => {
  const theme = useTheme()
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 732.8 277.2"
      fill={theme.palette.secondary.main}
      {...props}
    >
      <path
        d="M75.5,159c0,3-2.1,6.6-6.4,10.7c-3.6-3.7-6.4-4.9-11.7-4.9c-5,0-11,0.9-17.9,2.6l-0.5,0.1l0,0.5
	c-1.1,11.1-0.1,32.5,0.6,46.7l0,0.8c0.1,2.8-0.8,5-2.7,6.4c-2.7,2-6.7,2.1-9.4,1.2c-2.1-1.7-0.4-30.1,0.2-39.5
	c0.2-3,0.3-13.2,0.3-13.2s-3.1,0.9-5.9,2.1c-2.3,1-17.9,9.4-17.9,9.4c-0.2,0.1-0.5,0.2-0.7,0.2c-0.5,0.2-0.8,0.3-1.1,0.3
	c-1.3,0-2.3-1.4-2.2-3.6c0.4-10.4,26.7-19.4,29-20.2l0.4-0.2l0-0.5c0.5-16.7,1.1-29,1.5-37.9l0.1-1c0,0-13.8,5.3-16.1,5.3
	c-0.9,0-1.6-0.4-2.1-1c-0.6-0.7-0.9-1.8-0.8-3.1c0.6-6.4,5.5-9.7,21.9-14.7c0.7-0.3,1.3-0.4,1.9-0.6c1-0.2,18.8-5.3,65.8-8.9
	c7.8-0.6,13.1,2.1,15.5,4.7c0.9,1.1,1.3,2.3,1.2,3.7c-0.3,3.3-3.6,6.9-10.2,11.1c-4.7-4.4-8.3-5.8-15.6-6.2
	c-10.5-0.6-41.7,3.5-51.1,6.5l-0.5,0.1l0,0.5c-0.7,8.5-1.4,38.6-1.4,38.6l0.9-0.2C48.9,153,55.9,152,63,152
	C71.1,152,75.5,154.5,75.5,159z M222.8,111.2c-3.9,4.7-6.6,10.3-6.6,13.5c0,3.6,2,7,4.2,7c1.9,0,4-1.7,6.5-5.3
	c4.6-6.2,6.6-10.6,6.6-14.1c0-3.2-1.9-5.6-4.4-5.6C227.1,106.7,224.6,109.1,222.8,111.2z M202,42.4c1.4,1,2,0.9,2,0.9h21.7
	c0,0,2.4,0.1,4.3-3.3c1.9-3.4,14.3-21,14.3-21s1-1,2.3,0.9c1.3,1.9,20.5,29.8,20.5,29.8s1.1,2,2.4,1.9c1.8-0.2,7.9-2.8,7.9-2.8
	L249.7,9.9l-25.1-1.1c0,0-2.3-0.6-3.9,2.3c-1.7,2.8-17.6,27.2-17.6,27.2S200.6,41.4,202,42.4z M165.3,88.4
	c34.8-15.5,76.2-23.7,119.7-23.7c49.1,0,96.6,10.8,133.5,30.4c0.7,0.4,1.5,0.6,2.2,0.6c1.7,0,3.4-0.9,4.2-2.5c1.2-2.3,0.3-5.2-2-6.4
	c-38.3-20.3-87.3-31.5-138-31.5c-44.8,0-87.6,8.5-123.6,24.5c-2.4,1.1-3.5,3.9-2.4,6.3C160,88.4,162.8,89.5,165.3,88.4z M281.1,10.2
	c0,0,1-1,2.3,0.9C284.7,13,304,40.8,304,40.8c0.4,0.7,1.2,2,2.5,1.8c1.8-0.2,7.7-2.7,7.7-2.7L286.6,1.1L261.5,0c0,0-2.3-0.6-3.9,2.3
	c-1.7,2.8-4.6,7.6-4.6,7.6s9.6,13.7,14.5,20.1C269.4,26.7,281.1,10.2,281.1,10.2z M325.9,44.6c1.9-3.4,14.3-21,14.3-21s1-1,2.3,0.9
	c1.3,1.9,20.5,29.8,20.5,29.8s1.1,2,2.9,1.9c1.8-0.1,7.4-2.8,7.4-2.8l-27.6-38.8l-25.1-1.1c0,0-2.3-0.6-3.9,2.3
	c-1.7,2.8-7.9,12.8-7.9,12.8l13.3,18.4C322.1,46.9,323.9,48,325.9,44.6z M580.2,79.2c-2.4,0-4.9,2.2-8.1,7.7
	c6.5,7.3,11.2,16.9,15.4,32.1c4.5,16.1,7.5,37.8,7.5,53.9c0,23-5.5,40.7-12.8,40.7c-4.7,0-9.3-4.5-15-14.2
	c-5.9-9.6-13.8-27.2-17.1-38.2c-1.4-4.7-2.6-6.1-5.3-6.1c-2.8,0-3.9,1.6-4.5,7.3c-2.8,25.2-9.6,46.1-14.6,46.1
	c-3.7,0-5.9-10-5.9-26.4c0-15,1.8-30.7,5.9-50c1.8-8.5,3-13.8,4.1-16.9c0.8-2.6,1.2-3.7,1.2-4.7c0-1.4-0.6-2.2-1.8-2.2
	c-3.5,0-9.4,5.7-11.4,10.8c-5.1,13.6-8.7,40.9-8.7,64.4c0,25.2,6.3,41.9,15.7,41.9c4.5,0,9.3-5.1,13.4-14.2
	c3.5-7.5,6.3-17.5,7.5-25.6c0.6-3.7,0.6-3.7,1-5.5c8.3,26.8,23,46.3,34.8,46.3c5.5,0,10.8-3.7,14.8-10.6c6.5-11,9.6-25.2,9.6-45.1
	c0-30.3-7.5-64.6-18.7-85.2C584.7,81.2,582.7,79.2,580.2,79.2z M203.7,151.6c0,2.2-1.4,4.9-5.1,8.3c-2.6-2.8-4.5-3.7-8.3-3.7
	c-5.7,0-13,1.6-20.7,4.3c0,0-1,15.4-1,20.9c0,11.8,0.8,19.5,2.4,24c1.4,3.9,3.7,5.9,5.9,5.9c2.6,0,5.3-2.4,8.9-8.5
	c2.4-4.3,5.1-9.6,6.1-13.2l0.8-2.4c0.4-1.6,1.2-2.2,2-2.2c1.8,0,3.3,3.3,3.3,6.9c0,4.5-2,10-6.1,16.7c-5.9,9.6-11.8,14.2-17.9,14.2
	c-10.6,0-16.7-13.6-16.7-38c0-7.5,0.4-12.8,1-19.5c-1.2,0.6-4.9,3.3-7.3,3.3c-1.2,0-5.1-2.4-8-8.2c-1.6-3.2-2.8-3.9-3.9-3.9
	c-1.4,0-2.8,1.4-4.5,4.7c-4.3,8.1-6.7,19.9-6.7,33.3c0,4.3,0,5.3,0.2,8.5l0.4,11.6c0.2,4.1-1.2,6.5-3.5,6.5c-1.8,0-3.2-2-4.7-5.9
	c-0.8-2.1-1.4-4.9-1.8-8.4c-0.4,0.7-0.9,1.4-1.3,2.1c-6.1,9.4-11.6,13.4-18.7,13.4c-13.2,0-21.9-14-21.9-35.2
	c0-24.8,11.6-48.2,23.8-48.2c6.9,0,11.6,6.7,11.6,16.5c0,15.9-8.3,30.9-24.2,43.7c2.8,8.5,6.5,13.2,11.4,13.2
	c5.7,0,10.8-6.3,16.7-19.9c0.7-1.6,1.7-3.9,1.9-5.3c1.9-12,2-40.6,2-40.6c0.6-4.7,1.6-6.7,3.3-6.7c3.7,0,5.7,4.3,5.7,11.4
	c0,1.6,0,2.8-0.2,5.3c1-1.6,5.2-12.9,11.2-12.8c1.4,0,3,0.8,3.9,2l4.3,6.1c1.6,2.6,3.8,6.1,6,6.2c1.8,0.1,3.4-2,5.8-3.1l0.2-1.6
	c1.6-13,3.5-20.9,6.5-27c2-4.5,6.1-8.1,8.7-8.1c1.2,0,1.8,0.8,1.8,2c0,0.8,0.3,1.3-0.8,3.7c-2.5,5.4-5.5,26.8-5.5,26.8
	c9.8-3,16.5-4.5,23.6-4.5C200.5,146.1,203.7,148.1,203.7,151.6z M86.6,189c10.2-8.3,16.7-21.5,16.7-33.1c0-3.7-1.2-6.3-3-6.3
	C93.9,149.5,86.6,170.7,86.6,189z M732.8,151c0,2.2-1.4,4.9-5.1,8.3c-2.6-2.8-4.5-3.7-8.3-3.7c-5.7,0-13,1.6-20.7,4.3l-0.2,2.2
	c-0.6,7.7-0.8,13.2-0.8,18.7c0,11.8,0.8,19.5,2.4,24c1.4,3.9,3.7,5.9,5.9,5.9c2.6,0,5.3-2.4,8.9-8.5c3.7-6.3,6.9-15.6,6.9-15.6
	c0.4-1.6,1.2-2.2,2-2.2c1.8,0,3.3,3.3,3.3,6.9c0,4.5-2,10-6.1,16.7c-5.9,9.6-11.8,14.2-17.9,14.2c-7.7,0-13-7.2-15.3-20.5
	c-1.8,4-4.2,8.1-6.7,12c-4.1,6.1-7.9,8.9-11.8,8.9c-4.5,0-7.7-3.9-10-11.4c-1.2-4.2-1.8-8.7-2.2-15c-0.8,2.9-2.5,6.4-5.2,10.5
	c-6.1,9.4-11.6,13.4-18.7,13.4c-13.2,0-22-14-22-35.2c0-24.8,11.6-48.2,23.8-48.2c6.9,0,11.6,6.7,11.6,16.5
	c0,15.9-8.3,30.9-24.2,43.7c2.8,8.5,6.5,13.2,11.4,13.2c5.7,0,10.8-6.3,16.7-19.9c1-2.4,1.8-3.3,2.8-3.3c1.4,0,2.7,1.2,3.5,2.8
	c0-1.9,0-3.8,0-6c0-28.9,3.3-62.4,8.7-86.2c2-9.8,3.5-12.6,5.9-12.6c2.2,0,4.9,3,7.9,8.9c-3,8.9-5.3,17.3-7.3,29.1
	c-3.3,18.1-5.7,44.5-5.7,62.8c0,15.6,1.6,22,5.5,22c0.8,0,1.8-0.6,2.6-1.4c2-2.6,10.6-14.9,11.9-22.2c1.3-7.4,0.4-12.8,1-19.5
	c-1.2,0.6-2.2,1.2-2.8,1.4l-3.1,1.8c-0.6,0.2-1,0.4-1.4,0.4c-1.2,0-2-1.2-2-3c0-4.5,2.2-6.9,10.8-11l0.2-1.6c1.6-13,3.5-20.9,6.5-27
	c2-4.5,6.1-8.1,8.7-8.1c1.2,0,1.8,0.8,1.8,2c0,0.8,0,1.2-0.8,3.7c-1.6,5.3-3.7,14.6-5.3,25l-0.2,1.8c9.8-3,16.5-4.5,23.6-4.5
	C729.5,145.5,732.8,147.6,732.8,151z M621,186.7c10.2-8.3,16.7-21.5,16.7-33.1c0-3.7-1.2-6.3-3-6.3C628.4,147.3,621,168.4,621,186.7
	z M493.9,172.3c-1.4-0.9-12-4.4-15.8-5.1c-5.1-1-7.5-2.8-7.5-5.5c0-4.5,5.1-10.6,13-15.4c4.5-2.8,5.5-4.1,5.5-6.3
	c0-2.4-2.2-4.5-5.1-4.5c-2.6,0-6.1,1.4-10,4.3c-8.7,6.7-14.6,16.1-14.6,23.8c0,7.5,4.5,11.8,14,13.4c5.9,1,7.1,1.2,7.9,1.4
	c2,0.4,4.3,1.2,5.7,1.8c4.3,1.8,6.9,6.1,6.9,10.8c0,10.4-9.4,19.7-19.5,19.7c-2.2,0-4.1-0.2-8.5-2c-1.2-0.4-2.2-0.6-3-0.6
	c-2.2,0-4.5,2.6-4.5,5.1c0,5.3,5.9,9.1,13.6,9.1c17.1,0,32.7-15.9,32.7-33.1C504.8,182.3,499.8,176,493.9,172.3z M458.4,198.6
	c0,3.5-1.4,7.7-4.7,13.2c-4.3,7.3-7.9,10.8-11.4,10.8c-2.2,0-4.7-1.6-5.9-4.1c-2.8-5.3-4.5-15-4.5-25.6c0-2.6,0-4.7,0.4-9.1
	l-1.4,3.5c-5.1,12.6-6.5,15.6-9.6,21.1c-5.5,9.6-9.3,13.6-13,13.6c-4,0-7-4.7-8.7-12.8c-0.3,0.6-0.6,1.3-1,2
	c-4.1,8.1-7.9,11.8-12.4,11.8c-6.3,0-10-6.7-12.4-23.2l-0.4-2.8c-5.9,13.2-14.2,20.9-22,20.9c-5.8,0-10.1-3.8-12.1-10.2
	c-2,4.5-5,9.3-8.1,12.6c-1.6,1.6-3.7,2.6-5.7,2.6c-5.3,0-8.7-5.5-10.2-16.7c-0.8-6.5-1.4-20.3-1.4-29.9v-1.2v-1.8v-2.6
	c0-5.5-0.4-7.1-1.8-7.1c-1.8,0-4.9,5.9-8.1,15.2c-3.5,10.6-8.4,24.2-9.4,33.1c-0.4,5.5-3.1,10.7-5.1,10.7c-2.2,0-3.7-1.6-4.5-5.1
	c-1.2-5.1,1.9-65.6,2.7-75.8c0.6-8.9,2.6-27.2,3.5-32.9l1.8-10.8c1.4-9.1,2.8-12.2,6.1-12.2c2.4,0,4.3,2,7.3,8.7
	c-2,6.9-3.5,13.4-4.9,22.2c-2.6,16.1-5.1,57.5-5.1,59.5c6.3-18.1,13.2-27.8,20.1-27.8c2.6,0,4.9,1.4,6.1,3.9c0.8,1.8,1,2.8,1,16.7
	c0.2,9.3,0.6,21.1,1,26.6c0.8,10,1.4,12.6,3.3,12.6s5.6-2.7,8.9-13.2c6.8-21.2,16.2-54.8,37.1-54.5c8.7,0.1,16.2,6.7,15.4,15.9
	c-0.6,7.9-7.3,20.4-4.9,42.7c0.7,6.7,2.2,10,4.5,10c1.8,0,4.6-3,7.7-9c1.2-2.4,1.4-4.6,1.4-8.3c0-16.3,1.6-31.3,5.3-44.1
	c1.6-6.7,3-8.9,5.1-8.9c2.8,0,6.1,3.3,6.1,6.3c0,0.8-0.2,1.6-1,4.9c-1,3.3-2.6,11.4-3.5,15.6c-1.6,10.6-2.2,18.7-2.2,30.3
	c0,8.1,0.8,11.6,2.6,11.6s4.3-2.8,7.5-9.1c7.1-13.4,11.6-27.8,13.8-44.1c2.8-2.2,5.1-3.2,7.1-3.2c1.6,0,2.6,1.2,2.6,3
	c0,0-1.4,24-1.4,28c0,9.3,0.8,19.7,1.8,22.8c0.6,2.2,1.6,3.5,2.8,3.5c1.8,0,4.5-3.7,7.5-10.4l1.8-4.1c0.4-1,1-1.4,1.4-1.4
	C457.2,192.5,458.4,195.3,458.4,198.6z M379.1,154.7c0-3.4-2.8-4.9-5.9-4.9c-5.3,0-11.8,6.7-17.3,17.9c-4.3,8.5-7.3,19.9-7.3,26.6
	c0,7.1,1.8,10.6,5.9,10.6C366,204.9,379.1,164.6,379.1,154.7z M278.5,134.5c-1.6,6.7-2,97.7-2,101.8c-0.5,25.6-13,40.9-34.6,40.9
	c-21.3,0-41.9-14.8-41.9-30.3c0-5.5,3.9-10.6,8.1-10.6c1.8,0,3.3,1.4,3.3,3c0,0.6-0.2,1.6-0.4,2.6v2.8c0,12.4,13.8,22.8,29.9,22.8
	c17.7,0,25.6-10.8,25.6-34.6c0-0.6-0.6-19.7-0.6-19.7l-0.8-16.7v-4.5c-0.4,0.6-0.6,1-0.6,1.2l-1.4,2.2c-5.5,9.4-12,14.6-18.7,14.6
	c-5,0-9-2.7-11.5-7.3c-1.5,4.6-4.8,10.5-8.6,15.4c-2.8,3.5-4.9,5.1-7.3,5.1c-6.7,0-10.6-12.4-10.6-34.3c0-15.7,1.4-29.1,4.5-42.5
	c0.6-3,1.6-4.3,3.7-4.3c2.8,0,5.7,3.5,5.7,6.9c0,1,0,2-1,8.1c-1.8,10.6-2.8,24.8-2.8,36.2c0,10,1,15.7,3,15.7c0.8,0,1.6-0.8,2.6-2.2
	c0.4-0.6,8.2-14.7,8.1-16.4c-0.4-22.6,17.9-45.9,35.4-45.9c0.4,0,0.8,0,1.4,0.2c0-0.8,0.2-1.4,0.2-1.8c0.2-5.3,0.4-5.7,1.4-7.3
	c1.4-2.4,4.3-4.1,7.1-4.1C277.5,131.7,278.9,132.7,278.5,134.5z M266.1,154.8c-0.4-0.2-0.6-0.4-1.2-0.4c-12,0-24.2,17.7-24.2,34.6
	c0,5.7,2.2,9.3,5.7,9.3c3.2,0,6.9-2.6,10-7.1c4.7-6.9,7.7-17.9,9.6-34.6L266.1,154.8z"
      />
    </svg>
  )
}

export const FHWLogoSvg = React.memo(LogoSvg)
