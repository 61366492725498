import React from 'react'
import { Grid } from '@material-ui/core'
import { FHWLogoSvg } from '../theme/icons/FHWLogo'
import { styled } from '../theme'

interface FHWScreenProps {
  children: React.ReactNode
}

const StyledFHWLogo = styled(FHWLogoSvg)`
  height: 150px;
  width: auto;
  margin-bottom: 50px;
  margin-top: 10%;
`
export const FHWScreen: React.FC<FHWScreenProps> = (props) => {
  return (
    <Grid
      container
      style={{ height: '100vh', width: '100vw' }}
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item>
        <StyledFHWLogo />
      </Grid>
      <Grid item>{props.children}</Grid>
    </Grid>
  )
}
