import { AppointmentStatus } from '../generated/graphql-types'
import { Chip, ChipProps } from '@material-ui/core'
import { statusColorMap } from '../utils/entities/appointmentUtils'
import { labels } from '../utils/labelUtils'
import React from 'react'

interface AppointmentStatusBadgeProps extends ChipProps {
  status: AppointmentStatus | null | undefined
}

export const AppointmentStatusBadge: React.FC<AppointmentStatusBadgeProps> = ({ status, ...props }) => {
  if (!status) {
    return <Chip label={'?'} {...props} />
  }
  return (
    <Chip
      style={{ backgroundColor: statusColorMap[status] }}
      label={labels.enums.AppointmentStatus[status]}
      {...props}
    />
  )
}
