import React from 'react'
import { useReportStyles } from './index'

export type ManufacturerReportResult = {
  name: 'MANUFACTURER'
  report: {
    label: string
    clusterDate: string
    data: {
      parkName: string
      manufacturers: {
        [name: string]: {
          appointments: number
        }
      }
    }[]
  }[]
}

export const ManufacturerReport: React.FC<{ reportResult: ManufacturerReportResult }> = ({
  reportResult: { report },
}) => {
  const classes = useReportStyles()
  return (
    <>
      <h2>Spezialreport (1)</h2>
      {report.map((cluster) => (
        <div key={cluster.clusterDate}>
          <h3>{cluster.label}</h3>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>Park</th>
                <th>Hersteller</th>
                <th>Anzahl Termine</th>
              </tr>
            </thead>
            <tbody>
              {cluster.data.map((park) =>
                Object.keys(park.manufacturers).map((manufacturer) => (
                  <tr key={`${park}_${manufacturer}`}>
                    <th>{park.parkName}</th>
                    <td>{manufacturer}</td>
                    <td>{park.manufacturers[manufacturer].appointments}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ))}
    </>
  )
}
