import React, { useState } from 'react'
import { AppBar, Box, IconButton, makeStyles, Menu, MenuItem, Theme, Toolbar, withTheme } from '@material-ui/core'
import { styled } from '../theme'
import { AppBarProps } from '@material-ui/core/AppBar/AppBar'
import { FHWLogoSvg } from '../theme/icons/FHWLogo'
import { AccountCircle } from '@material-ui/icons'
import { useAuthData } from '../utils/auth/AuthenticationProvider'

const HeaderBar = styled((props: AppBarProps) => <AppBar position={'static'} {...props} />)`
  height: 100px;
  color: ${(props) => props.theme.palette.text.primary};
  background: ${(props) => props.theme.palette.background.default};
  box-shadow: none;
`

const HeaderLogo = styled(FHWLogoSvg)`
  color: ${(props) => props.theme.palette.primary.main};
  height: 50px;
  width: auto;
`

const PageWrapper = styled(Box)`
  padding-left: 50px;
  padding-right: 50px;
`

export const BasePage: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const authData = useAuthData()

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={anchorEl !== null}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem onClick={authData.logout}>Logout</MenuItem>
    </Menu>
  )

  return (
    <PageWrapper>
      <HeaderBar position={'static'}>
        <Toolbar style={{ height: '100%', display: 'flex', justifyContent: 'space-between' }} disableGutters={true}>
          <HeaderLogo />
          <IconButton edge="end" onClick={(event) => setAnchorEl(!anchorEl ? event.currentTarget : null)}>
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </HeaderBar>
      <Box>{children}</Box>
      {renderProfileMenu}
    </PageWrapper>
  )
}
