import React, { useEffect } from 'react'
import { Button, Chip, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import { useNotifications } from '../../../../utils/notificationUtils'
import { TicketType, useGetTicketQuery, useCheckInTicketMutation } from '../../../../generated/graphql-hooks'
import { LoadingBackdrop } from '../../../../components/LoadingBackdrop'
import { labels } from '../../../../utils/labelUtils'
import CheckRounded from '@material-ui/icons/CheckRounded'
import BlockRounded from '@material-ui/icons/BlockRounded'
import { styled } from '../../../../theme'
import { deSerializeDate, formatDate } from '../../../../utils/dateUtils'
import { Ticket } from '../../../../generated/graphql-types'
import { useHistory } from 'react-router-dom'
import { routes, useNestedRoutePath } from '../../../../utils/routeUtils'
import PrintIcon from '@material-ui/icons/PrintRounded'
import { printTicket } from '../../../../utils/entities/ticketUtils'
import { useAuthData } from '../../../../utils/auth/AuthenticationProvider'
import { LimitedWidthGridContainer } from '../../../../components/LimitedWidthGridContainer'

const ValidIcon = styled(CheckRounded)`
  color: ${(props) => props.theme.palette.success.main};
`

const NotValidIcon = styled(BlockRounded)`
  color: ${(props) => props.theme.palette.error.main};
`

export const TicketDetail: React.FC<{ uuid: string }> = ({ uuid }) => {
  const { splashError, splashSuccess, showError } = useNotifications()
  const history = useHistory()
  const { isInsideTicketScanPath } = useNestedRoutePath()

  const [checkInTicket, { loading: checkInLoading }] = useCheckInTicketMutation({
    refetchQueries: ['getTicket', 'getTickets'],
  })
  const { data, loading } = useGetTicketQuery({
    variables: {
      uuid,
    },
  })

  const authData = useAuthData()

  useEffect(() => {
    if (!loading && !data?.getTicket) {
      splashError('Ticket konnte nicht gefunden werden.').then(() =>
        history.push(routes.authenticated.Tickets.Scan.TicketScan.pathDef)
      )
    }
  }, [loading, data])

  const handleCheckIn = () => {
    checkInTicket({
      variables: {
        uuid: data?.getTicket?.uuid || '',
      },
    }).then((result) => {
      if (result.data?.checkinTicket.success) {
        return splashSuccess('Der Einlass des Besuchers wurde erfolgreich registriert.', 'Einlass erfolgreich').then(
          () => {
            history.push(routes.authenticated.Tickets.Table.List.pathDef)
          }
        )
      }
      return showError('Fehler beim Einlass, prüfen Sie das Ticket und versuchen Sie es erneut.')
    })
  }

  const handleRegisterVisitor = () => {
    const ticketUuid = data?.getTicket?.uuid
    if (!ticketUuid) {
      return
    }
    if (isInsideTicketScanPath) {
      history.push(routes.authenticated.Tickets.Scan.RegisterVisitor.get(ticketUuid))
    } else {
      history.push(routes.authenticated.Tickets.Table.RegisterVisitor.get(ticketUuid))
    }
  }

  const handlePrint = () => {
    if (!data?.getTicket?.uuid) {
      return
    }

    printTicket(data?.getTicket?.uuid, authData.getToken()).catch((error) => {
      console.error(error)
      showError('Fehler beim Drucken des Tickets')
    })
  }

  const renderVisitorInfo = (ticket: Ticket): JSX.Element => {
    return (
      <Grid container spacing={3}>
        {ticket.hasIncompleteVisitorData && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={true}
                variant={'outlined'}
                value={''}
                label={'Status'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Chip style={{ backgroundColor: 'red' }} label={'Fehlende Besucherdaten'} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={handleRegisterVisitor}
                fullWidth
                style={{ height: '100%' }}
              >
                Besucher registrieren
              </Button>
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={true}
            variant={'outlined'}
            value={formatDate(deSerializeDate(ticket.expectedEntryDate || ''))}
            label={'Datum'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={true}
            variant={'outlined'}
            value={formatDate(new Date())}
            label={'Datum heute'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={true}
            variant={'outlined'}
            value={ticket.visitor?.firstName || ''}
            label={'Vorname'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={true}
            variant={'outlined'}
            value={ticket.visitor?.name || ''}
            label={'Nachname'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={true}
            variant={'outlined'}
            value={ticket.visitor?.email || ''}
            label={'Email'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={true}
            variant={'outlined'}
            value={ticket.visitor?.numAdults || 1}
            label={'Anzahl Erwachsene'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={true}
            variant={'outlined'}
            value={ticket.visitor?.numChildren || 0}
            label={'Anzahl Kinder'}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {!loading && data?.getTicket && (
        <>
          <LimitedWidthGridContainer>
            <Grid item xs={12}>
              <Typography variant={'h6'} component={'h2'}>
                Ticket {data.getTicket.uuid}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={true}
                variant={'outlined'}
                value={''}
                label={'Art'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Chip label={labels.enums.TicketType[data.getTicket.type]} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {data.getTicket.type === TicketType.Appointment && (
              <Grid item xs={12}>
                {renderVisitorInfo(data.getTicket as Ticket)}
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={true}
                variant={'outlined'}
                multiline={true}
                rows={5}
                value={data.getTicket.comment || ''}
                label={'Kommentar'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={true}
                variant={'outlined'}
                value={data.getTicket.remainingEntries || '0'}
                label={'Verbleibende Eintritte'}
              />
            </Grid>
            <Grid item xs={6}>
              {data.getTicket.isValid && (
                <Button
                  style={{ height: '100%' }}
                  startIcon={<ValidIcon />}
                  variant={'contained'}
                  fullWidth
                  disabled={checkInLoading}
                  onClick={handleCheckIn}
                >
                  Check-In
                </Button>
              )}
              {!data.getTicket.isValid && (
                <Button style={{ height: '100%' }} startIcon={<NotValidIcon />} variant={'outlined'} fullWidth disabled>
                  Ticket nicht gültig
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<PrintIcon />}
                variant={'outlined'}
                fullWidth
                disabled={checkInLoading}
                onClick={handlePrint}
              >
                Ticket drucken
              </Button>
            </Grid>
          </LimitedWidthGridContainer>
        </>
      )}
      <LoadingBackdrop loading={loading} />
    </>
  )
}
