import React from 'react'
import { useGetManufacturersQuery } from '../../../generated/graphql-hooks'
import { useState } from 'react'
import { GenericDataTable, usePageQueryState } from '../../../components/GenericDataTable'
import { Delete, Edit } from '@material-ui/icons'
import { Manufacturer } from '../../../generated/graphql-types'
import { UpdateManufacturerDialog } from '../dialogs/UpdateManufacturerDialog'
import { DeleteManufacturerDialog } from '../dialogs/DeleteManufacturerDialog'
import { useTableState } from './useTableState'
import { DEFAULT_POLLING_INTERVAL } from '../../../utils/apolloUtils'

export const ManufacturersTab: React.FC = () => {
  const [queryState, setQueryState] = usePageQueryState()
  const data = useGetManufacturersQuery({
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: { pageQuery: queryState },
  })
  const Manufacturers = data.data?.getManufacturers.manufacturers ?? []

  const {
    editDialogOpen,
    setEditDialogOpen,
    cancelDialogOpen,
    setCancelDialogOpen,
    selectedRow,
    setSelectedRow,
  } = useTableState<Manufacturer>()

  return (
    <>
      <GenericDataTable
        data={Manufacturers}
        pageInfo={data.data?.getManufacturers.pageInfo}
        pageQuery={queryState}
        isLoading={data.loading}
        onPageQueryChange={setQueryState}
        columns={[
          { title: 'Hersteller ID', field: 'uuid', hidden: true },
          { title: 'Name', field: 'name' },
          {
            title: 'Straße',
            render: (rowData) => `${rowData.address?.street || ''} ${rowData.address?.streetNumber || ''}`,
          },
          { title: 'Stadt', field: 'address.city' },
          { title: 'Postleitzahl', field: 'address.postalCode' },
        ]}
        title={'Hersteller'}
        actions={[
          {
            icon: Delete,
            tooltip: 'Löschen',
            onClick: (event, rowData) => {
              setCancelDialogOpen(true)
              setSelectedRow(rowData as Manufacturer)
            },
          },
          {
            icon: Edit,
            tooltip: 'Bearbeiten',
            onClick: (event, rowData) => {
              setEditDialogOpen(true)
              setSelectedRow(rowData as Manufacturer)
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
      <UpdateManufacturerDialog
        selectedRow={selectedRow}
        open={editDialogOpen}
        handleClose={() => setEditDialogOpen(false)}
      />
      <DeleteManufacturerDialog
        selectedRow={selectedRow}
        open={cancelDialogOpen}
        handleClose={() => setCancelDialogOpen(false)}
      />
    </>
  )
}
