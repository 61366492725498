import React, { useContext } from 'react'
import Keycloak from 'keycloak-js'
import { getEnvironment } from '../envUtils'
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'
import { AuthLoadingPage } from './AuthLoadingPage'
import { get } from 'lodash'
import { AuthInvalidPage } from './AuthInvalidPage'

export enum UserRole {
  CenterManager = 'CENTER_MANAGER',
  SalesRepresentative = 'SALES_REPRESENTATIVE',
  BdfAdmin = 'BDF_ADMIN',
}

export interface UserData {
  uuid: string
  firstName: string
  name: string
  role: UserRole
}

interface AuthData {
  getUser: () => UserData
  logout: () => void
  getToken: () => string
}

const AuthenticationContext = React.createContext<AuthData | null>(null)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const keycloak = new Keycloak({
  clientId: getEnvironment().OidcClientId,
  realm: getEnvironment().OidcRealm,
  url: getEnvironment().OidcEndpoint,
}) as Keycloak.KeycloakInstance

interface VendorAuthenticationProviderProps {
  children: React.ReactNode
}
const VendorAuthenticationProvider: React.FC<VendorAuthenticationProviderProps> = ({ children }) => {
  const LoadingComponent = <AuthLoadingPage />

  return (
    <ReactKeycloakProvider
      autoRefreshToken={true}
      LoadingComponent={LoadingComponent}
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required' }}
    >
      {children}
    </ReactKeycloakProvider>
  )
}

const InternalAuthenticationProvider: React.FC = ({ children }) => {
  const { keycloak } = useKeycloak()

  const getUser = (): UserData => {
    const roles = get(keycloak.tokenParsed, `realm_access.roles`, []).filter((role: string) =>
      Object.values(UserRole).includes(role as UserRole)
    ) as UserRole[]
    if (roles.length > 1) {
      console.warn('User with multiple roles (%s): %s', roles.join(','), keycloak.tokenParsed?.sub)
    }
    const userRole = roles[0]
    return {
      uuid: get(keycloak.tokenParsed, 'uuid'),
      firstName: '',
      name: '',
      role: userRole,
    }
  }

  const user = getUser()
  if (!user.role || !user.uuid) {
    console.warn('User without role or uuid', user)
    return <AuthInvalidPage />
  }

  return (
    <AuthenticationContext.Provider
      value={{
        getUser,
        logout: () => keycloak.logout(),
        getToken: () => keycloak.token || '',
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export const AuthenticationProvider: React.FC = ({ children }) => (
  <VendorAuthenticationProvider>
    <InternalAuthenticationProvider>{children}</InternalAuthenticationProvider>
  </VendorAuthenticationProvider>
)

export const useAuthData = (): AuthData => {
  const data = useContext(AuthenticationContext)

  if (data === null) {
    throw new Error('useAuthData must be used inside AuthenticationContext')
  }

  return data
}
