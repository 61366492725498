import React, { forwardRef, ReactElement, useCallback, useEffect, useState } from 'react'
import MaterialTable, { Column, MaterialTableProps, MTableToolbar } from 'material-table'
import { makeResponseDataMutable } from '../utils/apolloUtils'
import { styled } from '../theme'
import { debounce } from 'lodash'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { PageInfo, PageQuery } from '../generated/graphql-types'

const StyledMTableContainer = styled.div`
  background: ${(props) => props.theme.palette.background.default};
`

const MaterialTableWrapper = styled.div`
  .MuiToolbar-root.MuiToolbar-gutters {
    padding-left: 0;
    padding-right: 0;
  }
  .MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 1em;
  }
  .MuiTableBody-root {
    .MuiTableCell-root {
      background: #f4f4f4;
      border: 0px;
    }
  }
`

const tableIcons = {
  Add: forwardRef((props: any, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props: any, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props: any, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props: any, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props: any, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props: any, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props: any, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props: any, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props: any, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props: any, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props: any, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props: any, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props: any, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props: any, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props: any, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props: any, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props: any, ref) => <ViewColumn {...props} ref={ref} />),
}

const labels = {
  body: {
    emptyDataSourceMessage: 'Keine Einträge',
    addTooltip: 'Hinzufügen',
    deleteTooltip: 'Löschen',
    editTooltip: 'Bearbeiten',
    filterRow: {
      filterTooltip: 'Filter',
    },
    editRow: {
      deleteText: 'Diese Zeile wirklich löschen?',
      cancelTooltip: 'Abbrechen',
      saveTooltip: 'Speichern',
    },
  },
  grouping: {
    placeholder: 'Spalten ziehen ...',
    groupedBy: 'Gruppiert nach:',
  },
  header: {
    actions: '',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} von {count}',
    labelRowsSelect: 'Zeilen',
    labelRowsPerPage: 'Zeilen pro Seite:',
    firstAriaLabel: 'Erste Seite',
    firstTooltip: 'Erste Seite',
    previousAriaLabel: 'Vorherige Seite',
    previousTooltip: 'Vorherige Seite',
    nextAriaLabel: 'Nächste Seite',
    nextTooltip: 'Nächste Seite',
    lastAriaLabel: 'Letzte Seite',
    lastTooltip: 'Letzte Seite',
  },
  toolbar: {
    addRemoveColumns: 'Spalten hinzufügen oder löschen',
    nRowsSelected: '{0} Zeile(n) ausgewählt',
    showColumnsTitle: 'Zeige Spalten',
    showColumnsAriaLabel: 'Zeige Spalten',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportName: 'Export als CSV',
    searchTooltip: 'Suche',
    searchPlaceholder: 'Suche',
  },
}

interface GenericDataTableProps<T extends Record<string, unknown>>
  extends Pick<MaterialTableProps<T>, 'options' | 'actions'> {
  data: T[]
  pageInfo?: PageInfo | null
  pageQuery: PageQuery
  onPageQueryChange: (pageQuery: PageQuery) => void
  columns: Column<T>[]
  isLoading: boolean
  title?: string
}

export const usePageQueryState = (initialState?: PageQuery) => {
  return useState<PageQuery>(initialState || { pageSize: 5, page: 0, search: '' })
}

export const GenericDataTable = <T extends Record<string, unknown>>(props: GenericDataTableProps<T>): ReactElement => {
  const rows = makeResponseDataMutable(props.data)
  const columnsWithoutClientSideSearch = props.columns.map((col) => {
    col.customFilterAndSearch = () => true
    return col
  })

  const debouncedOnSearchChange = useCallback(
    debounce((searchText: string) => {
      props.onPageQueryChange({ ...props.pageQuery, search: searchText })
    }, 1000),
    [props.pageQuery, props.onPageQueryChange]
  )

  const onSearchChange = (searchText: string) => {
    if (!searchText) {
      return props.onPageQueryChange({ ...props.pageQuery, search: searchText })
    }
    debouncedOnSearchChange(searchText)
  }

  return (
    <MaterialTableWrapper>
      <MaterialTable
        components={{
          Container: (props) => <StyledMTableContainer {...props} />,
          Toolbar: (props) => <MTableToolbar disableGutters={true} {...props} />,
        }}
        page={props.pageQuery.page}
        onChangePage={(page, pageSize) => props.onPageQueryChange({ ...props.pageQuery, pageSize, page })}
        onSearchChange={(searchText) => onSearchChange(searchText)}
        totalCount={props.pageInfo?.totalCount ?? 0}
        isLoading={props.isLoading}
        columns={columnsWithoutClientSideSearch}
        localization={labels}
        data={rows}
        title={props.title}
        actions={props.actions}
        options={props.options}
        icons={tableIcons as any}
      />
    </MaterialTableWrapper>
  )
}
