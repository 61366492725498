import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'

export const LoadingBackdrop: React.FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <Backdrop open={loading} style={{ zIndex: 9999 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
