import React from 'react'
import { useReportStyles } from './index'
import { CSVLink } from "react-csv"

export type BillingReportResult = {
  name: 'BILLING'
  csv: [
    {
      email: string
      firstName: string
      name: string
      people: number
      salesRepresentative: string
      manufacturer: string
      date?: string
      parkName?: string
    }
  ]
  report: {
    label: string
    clusterDate: string
    data: {
      parkName: string
      visits: [
        {
          email: string
          firstName: string
          name: string
          people: number
          salesRepresentative: string
          manufacturer: string
          date?: string
          parkName?: string
        }
      ]
    }[]
  }[]
}

export const BillingReport: React.FC<{ reportResult: BillingReportResult }> = ({ reportResult: { report, csv} }) => {
  const classes = useReportStyles()
  return (
    <>
      <h2>Abrechnungsreport</h2>
      <CSVLink data={csv} separator={";"} filename={"billing.csv"} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary">
        CSV Download
      </CSVLink>
      {report.map((cluster) => (
        <div key={cluster.clusterDate}>
          <h3>{cluster.label}</h3>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>Parks</th>
                <th>Hersteller</th>
                <th>Ansprechpartner</th>
                <th>Name</th>
                <th>Vorname</th>
                <th>Email</th>
                <th>Anzahl Personen</th>
              </tr>
            </thead>
            <tbody>
              {cluster.data.map((park, i) =>
                park.visits.map((visit, j) => (
                  <tr key={`${i}_${j}`}>
                    <th>{park.parkName}</th>
                    <td>{visit.manufacturer}</td>
                    <td>{visit.salesRepresentative}</td>
                    <td>{visit.name}</td>
                    <td>{visit.firstName}</td>
                    <td>{visit.email}</td>
                    <td>{visit.people}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ))}
    </>
  )
}
