import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { useResendRegistrationEmailSalesRepresentativeMutation } from '../../../generated/graphql-hooks'
import { useNotifications } from '../../../utils/notificationUtils'
import { useAuthData } from '../../../utils/auth/AuthenticationProvider'
import { SalesRepresentative } from '../../../generated/graphql-types'
import { DialogProps } from './UpdateAppointmentDialog'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'

export const ResendRegistrationEmailSalesRepresentativeDialog: React.FC<DialogProps<SalesRepresentative>> = (props) => {
  const { showError, showSuccess } = useNotifications()
  const [
    resendRegistrationEmailSalesRepresentativeMutation,
    { data, loading, error },
  ] = useResendRegistrationEmailSalesRepresentativeMutation({
    refetchQueries: ['getSalesRepresentatives'],
  })
  const authData = useAuthData()
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      props.handleClose()
      resendRegistrationEmailSalesRepresentativeMutation({
        variables: {
          resendRegistrationEmailSalesRepresentative: {
            uuid: props.selectedRow?.uuid || '',
          },
        },
      })
        .then(() => showSuccess('Die Registrierungsemail wurde erfolgreich gesendet.'))
        .catch((err) => {
          console.error(err)
          showError('Die Registrierungsemail konnte nicht versendet werden.')
        })
    },
  })

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle
            title={'Registrierungsemail erneut senden'}
            subtitle={[props.selectedRow?.firstName || '', props.selectedRow?.name || ''].join(' ')}
          />
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant={'caption'}>Email:</Typography>
                <Typography>{props.selectedRow?.email || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'caption'}>Hersteller:</Typography>
                <Typography>{props.selectedRow?.manufacturer?.name || ''}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Registrierungsemail erneut senden
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
