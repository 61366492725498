import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useNotifications } from '../../../utils/notificationUtils'
import { useAuthData } from '../../../utils/auth/AuthenticationProvider'
import { formikTextFieldArgs } from '../../../utils/formUtils'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'
import { useCreateTicketMutation } from '../../../generated/graphql-hooks'
import { TicketType } from '../../../generated/graphql-types'
import { labels } from '../../../utils/labelUtils'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../utils/routeUtils'

export const CreateTicketButton: React.FC = () => {
  const { showError, showSuccess } = useNotifications()
  const [open, setOpen] = React.useState(false)
  const history = useHistory()
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const validationSchema = yup.object({
    comment: yup.string().max(255, 'Bitte nicht mehr als 255 Zeichen eingeben'),
    type: yup.string().required('Bitte Ticketart auswählen'),
  })

  const [createParkMutation, { data, loading, error }] = useCreateTicketMutation({
    refetchQueries: ['getTickets'],
  })
  const authData = useAuthData()
  const formik = useFormik({
    initialValues: {
      comment: '',
      type: TicketType.Cash,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClose()
      createParkMutation({
        variables: {
          createTicket: {
            comment: values.comment,
            type: values.type,
          },
        },
      })
        .then((createdTicket) => {
          showSuccess('Ticket wurde erfolgreich erstellt.')
          formik.resetForm()
          history.push(routes.authenticated.Tickets.Scan.Detail.get(createdTicket.data?.createTicket.uuid || ''))
        })
        .catch((err) => {
          console.error(err)
          showError('Ticket konnte nicht erstellt werden.')
        })
    },
  })

  return (
    <>
      <Button variant={'contained'} color={'primary'} onClick={handleClickOpen}>
        Ticket anlegen
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle title={'Neues Ticket anlegen'} />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="ticket-type-select-label">Art</InputLabel>
                  <Select labelId="ticket-type-select-label" label="Art" {...formikTextFieldArgs('type', formik)}>
                    <MenuItem value={TicketType.Cash}>{labels.enums.TicketType[TicketType.Cash]}</MenuItem>
                    <MenuItem value={TicketType.GiftCard}>{labels.enums.TicketType[TicketType.GiftCard]}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant={'outlined'}
                  rows={5}
                  multiline={true}
                  label="Kommentar"
                  {...formikTextFieldArgs('comment', formik)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Anlegen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
