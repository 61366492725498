import React from 'react'
import { AuthenticationProvider, useAuthData } from '../../utils/auth/AuthenticationProvider'
import { Route, Switch } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { getApolloLink } from '../../utils/apolloUtils'
import { BasePage } from '../../components/BasePage'
import { routes } from '../../utils/routeUtils'

const InnerAuthenticatedApp: React.FC = () => {
  const { getToken } = useAuthData()

  return (
    <ApolloProvider
      client={
        new ApolloClient({
          cache: new InMemoryCache({}),
          link: getApolloLink(getToken),
        })
      }
    >
      <BasePage>
        <Switch>
          <Route path={routes.authenticated.Home.pathDef}>
            <HomePage />
          </Route>
        </Switch>
      </BasePage>
    </ApolloProvider>
  )
}

export const AuthenticatedApp: React.FC = () => {
  return (
    <AuthenticationProvider>
      <InnerAuthenticatedApp />
    </AuthenticationProvider>
  )
}
