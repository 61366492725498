import React from 'react'
import {
  useGetTicketsQuery,
  usePrintTicketAsPdfMutation,
  useSendTicketEmailMutation,
} from '../../../../../generated/graphql-hooks'
import { GenericDataTable, usePageQueryState } from '../../../../../components/GenericDataTable'
import ViewIcon from '@material-ui/icons/VisibilityRounded'
import EmailRoundedIcon from '@material-ui/icons/EmailRounded'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Ticket } from '../../../../../generated/graphql-types'
import { labels } from '../../../../../utils/labelUtils'
import { Chip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../../utils/routeUtils'
import { deSerializeDate, formatDate, getBeginDate, incrementDate, serializeDate } from '../../../../../utils/dateUtils'
import { TicketsViewProps } from './index'
import { DEFAULT_POLLING_INTERVAL } from '../../../../../utils/apolloUtils'
import { maxAllowedEntries } from '../../../../../utils/entities/ticketUtils'
import { useNotifications } from '../../../../../utils/notificationUtils'

export const TicketsTable: React.FC<TicketsViewProps> = (props) => {
  const [queryState, setQueryState] = usePageQueryState()
  const history = useHistory()
  const { showError, showSuccess } = useNotifications()
  const [sendTicketEmailMutation] = useSendTicketEmailMutation()
  const [printTicketAsPdfMutation] = usePrintTicketAsPdfMutation()

  const downloadFile = async (base64String: string, filename = 'Eintrittskarte-FertighausWelt') => {
    const data = Buffer.from(base64String, 'base64')
    const blob = new Blob([data], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `${filename}-${+new Date()}.pdf`
    link.click()
  }

  const handlePdfDownload = (uuid: string): void => {
    printTicketAsPdfMutation({
      variables: {
        uuid,
      },
    })
      .then(async (response) => {
        const base64String = response.data?.printTicketAsPdf || ''
        await downloadFile(base64String)
      })
      .catch((e) => {
        console.error(e)
        showError('Fehler beim PDF Download')
      })
  }

  const handleSendEmail = (uuid: string): void => {
    sendTicketEmailMutation({
      variables: {
        uuid,
      },
    })
      .then(() => showSuccess('Die Email wurde erfolgreich gesendet.'))
      .catch((err) => {
        console.error(err)
        showError('Die Email konnte nicht versendet werden.')
      })
  }

  const data = useGetTicketsQuery({
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: {
      pageQuery: queryState,
      dateQuery: {
        beginDate: serializeDate(getBeginDate(props.selectedDate, props.view)),
        endDate: serializeDate(incrementDate(getBeginDate(props.selectedDate, props.view), props.view, false)),
      },
    },
  })
  const tickets = data.data?.getTickets.tickets ?? []

  return (
    <>
      <GenericDataTable
        data={tickets}
        pageQuery={queryState}
        isLoading={data.loading}
        onPageQueryChange={setQueryState}
        pageInfo={data.data?.getTickets.pageInfo}
        columns={[
          { title: 'Ticket ID', field: 'uuid', hidden: true },
          { title: 'Typ', field: 'type', render: (data) => <Chip label={labels.enums.TicketType[data.type]} /> },
          { title: 'Kommentar', field: 'comment' },
          {
            title: 'Datum',
            field: 'date',
            render: (data) => data?.expectedEntryDate && formatDate(deSerializeDate(data.expectedEntryDate)),
          },
          { title: 'Vorname', field: 'firstName', render: (data) => data?.visitor?.firstName },
          { title: 'Name', field: 'name', render: (data) => data?.visitor?.name },
          { title: 'Email', field: 'email', render: (data) => data?.visitor?.email },
          {
            title: 'Handeslvertreter',
            field: 'salesRepresentative',
            render: (data) =>
              data?.visitor?.appointments
                ?.map(
                  (appointment) =>
                    `${appointment?.salesRepresentative?.name} (${appointment?.salesRepresentative?.manufacturer?.name})`
                )
                .join(', '),
          },
          {
            title: 'Anzahl Personen',
            field: 'numAdults',
            render: (data) => `${data?.visitor?.numAdults} Erwachsene, ${data?.visitor?.numChildren} Kinder`,
          },
          {
            title: 'Verbleibende Eintritte',
            field: 'remainingEntries',
            render: (data) =>
              data.remainingEntries < maxAllowedEntries[data.type] ? <DirectionsRunIcon /> : data.remainingEntries,
          },
        ]}
        title={'Tickets'}
        actions={[
          {
            icon: ViewIcon,
            tooltip: 'Details',
            onClick: (event, rowData) => {
              if (((rowData as unknown) as Ticket).uuid) {
                history.push(routes.authenticated.Tickets.Table.Detail.get(((rowData as unknown) as Ticket).uuid))
              }
            },
          },
          {
            icon: EmailRoundedIcon,
            tooltip: 'E-Mail erneut senden',
            onClick: (event, rowData) => {
              handleSendEmail(((rowData as unknown) as Ticket).uuid || '')
            },
          },
          {
            icon: GetAppIcon,
            tooltip: 'Download PDF',
            onClick: (event, rowData) => {
              handlePdfDownload(((rowData as unknown) as Ticket).uuid || '')
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </>
  )
}
