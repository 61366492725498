import React, { useMemo } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useUpdateAppointmentMutation } from '../../../generated/graphql-hooks'
import { deSerializeDate, formatDate, formatTime, serializeDate } from '../../../utils/dateUtils'
import { useNotifications } from '../../../utils/notificationUtils'
import { Appointment } from '../../../generated/graphql-types'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de'

export interface DialogProps<T> {
  selectedRow: T | null
  open: boolean
  handleClose: () => void
}

export const UpdateAppointmentDialog: React.FC<DialogProps<Appointment>> = (props) => {
  const { showError, showSuccess } = useNotifications()
  const validationSchema = yup.object({
    date: yup.string().required('Datum ist erforderlich'),
  })

  const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
    refetchQueries: ['getAppointments', 'getAppointment'],
  })

  const selectedRowDate = useMemo(() => {
    return props.selectedRow?.date ? deSerializeDate(props.selectedRow.date) : new Date()
  }, [props.selectedRow])
  const formik = useFormik({
    initialValues: {
      date: selectedRowDate,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.handleClose()
      updateAppointmentMutation({
        variables: {
          updateAppointment: {
            uuid: props.selectedRow?.uuid || '',
            date: serializeDate(values.date),
          },
        },
      })
        .then(() => showSuccess('Termin wurde erfolgreich bearbeitet.'))
        .catch((err) => {
          console.error(err)
          showError('Termin konnte nicht bearbeitet werden.')
        })
    },
  })

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle
            title={'Termin bearbeiten'}
            subtitle={[
              formatDate(deSerializeDate(props.selectedRow?.date || '')) || '',
              'um',
              formatTime(deSerializeDate(props.selectedRow?.date || '')),
              'Uhr.',
            ].join(' ')}
          />
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant={'caption'}>Email:</Typography>
                <Typography>{props.selectedRow?.visitor?.email || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <DateTimePicker
                    cancelLabel={'Abbrechen'}
                    todayLabel={'Heute'}
                    fullWidth={true}
                    minutesStep={15}
                    label={'Datum & Uhrzeit'}
                    ampm={false}
                    disablePast={true}
                    showTodayButton={true}
                    name={'date'}
                    format={'dd.MM.yyyy HH:mm'}
                    id={'date'}
                    value={formik.values['date']}
                    onChange={(date) => {
                      formik.setFieldValue('date', date)
                    }}
                    error={formik.touched['date'] && Boolean(formik.errors['date'])}
                    helperText={formik.touched['date'] && formik.errors['date']}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Bearbeiten
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <LoadingBackdrop loading={loading} />
    </>
  )
}
