import React from 'react'
import {
  Button,
  ButtonGroup, Checkbox,
  Dialog,
  DialogActions,
  DialogContent, FormControl,
  FormControlLabel,
  Grid,
  TextField
} from '@material-ui/core'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useCreateAppointmentMutation } from '../../../generated/graphql-hooks'
import { deSerializeDate, serializeDate } from '../../../utils/dateUtils'
import { useNotifications } from '../../../utils/notificationUtils'
import { useAuthData } from '../../../utils/auth/AuthenticationProvider'
import { formikTextFieldArgs } from '../../../utils/formUtils'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'
import { DateTimePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de'

export const CreateAppointmentButton: React.FC = () => {
  const { showError, showSuccess } = useNotifications()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const validationSchema = yup.object({
    email: yup.string().required('Email Adresse ist erforderlich'),
    date: yup.string().required('Datum ist erforderlich'),
  })

  const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
    refetchQueries: ['getAppointments', 'getAppointment'],
  })
  const authData = useAuthData()

  const getRoundedMinutes = (date: Date) => {
    if (date.getMinutes() < 15) {
      return 15
    }
    if (date.getMinutes() < 30) {
      return 30
    }
    if (date.getMinutes() < 45) {
      return 45
    }
    return 0
  }
  const now = new Date()
  const startDate = new DateFnsUtils().setMinutes(now, getRoundedMinutes(now))
  const formik = useFormik({
    initialValues: {
      email: '',
      date: startDate,
      bill: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClose()
      createAppointmentMutation({
        variables: {
          createAppointment: {
            date: serializeDate(values.date),
            email: values.email,
            salesRepresentativeUuid: authData.getUser().uuid,
            bill: values.bill ? false : true
          },
        },
      })
        .then(() => {
          showSuccess('Termin wurde erfolgreich erstellt.')
          formik.resetForm()
        })
        .catch((err) => {
          console.error(err)
          showError('Termin konnte nicht erstellt werden.')
        })
    },
  })

  return (
    <>
      <Button variant={'contained'} color={'primary'} onClick={handleClickOpen}>
        Termin anlegen
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle title={'Termin neu anlegen'} />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bill"
                      color="primary"
                      value={formik.values.bill}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Kostenloses Ticket"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Email Adresse" {...formikTextFieldArgs('email', formik)} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <DateTimePicker
                    cancelLabel={'Abbrechen'}
                    todayLabel={'Heute'}
                    fullWidth={true}
                    minutesStep={15}
                    label={'Datum & Uhrzeit'}
                    ampm={false}
                    disablePast={true}
                    showTodayButton={true}
                    name={'date'}
                    format={'dd.MM.yyyy HH:mm'}
                    id={'date'}
                    value={formik.values['date']}
                    onChange={(date) => {
                      formik.setFieldValue('date', date)
                    }}
                    error={formik.touched['date'] && Boolean(formik.errors['date'])}
                    helperText={formik.touched['date'] && formik.errors['date']}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Anlegen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
