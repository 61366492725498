import React from 'react'
import { FHWScreen } from '../../components/FHWScreen'
import { PaperModal } from '../../components/PaperModal'

export const AuthInvalidPage: React.FC = () => {
  return (
    <FHWScreen>
      <PaperModal>
        Sie besitzen leider nicht die nötige Berichtigung um auf Fertighauswelt Park Management zuzugreifen.
      </PaperModal>
    </FHWScreen>
  )
}
