import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useCreateCenterManagerMutation, useGetParksQuery } from '../../../generated/graphql-hooks'
import { useNotifications } from '../../../utils/notificationUtils'
import { useAuthData } from '../../../utils/auth/AuthenticationProvider'
import { formikTextFieldArgs } from '../../../utils/formUtils'
import { RemoteSelect, SelectOption } from '../../../components/RemoteSelect'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'

export const CreateCenterManagerButton: React.FC = () => {
  const { showError, showSuccess } = useNotifications()
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const validationSchema = yup.object({
    email: yup.string().required('Email Adresse ist erforderlich'),
    firstName: yup.string().required('Vorname ist erforderlich'),
    name: yup.string().required('Nachname ist erforderlich'),
    parkUuids: yup.array().min(1, 'Park ist erforderlich'),
  })

  const useParksSelectQuery = (search?: string) =>
    useGetParksQuery({
      variables: { pageQuery: { page: 0, pageSize: 10, search } },
    })

  const [createCenterManagerMutation, { data, loading, error }] = useCreateCenterManagerMutation({
    refetchQueries: ['getCenterManagers'],
  })
  const authData = useAuthData()
  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      name: '',
      parkUuids: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClose()
      createCenterManagerMutation({
        variables: {
          createCenterManager: {
            email: values.email,
            firstName: values.firstName,
            name: values.name,
            parkUuids: values.parkUuids,
          },
        },
      })
        .then(() => {
          showSuccess('Center Manager wurde erfolgreich erstellt.')
          formik.resetForm()
        })
        .catch((err) => {
          console.error(err)
          showError('Center Manager konnte nicht erstellt werden.')
        })
    },
  })

  return (
    <>
      <Button variant={'contained'} color={'primary'} onClick={handleClickOpen}>
        Center Manager anlegen
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle title={'Neuen Center Manager anlegen'} />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField label="Email Adresse" {...formikTextFieldArgs('email', formik)} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Vorname" {...formikTextFieldArgs('firstName', formik)} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Nachname" {...formikTextFieldArgs('name', formik)} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <RemoteSelect
                  label={'Parks'}
                  useQuery={useParksSelectQuery}
                  multiple={true}
                  queryResultToOptions={(queryResult) =>
                    queryResult?.getParks.parks.map<SelectOption>((x) => ({
                      label: x?.name ?? '',
                      value: x?.uuid ?? '',
                    })) ?? []
                  }
                  formik={formik}
                  name={'parkUuids'}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Anlegen
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
