import React from 'react'
import { routes } from '../../../../utils/routeUtils'

import { Redirect, Route, Switch } from 'react-router-dom'
import { AppointmentDetail } from './AppointmentDetail'
import { AppointmentsCalendarAndTableView } from './CalendarAndTableView'

export const AppointmentsTab: React.FC = () => {
  return (
    <Switch>
      <Route path={routes.authenticated.Appointments.Detail.pathDef}>
        {({ match }) => <AppointmentDetail uuid={match?.params.uuid ?? ''} />}
      </Route>
      <Route path={routes.authenticated.Appointments.CalendarAndTable.pathDef}>
        <AppointmentsCalendarAndTableView />
      </Route>
      <Route>
        <Redirect to={routes.authenticated.Appointments.CalendarAndTable.pathDef} />
      </Route>
    </Switch>
  )
}
