import React, { useRef, useState } from 'react'
import { useAuthData, UserRole } from '../../../../utils/auth/AuthenticationProvider'
import { getReport } from '../../../../utils/reportsUtil'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'
import { ForecastReport, ForecastReportResult } from './ForecastReport'
import { RetrospectionReport, RetrospectionReportResult } from './RetrospectionReport'
import { RevenueReport, RevenueReportResult } from './RevenueReport'
import { BillingReport, BillingReportResult } from './BillingReport'
import { useNotifications } from '../../../../utils/notificationUtils'
import { VisitorReport, VisitorReportResult } from './VisitorReport'
import { ManufacturerReport, ManufacturerReportResult } from './ManufacturerReport'

export type ReportResult =
  | ForecastReportResult
  | RetrospectionReportResult
  | RevenueReportResult
  | BillingReportResult
  | VisitorReportResult
  | ManufacturerReportResult

export const useReportStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      backgroundColor: '#a9a9a92e',
      padding: '15px',
      borderRadius: '5px',
      marginBottom: '30px',
    },
    table: {
      width: '100%',
      '& thead': {
        backgroundColor: '#636363ba',
        color: 'white',
      },
      '& th': {
        padding: '5px',
      },
      '& td': {
        padding: '5px',
      },
      '& tbody tr:nth-child(even)': {
        backgroundColor: '#a9a9a970',
      },
      '& tbody tr:nth-child(odd)': {
        backgroundColor: '#a9a9a92e',
      },
    },
  })
)

const AvailableReports: {
  label: string
  id: number
  roles: UserRole[]
}[] = [
  {
    id: 1,
    label: 'Planungsreport',
    roles: [UserRole.BdfAdmin, UserRole.CenterManager],
  },
  {
    id: 2,
    label: 'Ist Report',
    roles: [UserRole.BdfAdmin, UserRole.CenterManager],
  },
  {
    id: 3,
    label: 'Umsatz Report',
    roles: [UserRole.BdfAdmin],
  },
  {
    id: 4,
    label: 'Abrechnungsreport',
    roles: [UserRole.BdfAdmin],
  },
  {
    id: 5,
    label: 'Spezialreport (1)',
    roles: [UserRole.BdfAdmin],
  },
  {
    id: 7,
    label: 'Spezialreport (3)',
    roles: [UserRole.BdfAdmin],
  },
]

export const ReportsTab: React.FC = () => {
  const classes = useReportStyles()
  const { showError } = useNotifications()
  const authData = useAuthData()
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const loadingLock = useRef(false)
  const [report, setReport] = useState<ReportResult | null>(null)
  const [selectedBeginDate, setSelectedBeginDate] = React.useState<Date>(new Date())
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date>(new Date())
  const [reportId, setReportId] = React.useState(AvailableReports[0]?.id)
  const handleChangeReportId = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReportId(event.target.value as any)
  }
  const [period, setPeriod] = React.useState('m')
  const handleChangePeriod = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPeriod(event.target.value as string)
  }
  const handleChangeBeginDate = (date: Date) => {
    date.setHours(6)
    date.setMinutes(0)
    date.setMilliseconds(0)
    setSelectedBeginDate(date)
  }
  const handleChangeEndDate = (date: Date) => {
    date.setHours(22)
    date.setMinutes(0)
    date.setMilliseconds(0)
    setSelectedEndDate(date)
  }
  const handleClick = () => {
    if (loadingLock.current) {
      return
    }
    loadingLock.current = true
    setLoadingData(true)
    getReport(
      authData.getToken(),
      reportId,
      selectedBeginDate.toISOString().slice(0, 10),
      selectedEndDate.toISOString().slice(0, 10),
      period
    )
      .then((response) => {
        setReport(response)
      })
      .catch((err) => {
        console.error('error while loading report', err)
        showError('Report konnte nicht geladen werden', 'Fehler')
      })
      .finally(() => {
        loadingLock.current = false
        setLoadingData(false)
      })
  }

  return (
    <>
      <Grid container justify="space-around" className={classes.grid}>
        <FormControl>
          <InputLabel id="report-label">Report</InputLabel>
          <Select labelId="report-label" id="report" value={reportId} onChange={handleChangeReportId}>
            {AvailableReports.map(
              (report) =>
                report.roles.includes(authData.getUser().role) && (
                  <MenuItem key={report.id} value={report.id}>
                    {report.label}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="period-label">Zeitraum</InputLabel>
          <Select labelId="period-label" id="period" value={period} onChange={handleChangePeriod}>
            <MenuItem value={'d'}>Tag</MenuItem>
            <MenuItem value={'w'}>Woche</MenuItem>
            <MenuItem selected value={'m'}>Monat</MenuItem>
            <MenuItem value={'y'}>Jahr</MenuItem>
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
          <KeyboardDatePicker
            disableToolbar
            variant={'inline'}
            format={'dd.MM.yyyy'}
            id={'beginDate'}
            label={'Beginn'}
            value={selectedBeginDate}
            onChange={handleChangeBeginDate}
          />
          <KeyboardDatePicker
            disableToolbar
            variant={'inline'}
            format={'dd.MM.yyyy'}
            id={'endDate'}
            label={'Ende'}
            value={selectedEndDate}
            onChange={handleChangeEndDate}
          />
        </MuiPickersUtilsProvider>
        <Button variant="contained" color="primary" onClick={handleClick}>
          generieren
        </Button>
      </Grid>
      {loadingData ? <CircularProgress /> : <ReportRenderer reportResult={report} />}
    </>
  )
}

const ReportRenderer: React.FC<{ reportResult: ReportResult | null }> = ({ reportResult }) => {
  if (!reportResult) {
    return null
  }

  if (reportResult.name === 'FORECAST') {
    return <ForecastReport reportResult={reportResult} />
  }

  if (reportResult.name === 'RETROSPECTION') {
    return <RetrospectionReport reportResult={reportResult} />
  }

  if (reportResult.name === 'REVENUE') {
    return <RevenueReport reportResult={reportResult} />
  }

  if (reportResult.name === 'BILLING') {
    return <BillingReport reportResult={reportResult} />
  }

  if (reportResult.name === 'VISITOR') {
    return <VisitorReport reportResult={reportResult} />
  }

  if (reportResult.name === 'MANUFACTURER') {
    return <ManufacturerReport reportResult={reportResult} />
  }

  return <h1>Fehler: Report "{(reportResult as any).name}" nicht gefunden</h1>
}
