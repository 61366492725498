import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RegisterForAppointmentPage, RegisterForAppointmentGuard } from './Pages/RegisterForAppointmentPage'
import {
  RequestChangeForAppointmentGuard,
  RequestChangeForAppointmentPage,
} from './Pages/RequestChangeForAppointmentPage'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { getEnvironment } from '../../utils/envUtils'
import { routes } from '../../utils/routeUtils'

export const PublicApp: React.FC = () => {
  return (
    <ApolloProvider
      client={
        new ApolloClient({
          uri: getEnvironment().GqlEndpoint,
          cache: new InMemoryCache({}),
        })
      }
    >
      <Switch>
        <Route path={routes.public.RegisterForAppointment.pathDef}>
          {(props) => {
            const uuid = props.match?.params?.uuid || ''
            return (
              <RegisterForAppointmentGuard uuid={uuid}>
                <RegisterForAppointmentPage uuid={uuid} />
              </RegisterForAppointmentGuard>
            )
          }}
        </Route>
        <Route path={routes.public.ChangeAppointment.pathDef}>
          {(props) => {
            const uuid = props.match?.params?.uuid || ''
            return (
              <RequestChangeForAppointmentGuard uuid={uuid}>
                <RequestChangeForAppointmentPage uuid={uuid} />
              </RequestChangeForAppointmentGuard>
            )
          }}
        </Route>
        <Route>
          <Redirect to={routes.authenticated.Home.pathDef} />
        </Route>
      </Switch>
    </ApolloProvider>
  )
}
