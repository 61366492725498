import React from 'react'
import { createMuiTheme, CssBaseline, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core'
import { ThemeProvider } from 'styled-components'

let theme = createMuiTheme({
  overrides: {
    MuiTab: {
      root: {
        minWidth: '100px !important',
        textTransform: 'none',
      },
    },
  },
  palette: {
    primary: {
      main: '#636363',
    },
    secondary: {
      main: '#980A2B',
    },
    background: {
      default: '#FCFDFF',
    },
  },
})
theme = responsiveFontSizes(theme)

export const FHWThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </MuiThemeProvider>
)
