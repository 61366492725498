import { DialogProps } from './UpdateAppointmentDialog'
import * as yup from 'yup'
import { Manufacturer } from '../../../generated/graphql-types'
import { useUpdateManufacturerMutation } from '../../../generated/graphql-hooks'
import { useFormik } from 'formik'
import { useNotifications } from '../../../utils/notificationUtils'
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core'
import { formikTextFieldArgs } from '../../../utils/formUtils'
import React from 'react'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'

export const UpdateManufacturerDialog: React.FC<DialogProps<Manufacturer>> = (props) => {
  const { showError, showSuccess } = useNotifications()
  const validationSchema = yup.object({
    name: yup.string().required('Name ist erforderlich'),
    street: yup.string(),
    streetNumber: yup.string().when(['street'], {
      is: (value: string) => !!value,
      then: yup.string().required('Bitte vollständige Addresse eingeben, oder alle Addressfelder frei lassen.'),
      otherwise: yup.string(),
    }),
    postalCode: yup.string().when(['streetNumber'], {
      is: (value: string) => !!value,
      then: yup.string().required('Bitte vollständige Addresse eingeben, oder alle Addressfelder frei lassen.'),
      otherwise: yup.string(),
    }),
    city: yup.string().when(['postalCode'], {
      is: (value: string) => !!value,
      then: yup.string().required('Bitte vollständige Addresse eingeben, oder alle Addressfelder frei lassen.'),
      otherwise: yup.string(),
    }),
  })
  const [updateManufacturerMutation, { data, loading, error }] = useUpdateManufacturerMutation({
    refetchQueries: ['getManufacturers'],
  })

  const formik = useFormik({
    initialValues: {
      name: props.selectedRow?.name || '',
      street: props.selectedRow?.address?.street || '',
      streetNumber: props.selectedRow?.address?.streetNumber || '',
      postalCode: props.selectedRow?.address?.postalCode || '',
      city: props.selectedRow?.address?.city || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.handleClose()
      updateManufacturerMutation({
        variables: {
          updateManufacturer: {
            uuid: props.selectedRow?.uuid || '',
            name: values.name,
            address: {
              street: values.street,
              streetNumber: values.streetNumber,
              postalCode: values.postalCode,
              city: values.city,
            },
          },
        },
      })
        .then(() => showSuccess('Hersteller wurde erfolgreich bearbeitet.'))
        .catch((err) => {
          console.error(err)
          showError('Hersteller konnte nicht bearbeitet werden.')
        })
    },
  })

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={formik.handleSubmit}>
          <FHWDialogTitle title={'Hersteller bearbeiten'} subtitle={props.selectedRow?.name || ''} />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField label="Name" {...formikTextFieldArgs('name', formik)} fullWidth />
              </Grid>
              <Grid item xs={8}>
                <TextField label="Straße" {...formikTextFieldArgs('street', formik)} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextField label="Hausnummer" {...formikTextFieldArgs('streetNumber', formik)} fullWidth />
              </Grid>
              <Grid item xs={8}>
                <TextField label="Stadt" {...formikTextFieldArgs('city', formik)} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextField label="Postleitzahl" {...formikTextFieldArgs('postalCode', formik)} fullWidth />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} variant="outlined" color="primary">
              Abbrechen
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Bearbeiten
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
