import React from 'react'
import { Grid, Tab } from '@material-ui/core'
import { TabSpec, useTabs } from '../../../../utils/tabUtils'
import { TableActionWrapper } from '../../../../components/TableActionWrapper'
import { PillStyleTabs } from '../../../../components/PillStyleTabs'
import { routes } from '../../../../utils/routeUtils'
import { TicketScan } from './TicketScan'
import { Redirect, Route, Switch } from 'react-router-dom'
import { TicketDetail } from './TicketDetail'
import { TicketRegistration } from './TicketRegistration'
import { TicketsCalendarAndTableView } from './CalendarAndTableView'

const tabSpecs: TabSpec[] = [
  {
    id: 0,
    label: 'Tabelle',
    route: routes.authenticated.Tickets.Table._group,
    content: () => (
      <Switch>
        <Route path={routes.authenticated.Tickets.Table.Detail.pathDef}>
          {({ match }) => <TicketDetail uuid={match?.params.uuid ?? ''} />}
        </Route>
        <Route path={routes.authenticated.Tickets.Table.RegisterVisitor.pathDef}>
          {({ match }) => <TicketRegistration uuid={match?.params.uuid ?? ''} />}
        </Route>
        <Route path={routes.authenticated.Tickets.Table.List.pathDef}>
          <TicketsCalendarAndTableView />
        </Route>
        <Route>
          <Redirect to={routes.authenticated.Tickets.Table.List.pathDef} />
        </Route>
      </Switch>
    ),
  },
  {
    id: 1,
    label: 'Scan',
    route: routes.authenticated.Tickets.Scan._group,
    content: () => (
      <Switch>
        <Route path={routes.authenticated.Tickets.Scan.Detail.pathDef}>
          {({ match }) => <TicketDetail uuid={match?.params.uuid ?? ''} />}
        </Route>
        <Route path={routes.authenticated.Tickets.Scan.RegisterVisitor.pathDef}>
          {({ match }) => <TicketRegistration uuid={match?.params.uuid ?? ''} />}
        </Route>
        <Route path={routes.authenticated.Tickets.Scan.TicketScan.pathDef}>
          <TicketScan />
        </Route>
        <Route>
          <Redirect to={routes.authenticated.Tickets.Scan.TicketScan.pathDef} />
        </Route>
      </Switch>
    ),
  },
]

export const TicketsTab: React.FC = () => {
  const tabs = useTabs(tabSpecs)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3} justify={'flex-end'}>
          <Grid item>
            <TableActionWrapper style={{ maxWidth: '400px' }}>
              <PillStyleTabs value={tabs.currentId} onChange={(e, val) => tabs.change(val)}>
                {tabs.renderTabs()}
              </PillStyleTabs>
            </TableActionWrapper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {tabs.renderCurrent()}
      </Grid>
    </Grid>
  )
}
