import React from 'react'
import { Button, Dialog, DialogActions } from '@material-ui/core'
import { useMarkAppointmentAsMissedMutation } from '../../../generated/graphql-hooks'
import { deSerializeDate, formatDate, formatTime } from '../../../utils/dateUtils'
import { useNotifications } from '../../../utils/notificationUtils'
import { Appointment } from '../../../generated/graphql-types'
import { DialogProps } from './UpdateAppointmentDialog'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { FHWDialogTitle } from '../../../components/FHWDialogTitle'

export const MarkAppointmentAsMissedDialog: React.FC<DialogProps<Appointment>> = (props) => {
  const { showError, showSuccess } = useNotifications()

  const [markAppointmentAsMissed, { data, loading, error }] = useMarkAppointmentAsMissedMutation({
    refetchQueries: ['getAppointments', 'getAppointment'],
  })

  const onSubmit = () => {
    markAppointmentAsMissed({
      variables: {
        uuid: props.selectedRow?.uuid || '',
      },
    })
      .then(() => showSuccess('Termin erfolgreich als Platzer markiert.'))
      .catch((err) => {
        console.error(err)
        showError('Fehler beim markieren des Termins als Platzer.')
      })
    props.handleClose()
  }

  return (
    <>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <FHWDialogTitle
          title={'Termin als Platzer markieren'}
          subtitle={[
            formatDate(deSerializeDate(props.selectedRow?.date || '')) || '',
            'um',
            formatTime(deSerializeDate(props.selectedRow?.date || '')),
            'Uhr.',
          ].join(' ')}
        />
        <DialogActions>
          <Button onClick={props.handleClose} variant="outlined" color="primary">
            Abbrechen
          </Button>
          <Button onClick={onSubmit} variant="contained" color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop loading={loading} />
    </>
  )
}
