interface Environment {
  GqlEndpoint: string
  OidcEndpoint: string
  OidcRealm: string
  OidcClientId: string
  RestEndpoint: string
}
export const getEnvironment = (): Environment => ({
  GqlEndpoint: process.env.REACT_APP_GQL_ENDPOINT || '',
  OidcClientId: process.env.REACT_APP_OIDC_CLIENT_ID || '',
  OidcRealm: process.env.REACT_APP_OIDC_REALM || '',
  OidcEndpoint: process.env.REACT_APP_OIDC_ENDPOINT || '',
  RestEndpoint: process.env.REACT_APP_REST_ENDPOINT || '',
})
