import React, { MouseEvent } from 'react'
import { ButtonGroup, Grid, IconButton, Tab } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { TabPanel } from '../../../../../utils/tabUtils'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import deLocale from 'date-fns/locale/de'
import { incrementDate } from '../../../../../utils/dateUtils'
import { TableActionWrapper } from '../../../../../components/TableActionWrapper'
import { PillStyleTabs } from '../../../../../components/PillStyleTabs'
import { styled } from '../../../../../theme'
import { TicketsTable } from './TicketsTable'
import { TicketsCalendar, ViewMode } from './TicketsCalendar'

export interface TicketsViewProps {
  view: ViewMode
  selectedDate: Date
}

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  &.MuiFormControl-root {
    justify-content: center;
  }
`

export const TicketsCalendarAndTableView: React.FC = () => {
  // Handle mode ( table / calendar )
  const [selectedMode, setMode] = React.useState(0)
  const handleModeChange = (event: React.ChangeEvent<unknown>, mode: number) => {
    setMode(mode)
  }
  // Handle view mode ( week / day )
  const [selectedViewMode, setViewMode] = React.useState<ViewMode>('week')
  const handleViewModeChange = (event: React.ChangeEvent<unknown>, viewMode: ViewMode) => {
    setViewMode(viewMode)
  }
  // Handle selected date
  const [selectedDate, setDate] = React.useState<Date>(new Date())
  const handleDateChange = (date: Date) => {
    setDate(date)
  }
  // Handle navigation buttons ( prev / next )
  const handlePrev = (event: MouseEvent) => {
    event.preventDefault()
    handleDateChange(incrementDate(selectedDate, selectedViewMode, true))
  }
  const handleNext = (event: MouseEvent) => {
    event.preventDefault()
    handleDateChange(incrementDate(selectedDate, selectedViewMode, false))
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3} justify={'flex-start'}>
          <Grid item>
            <TableActionWrapper>
              <ButtonGroup color="primary">
                <IconButton aria-label="back" onClick={handlePrev}>
                  <ArrowBackIcon />
                </IconButton>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                  <StyledKeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd. MMMM yyyy"
                    id="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
                <IconButton aria-label="forward" onClick={handleNext}>
                  <ArrowForwardIcon />
                </IconButton>
              </ButtonGroup>
            </TableActionWrapper>
          </Grid>
          <Grid item>
            <TableActionWrapper>
              <PillStyleTabs value={selectedViewMode} onChange={handleViewModeChange}>
                <Tab label="Tag" value={'day'} />
                <Tab label="Woche" value={'week'} />
              </PillStyleTabs>
            </TableActionWrapper>
          </Grid>
          <Grid item>
            <TableActionWrapper>
              <PillStyleTabs value={selectedMode} onChange={handleModeChange}>
                <Tab label="Tabelle" value={0} />
                <Tab label="Kalender" value={1} />
              </PillStyleTabs>
            </TableActionWrapper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={selectedMode} index={0}>
          <TicketsTable view={selectedViewMode} selectedDate={selectedDate} />
        </TabPanel>
        <TabPanel value={selectedMode} index={1}>
          <TicketsCalendar view={selectedViewMode} selectedDate={selectedDate} />
        </TabPanel>
      </Grid>
    </Grid>
  )
}
