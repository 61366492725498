import { AppointmentStatus } from '../../generated/graphql-types'

export const statusColorMap: { [key in AppointmentStatus]: string } = {
  [AppointmentStatus.Created]: 'gray',
  [AppointmentStatus.InvitationSent]: 'orange',
  [AppointmentStatus.ChangeRequested]: 'yellow',
  [AppointmentStatus.Registered]: 'green',
  [AppointmentStatus.Missed]: 'blue',
  [AppointmentStatus.Canceled]: 'red',
}
