import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AuthenticatedApp } from './pages/authenticated/AuthenticatedApp'
import { PublicApp } from './pages/public/PublicApp'
import { StylesProvider } from '@material-ui/core'
import { FHWThemeProvider } from './theme'
import { SnackbarProvider } from 'notistack'

function App() {
  return (
    <StylesProvider injectFirst>
      <FHWThemeProvider>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <Switch>
              <Route path="/public">
                <PublicApp />
              </Route>
              <Route path="/">
                <AuthenticatedApp />
              </Route>
            </Switch>
          </Router>
        </SnackbarProvider>
      </FHWThemeProvider>
    </StylesProvider>
  )
}

export default App
