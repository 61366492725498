import React from 'react'
import { DialogTitle, Typography } from '@material-ui/core'
import { DialogTitleProps } from '@material-ui/core/DialogTitle/DialogTitle'

interface FHWDialogTitleProps extends DialogTitleProps {
  title: string
  subtitle?: string
}
export const FHWDialogTitle: React.FC<FHWDialogTitleProps> = ({ title, subtitle, ...props }) => {
  return (
    <DialogTitle {...props} disableTypography={true}>
      <Typography variant={'h6'} component={'h2'}>
        {title}
      </Typography>
      {subtitle && (
        <>
          <Typography variant={'caption'}>Ausgewählt:</Typography>
          <Typography variant={'subtitle1'} component={'h3'}>
            {subtitle}
          </Typography>
        </>
      )}
    </DialogTitle>
  )
}
