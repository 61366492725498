import React from 'react'
import { Box, Grid, Tabs } from '@material-ui/core'
import { SalesRepresentativesTab } from '../tabs/SalesRepresentativesTab'
import { CenterManagersTab } from '../tabs/CenterManagersTab'
import { ManufacturersTab } from '../tabs/ManufacturersTab'
import { ParksTab } from '../tabs/ParksTab'
import { ReportsTab } from '../tabs/ReportsTab'
import { CreateSalesRepresentativeButton } from '../buttons/CreateSalesRepresentativeButton'
import { CreateCenterManagerButton } from '../buttons/CreateCenterManagerButton'
import { CreateParkButton } from '../buttons/CreateParkButton'
import { CreateManufacturerButton } from '../buttons/CreateManufacturerButton'
import { CreateAppointmentButton } from '../buttons/CreateAppointmentButton'
import { UserRole } from '../../../utils/auth/AuthenticationProvider'
import { CreateTicketButton } from '../buttons/CreateTicketButton'
import { TicketsTab } from '../tabs/ticketsTab'
import { routes } from '../../../utils/routeUtils'
import { TabSpec, useTabs } from '../../../utils/tabUtils'
import { AppointmentsTab } from '../tabs/appointmentsTab'

const tabSpecs: TabSpec[] = [
  {
    id: 0,
    action: <CreateTicketButton />,
    label: 'Tickets',
    content: () => <TicketsTab />,
    authorizedRoles: [UserRole.CenterManager, UserRole.BdfAdmin],
    route: routes.authenticated.Tickets._group,
  },
  {
    id: 1,
    action: <CreateAppointmentButton />,
    label: 'Termine',
    content: () => <AppointmentsTab />,
    authorizedRoles: [UserRole.SalesRepresentative],
    route: routes.authenticated.Appointments._group,
  },
  {
    id: 2,
    action: <CreateSalesRepresentativeButton />,
    label: 'Handelsvertreter',
    content: () => <SalesRepresentativesTab />,
    authorizedRoles: [UserRole.CenterManager],
    route: routes.authenticated.SalesRepresentatives.pathDef,
  },
  {
    id: 3,
    action: <CreateCenterManagerButton />,
    label: 'Center Manager',
    content: () => <CenterManagersTab />,
    authorizedRoles: [UserRole.BdfAdmin],
    route: routes.authenticated.CenterManagers.pathDef,
  },
  {
    id: 4,
    action: <CreateManufacturerButton />,
    label: 'Hersteller',
    content: () => <ManufacturersTab />,
    authorizedRoles: [UserRole.BdfAdmin],
    route: routes.authenticated.Manufacturers.pathDef,
  },
  {
    id: 5,
    action: <CreateParkButton />,
    label: 'Parks',
    content: () => <ParksTab />,
    authorizedRoles: [UserRole.BdfAdmin],
    route: routes.authenticated.Parks.pathDef,
  },
  {
    id: 6,
    label: 'Reports',
    content: () => <ReportsTab />,
    authorizedRoles: [UserRole.BdfAdmin, UserRole.CenterManager],
    route: routes.authenticated.Reports.pathDef,
  },
]

export const HomePage: React.FC = () => {
  const tabs = useTabs(tabSpecs)
  return (
    <>
      <Grid container justify={'space-between'}>
        <Grid item>
          <Tabs value={tabs.currentId} onChange={(event, id) => tabs.change(id)}>
            {tabs.renderTabs()}
          </Tabs>
        </Grid>
        <Grid item>{tabs.renderAction()}</Grid>
      </Grid>
      <Box mt={3}>{tabs.renderCurrent()}</Box>
    </>
  )
}
