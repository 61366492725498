import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'

export const AuthLoadingPage: React.FC = () => {
  return (
    <Grid container justify={'center'} alignItems={'center'} style={{ width: '100vw', height: '100vh' }}>
      <Grid item>
        <CircularProgress color="secondary" size={'8em'} />
      </Grid>
    </Grid>
  )
}
