import React from 'react'
import { useReportStyles } from './index'

export type ForecastReportResult = {
  name: 'FORECAST'
  report: {
    label: string
    clusterDate: string
    data: {
      parkName: string
      onlineTickets: {
        label: string
        total: number
        numAdults: number
        numChildren: number
      }
      appointments: {
        label: string
        total: number
        numAdults: number
        numChildren: number
      }
    }[]
  }[]
}

export const ForecastReport: React.FC<{ reportResult: ForecastReportResult }> = ({ reportResult: { report } }) => {
  const classes = useReportStyles()
  return (
    <>
      <h2>Planungsreport</h2>
      {report.map((cluster) => (
        <div key={cluster.clusterDate}>
          <h3>{cluster.label}</h3>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>Parks</th>
                <th colSpan={3}>Ticket Online verkauft</th>
                <th colSpan={3}>Termine FHW Hersteller</th>
              </tr>
              <tr>
                <th></th>
                <th>Gesamt</th>
                <th>Erwachsene</th>
                <th>Kinder</th>
                <th>Gesamt</th>
                <th>Erwachsene</th>
                <th>Kinder</th>
              </tr>
            </thead>
            <tbody>
              {cluster.data.map((park, i) => (
                <tr key={i}>
                  <td>{park.parkName}</td>
                  <td>{park.onlineTickets.total}</td>
                  <td>{park.onlineTickets.numAdults}</td>
                  <td>{park.onlineTickets.numChildren}</td>
                  <td>{park.appointments.total}</td>
                  <td>{park.appointments.numAdults}</td>
                  <td>{park.appointments.numChildren}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  )
}
