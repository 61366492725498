import Swal from 'sweetalert2'
import { useSnackbar } from 'notistack'

const splashError = (message?: string): Promise<void> => {
  return Swal.fire({
    title: 'Fehler',
    text: message || 'Es ist ein unerwarteter Fehler aufgetreten',
    icon: 'error',
    confirmButtonText: 'OK',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }).then((value) => {})
}

const splashSuccess = (message: string, title?: string): Promise<void> => {
  return Swal.fire({
    title: title,
    text: message,
    icon: 'success',
    confirmButtonText: 'OK',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }).then((value) => {})
}

export const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar()

  const showError = (message?: string, title?: string): void => {
    enqueueSnackbar(message || 'Es ist ein unerwarteter Fehler aufgetreten', {
      title: title || 'Fehler',
      variant: 'error',
    })
  }

  const showSuccess = (message: string): void => {
    enqueueSnackbar(message, {
      variant: 'success',
    })
  }

  const showWarning = (message: string): void => {
    enqueueSnackbar(message, {
      variant: 'warning',
    })
  }

  return {
    showError,
    showSuccess,
    showWarning,
    splashSuccess,
    splashError,
  }
}
