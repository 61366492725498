import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  Link,
} from '@material-ui/core'
import React from 'react'
import { PaperModal } from '../../../components/PaperModal'
import { FHWScreen } from '../../../components/FHWScreen'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useGetAppointmentQuery, useRegisterForAppointmentMutation } from '../../../generated/graphql-hooks'
import { useNotifications } from '../../../utils/notificationUtils'
import { formikTextFieldArgs } from '../../../utils/formUtils'
import { LoadingBackdrop } from '../../../components/LoadingBackdrop'
import { AppointmentStatus } from '../../../generated/graphql-types'
import { styled } from '../../../theme'

const InFormText = styled.span`
  vertical-align: bottom;
`

const EmbeddedTextField = styled(TextField)`
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: baseline;
  input {
    padding-bottom: 0;
    padding-top: 0;
  }
`

const InFormParagraph = styled(Typography)`
  line-height: 200%;
`

interface RegisterForAppointmentProps {
  uuid: string
}

interface RegisterForAppointmentGuardProps {
  uuid: string
  children: React.ReactElement
}

export const RegisterForAppointmentGuard: React.FC<RegisterForAppointmentGuardProps> = (props) => {
  const query = useGetAppointmentQuery({ variables: { uuid: props.uuid } })
  const appointment = query.data?.getAppointment
  if (query.loading) {
    return <LoadingBackdrop loading={query.loading} />
  }
  if (!appointment) {
    return (
      <FHWScreen>
        <PaperModal>
          <Typography>Ihr Termin konnte nicht gefunden werden.</Typography>
        </PaperModal>
      </FHWScreen>
    )
  }
  if (appointment?.status === AppointmentStatus.Registered) {
    return (
      <FHWScreen>
        <PaperModal>
          <Typography>Ihr Termin ist bereits registriert.</Typography>
        </PaperModal>
      </FHWScreen>
    )
  }
  if (appointment?.status === AppointmentStatus.Canceled) {
    return (
      <FHWScreen>
        <PaperModal>
          <Typography>Ihr Termin wurde abgesagt.</Typography>
        </PaperModal>
      </FHWScreen>
    )
  }
  if (appointment?.status === AppointmentStatus.Missed) {
    return (
      <FHWScreen>
        <PaperModal>
          <Typography>Sie sind nicht zum Termin erschienen.</Typography>
        </PaperModal>
      </FHWScreen>
    )
  }
  return props.children
}

export const RegisterForAppointmentPage: React.FC<RegisterForAppointmentProps> = (props) => {
  const { splashError, splashSuccess } = useNotifications()
  const validationSchema = yup.object({
    firstName: yup.string().required('Vorname ist erforderlich'),
    name: yup.string().required('Name ist erforderlich'),
    postalCode: yup.string().required('Postleitzahl ist erforderlich'),
    city: yup.string().required('Stadt ist erforderlich'),
    numAdults: yup
      .number()
      .typeError('Bitte eine Zahl eingeben')
      .integer('Bitte eine Ganzzahl eingeben')
      .min(1, 'Mindestens ein Erwachsener')
      .required('Anzahl Erwachsene ist erforderlich'),
    numChildren: yup
      .number()
      .typeError('Bitte eine Zahl eingeben')
      .integer('Bitte eine Ganzzahl eingeben')
      .min(0)
      .required('Anzahl Kinder ist erforderlich'),
    termsAccepted: yup
      .boolean()
      .required('Bitte akzeptieren Sie die AGBs.')
      .oneOf([true], 'Bitte akzeptieren Sie die AGBs.'),
  })

  const [registerForAppointmentMutation, { data, loading, error }] = useRegisterForAppointmentMutation({
    refetchQueries: ['getAppointments', 'getAppointment'],
  })
  const formik = useFormik({
    initialValues: {
      firstName: '',
      name: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      numAdults: 1,
      numChildren: 0,
      termsAccepted: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      registerForAppointmentMutation({
        variables: {
          registerForAppointment: {
            uuid: props.uuid,
            firstName: values.firstName,
            name: values.name,
            numAdults: values.numAdults,
            numChildren: values.numChildren,
            address: {
              street: values.street,
              streetNumber: values.streetNumber,
              postalCode: values.postalCode,
              city: values.city,
            },
          },
        },
      })
        .then(() => splashSuccess('Termin wurde erfolgreich registriert. Sie können das Fenster jetzt schließen.'))
        .catch((err) => {
          console.error(err)
          splashError('Termin konnte nicht registriert werden.')
        })
    },
  })

  const query = useGetAppointmentQuery({ variables: { uuid: props.uuid } })
  const appointment = query.data?.getAppointment
  if (query.loading) {
    return <LoadingBackdrop loading={query.loading} />
  }

  const introMsg =
    appointment?.salesRepresentative?.name &&
    appointment.salesRepresentative.firstName &&
    appointment.salesRepresentative.manufacturer?.name
      ? `Nachricht von ${appointment.salesRepresentative.firstName} ${appointment.salesRepresentative.name} von ${appointment.salesRepresentative.manufacturer.name}`
      : `Nachricht von Fertighauswelt`

  return (
    <FHWScreen>
      <PaperModal>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h5'}>{introMsg}</Typography>
            </Grid>
            <Grid item xs={12}>
              <InFormParagraph variant={'body1'}>
                <InFormText>Ich heiße</InFormText>
                <EmbeddedTextField placeholder={'Vorname'} {...formikTextFieldArgs('firstName', formik)} />
                <EmbeddedTextField placeholder={'Nachname'} {...formikTextFieldArgs('name', formik)} />
                <InFormText>.</InFormText>
              </InFormParagraph>
              <InFormParagraph variant={'body1'}>
                <InFormText>in</InFormText>
                <EmbeddedTextField placeholder={'Postleitzahl'} {...formikTextFieldArgs('postalCode', formik)} />
                <InFormText>,</InFormText>
                <EmbeddedTextField placeholder={'Stadt'} {...formikTextFieldArgs('city', formik)} />
                <InFormText>.</InFormText>
              </InFormParagraph>
              <InFormParagraph variant={'body1'}>
                <InFormText>Ich komme mit</InFormText>
                <EmbeddedTextField
                  type={'number'}
                  InputProps={{ inputProps: { min: 1 } }}
                  placeholder={'Anzahl'}
                  {...formikTextFieldArgs('numAdults', formik)}
                />
                <InFormText>Erwachsenen und</InFormText>
                <EmbeddedTextField
                  type={'number'}
                  InputProps={{ inputProps: { min: 0 } }}
                  placeholder={'Anzahl'}
                  {...formikTextFieldArgs('numChildren', formik)}
                />
                <InFormText>Kindern.</InFormText>
              </InFormParagraph>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={formik.touched.termsAccepted && Boolean(formik.errors.termsAccepted)}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="termsAccepted"
                      color="primary"
                      value={formik.values.termsAccepted}
                      onChange={formik.handleChange}
                    />
                  }
                  label={
                    <Link
                      href="https://www.fertighauswelt.de/datenschutz.html"
                      rel={'noopener'}
                      target={'_blank'}
                      underline="always"
                    >
                      Ich akzeptiere die AGBs.
                    </Link>
                  }
                />
                <FormHelperText error={formik.touched.termsAccepted && Boolean(formik.errors.termsAccepted)}>
                  {formik.touched.termsAccepted && formik.errors.termsAccepted}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ textAlign: 'right' }}>
                <Button type="submit" variant={'contained'} color={'primary'}>
                  Termin sichern
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
        <LoadingBackdrop loading={loading} />
      </PaperModal>
    </FHWScreen>
  )
}
