import React from 'react'
import { useReportStyles } from './index'

export type RetrospectionReportResult = {
  name: 'RETROSPECTION'
  report: {
    label: string
    clusterDate: string
    data: {
      parkName: string
      onlineTickets: {
        scanned: {
          total: number
          numAdults: number
          numChildren: number
        }
        notScanned: {
          total: number
          numAdults: number
          numChildren: number
        }
        other: {
          total: number
          numAdults: number
          numChildren: number
        }
      }
      appointments: {
        scanned: {
          total: number
          numAdults: number
          numChildren: number
        }
        notScanned: {
          total: number
          numAdults: number
          numChildren: number
        }
        other: {
          total: number
          numAdults: number
          numChildren: number
        }
      }
    }[]
  }[]
}

export const RetrospectionReport: React.FC<{ reportResult: RetrospectionReportResult }> = ({
  reportResult: { report },
}) => {
  const classes = useReportStyles()
  return (
    <>
      <h2>IST Report</h2>
      {report.map((cluster) => (
        <div key={cluster.clusterDate}>
          <h3>{cluster.label}</h3>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>Parks</th>
                <th colSpan={9}>Ticket Online verkauft</th>
                <th colSpan={9}>Termine FHW Hersteller</th>
              </tr>
              <tr>
                <th></th>
                <th colSpan={3}>Gescannt</th>
                <th colSpan={3}>Nicht gescannt</th>
                <th colSpan={3}>Andere</th>
                <th colSpan={3}>Gescannt</th>
                <th colSpan={3}>Nicht gescannt</th>
                <th colSpan={3}>Andere</th>
              </tr>
              <tr>
                <th></th>
                <th>Gesamt</th>
                <th>Erwachsene</th>
                <th>Kinder</th>
                <th>Gesamt</th>
                <th>Erwachsene</th>
                <th>Kinder</th>
                <th>Gesamt</th>
                <th>Erwachsene</th>
                <th>Kinder</th>
                <th>Gesamt</th>
                <th>Erwachsene</th>
                <th>Kinder</th>
                <th>Gesamt</th>
                <th>Erwachsene</th>
                <th>Kinder</th>
                <th>Gesamt</th>
                <th>Erwachsene</th>
                <th>Kinder</th>
              </tr>
            </thead>
            <tbody>
              {cluster.data.map((park) => (
                <tr key={park.parkName}>
                  <td>{park.parkName}</td>
                  <td>{park.onlineTickets.scanned.total}</td>
                  <td>{park.onlineTickets.scanned.numAdults}</td>
                  <td>{park.onlineTickets.scanned.numChildren}</td>
                  <td>{park.onlineTickets.notScanned.total}</td>
                  <td>{park.onlineTickets.notScanned.numAdults}</td>
                  <td>{park.onlineTickets.notScanned.numChildren}</td>
                  <td>{park.onlineTickets.other.total}</td>
                  <td>{park.onlineTickets.other.numAdults}</td>
                  <td>{park.onlineTickets.other.numChildren}</td>
                  <td>{park.appointments.scanned.total}</td>
                  <td>{park.appointments.scanned.numAdults}</td>
                  <td>{park.appointments.scanned.numChildren}</td>
                  <td>{park.appointments.notScanned.total}</td>
                  <td>{park.appointments.notScanned.numAdults}</td>
                  <td>{park.appointments.notScanned.numChildren}</td>
                  <td>{park.appointments.other.total}</td>
                  <td>{park.appointments.other.numAdults}</td>
                  <td>{park.appointments.other.numChildren}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  )
}
