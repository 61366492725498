import * as Apollo from '@apollo/client'
import { ApolloLink, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getEnvironment } from './envUtils'

// Workaround to get mutable data from apollo hooks, see https://github.com/mbrn/material-table/issues/1979
export const makeResponseDataMutable = <T>(data: T[]): T[] => {
  return data.map((o) => ({ ...o }))
}

export const getCommonQueryBaseOptions = <TQuery, TVars>(): Apollo.QueryHookOptions<TQuery, TVars> => ({
  errorPolicy: 'all',
})

export const DEFAULT_POLLING_INTERVAL = 1000 * 15

const apolloAuthLinkFactory = (getToken: () => string): ApolloLink => {
  return setContext(async (request, { headers }) => {
    const token = getToken()
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }
    } else {
      return { headers }
    }
  })
}

export const getApolloLink = (getToken: () => string): ApolloLink => {
  const httpLink = createHttpLink({
    uri: getEnvironment().GqlEndpoint,
  })

  const authLink = apolloAuthLinkFactory(getToken)
  return authLink.concat(httpLink)
}
