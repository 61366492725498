import React, { useState } from 'react'
import { useGetParksQuery } from '../../../generated/graphql-hooks'
import { GenericDataTable, usePageQueryState } from '../../../components/GenericDataTable'
import { Delete, Edit } from '@material-ui/icons'
import { Park } from '../../../generated/graphql-types'
import { UpdateParkDialog } from '../dialogs/UpdateParkDialog'
import { DeleteParkDialog } from '../dialogs/DeleteParkDialog'
import { useTableState } from './useTableState'
import { DEFAULT_POLLING_INTERVAL } from '../../../utils/apolloUtils'

export const ParksTab: React.FC = () => {
  const [queryState, setQueryState] = usePageQueryState()
  const data = useGetParksQuery({
    pollInterval: DEFAULT_POLLING_INTERVAL,
    variables: { pageQuery: queryState },
  })
  const Parks = data.data?.getParks.parks ?? []

  const {
    editDialogOpen,
    setEditDialogOpen,
    cancelDialogOpen,
    setCancelDialogOpen,
    selectedRow,
    setSelectedRow,
  } = useTableState<Park>()

  return (
    <>
      <GenericDataTable
        data={Parks}
        onPageQueryChange={setQueryState}
        pageQuery={queryState}
        pageInfo={data.data?.getParks.pageInfo}
        isLoading={data.loading}
        columns={[
          { title: 'Park ID', field: 'uuid', hidden: true },
          { title: 'Name', field: 'name' },
          {
            title: 'Straße',
            render: (rowData) => `${rowData.address?.street || ''} ${rowData.address?.streetNumber || ''}`,
          },
          { title: 'Stadt', field: 'address.city' },
          { title: 'Postleitzahl', field: 'address.postalCode' },
        ]}
        title={'Parks'}
        actions={[
          {
            icon: Delete,
            tooltip: 'Löschen',
            onClick: (event, rowData) => {
              setCancelDialogOpen(true)
              setSelectedRow(rowData as Park)
            },
          },
          {
            icon: Edit,
            tooltip: 'Bearbeiten',
            onClick: (event, rowData) => {
              setEditDialogOpen(true)
              setSelectedRow(rowData as Park)
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
      <UpdateParkDialog selectedRow={selectedRow} open={editDialogOpen} handleClose={() => setEditDialogOpen(false)} />
      <DeleteParkDialog
        selectedRow={selectedRow}
        open={cancelDialogOpen}
        handleClose={() => setCancelDialogOpen(false)}
      />
    </>
  )
}
