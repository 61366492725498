import { ViewMode } from '../pages/authenticated/tabs/appointmentsTab/CalendarAndTableView/AppointmentsCalendar'

const dateFormat = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
}

const timeFormat = {
  hour: '2-digit',
  minute: '2-digit',
}

export const serializeDate = (date: Date): string => {
  return date.toISOString()
}

export const deSerializeDate = (date: string): Date => {
  return new Date(date)
}

export const formatDate = (date: Date): string => {
  return date.toLocaleDateString('de-DE', dateFormat)
}

export const formatTime = (date: Date): string => {
  return date.toLocaleTimeString('de-DE', timeFormat)
}

export const getBeginDate = (date: Date, viewMode: ViewMode): Date => {
  let beginDate = new Date(date)
  if (viewMode === 'week') {
    const day = date.getDay()
    const isSunday = day === 0
    const diff = date.getDate() - day + (isSunday ? -6 : 1) // adjust when day is sunday
    beginDate = new Date(beginDate.setDate(diff))
  }
  // set time to 00:00 am to display all appointments of that day
  return new Date(beginDate.setUTCHours(0, 0, 0, 0))
}

export const incrementDate = (date: Date, viewMode: ViewMode, subtract: boolean): Date => {
  let increment =
    viewMode === 'day'
      ? 1000 * 60 * 60 * 24 // one day
      : 1000 * 60 * 60 * 24 * 7 // one week
  if (subtract) {
    increment *= -1
  }
  return new Date(date.getTime() + increment)
}
