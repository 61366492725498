import React, { useEffect, useRef } from 'react'
import { Box, Typography } from '@material-ui/core'
import QrReader from 'react-qr-reader'
import { useNotifications } from '../../../../utils/notificationUtils'
import { useCheckInTicketMutation, useGetTicketLazyQuery } from '../../../../generated/graphql-hooks'
import { LoadingBackdrop } from '../../../../components/LoadingBackdrop'

export const TicketScan: React.FC = () => {
  const { showError, showWarning, showSuccess, splashSuccess } = useNotifications()
  const lastCheckedInUuid = useRef('')
  const processing = useRef(false)
  const [getTicket, { data, loading, error, called }] = useGetTicketLazyQuery({ fetchPolicy: 'no-cache' })
  const [checkInTicket, { loading: checkInLoading }] = useCheckInTicketMutation({
    refetchQueries: ['getTicket', 'getTickets'],
  })

  const handleScan = (qrData: string | null): void => {
    if (!qrData) {
      return
    }

    if (processing.current) {
      return
    }

    getTicket({
      variables: {
        uuid: qrData,
      },
    })
  }

  const doCheckIn = (ticketUuid: string) => {
    if (processing.current) {
      return
    }
    if (lastCheckedInUuid.current === ticketUuid) {
      showWarning('Sie haben dieses Ticket bereits eingecheckt. Ein zweites Einchecken wurde verhindert.')
      return
    }
    processing.current = true
    checkInTicket({
      variables: {
        uuid: ticketUuid || '',
      },
    })
      .then((result) => {
        if (result.data?.checkinTicket.success) {
          lastCheckedInUuid.current = ticketUuid
          return splashSuccess('Der Einlass des Besuchers wurde erfolgreich registriert.', 'Einlass erfolgreich')
        }
        return showError('Fehler beim Einlass, prüfen Sie das Ticket und versuchen Sie es erneut.')
      })
      .catch((error) => {
        console.error(error)
        return showError('Fehler beim Einlass, prüfen Sie das Ticket und versuchen Sie es erneut.')
      })
      .finally(() => (processing.current = false))
  }

  useEffect(() => {
    if (loading || processing.current || !called) {
      return
    }
    if (!data?.getTicket?.uuid || error) {
      console.error(error || 'ticket response empty')
      showWarning('Ticket konnte nicht gefunden werden')
      return
    }

    doCheckIn(data.getTicket.uuid)
  }, [data?.getTicket?.uuid, error, loading, called])

  const handleError = (error: any): void => {
    console.error(error)
    showError('Fehler beim Zugriff auf die Kamera')
  }

  return (
    <>
      <Typography variant={'h6'} component={'h2'}>
        Ticket QR Code scannen
      </Typography>
      <Box>
        <QrReader
          delay={1000}
          style={{ height: 240, width: 320, margin: '25px auto' }}
          onError={handleError}
          onScan={handleScan}
        />
      </Box>
      <LoadingBackdrop loading={loading || checkInLoading} />
    </>
  )
}
