import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { debounce, isEqual, uniqBy } from 'lodash'
import { useFormik } from 'formik'
import { Chip } from '@material-ui/core'

export interface SelectOption {
  label: string
  value: string
}

type Data = Record<string, unknown> | undefined | null

interface QueryResult<T extends Data> {
  loading: boolean
  data: T
}

interface RemoteSelectProps<T extends Data> {
  label: string
  useQuery: (search?: string) => QueryResult<T>
  queryResultToOptions: (queryResult: T) => SelectOption[]
  name: string
  multiple?: boolean
  className?: string
  formik: Pick<ReturnType<typeof useFormik>, 'errors' | 'setFieldTouched' | 'setFieldValue' | 'touched' | 'values'>
  error?: boolean
  helperText?: string | false
}

export const RemoteSelect = <T extends Data>({
  label,
  error,
  useQuery,
  queryResultToOptions,
  helperText,
  formik,
  name,
  className,
  multiple,
}: PropsWithChildren<RemoteSelectProps<T>>): ReactElement => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [textInputValue, setTextInputValue] = useState('')

  const handleSearchChange = useCallback(
    debounce((search: string) => {
      setSearch(search)
    }, 750),
    []
  )

  const handleTextInputValueChange = (input: string) => {
    setTextInputValue(input)
    handleSearchChange(input)
  }

  const { data, loading } = useQuery(search)

  const queryResultOptions = queryResultToOptions(data)

  const [options, setOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    if (loading) {
      return
    }

    const newSelectOptions = queryResultToOptions(data)

    const newOptions = uniqBy([...options, ...newSelectOptions], (d) => d.value)

    if (!isEqual(newOptions, options)) {
      setOptions(newOptions)
    }
  }, [queryResultOptions, options, loading])

  const handleAutocompleteChange = (selected: SelectOption | SelectOption[] | null) => {
    if (!selected) {
      return
    }

    if (!Array.isArray(selected)) {
      console.log('set value', { name, value: selected.value })
      formik.setFieldTouched(name)
      formik.setFieldValue(name, selected.value)
      return
    }

    const arrayValue = selected.map((x) => x.value)

    if (formik && name) {
      formik.setFieldTouched(name)
      formik.setFieldValue(name, arrayValue)
    }
  }

  const value = formik.values[name]

  if (multiple && !Array.isArray(value)) {
    console.warn(`got multiple but value is no array in ${name}`)
  }

  const getOptionSelected = (option: SelectOption, value: SelectOption | SelectOption[]) => {
    if (Array.isArray(value)) {
      return value.filter((x) => x.value === option.value).length > 0
    }
    return option.value === value.value
  }

  const autocompleteValue = multiple
    ? options.filter((x) => value.includes(x.value))
    : options.find((x) => x.value === value) || null

  useEffect(() => {
    if (autocompleteValue && !multiple) {
      setTextInputValue((autocompleteValue as SelectOption).label)
    }
  }, [multiple, autocompleteValue])

  const renderOption = (selected: SelectOption | SelectOption[]) => (
    <div>
      {Array.isArray(selected) ? (
        selected.map((option) => <Chip key={option.value} label={option.label} />)
      ) : (
        <Chip key={selected.value} label={selected.label} />
      )}
    </div>
  )

  return (
    <Autocomplete
      className={className}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={autocompleteValue}
      multiple={multiple}
      onChange={(e, val) => handleAutocompleteChange(val as SelectOption | SelectOption[] | null)}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      options={options}
      loading={loading}
      noOptionsText={'Kein Suchergebnis'}
      getOptionLabel={(option) => option.label}
      inputValue={textInputValue}
      onInputChange={(event, userInput, reason) => {
        if (event) {
          handleTextInputValueChange(userInput)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={formik && name ? formik.touched[name] && Boolean(formik.errors[name]) : error}
          helperText={formik && name ? formik.touched[name] && formik.errors[name] : helperText}
          inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
