import React from 'react'
import { useReportStyles } from './index'

export type VisitorReportResult = {
  name: 'VISITOR'
  report: {
    label: string
    clusterDate: string
    data: {
      [postalCode: string]: {
        [manufacturer: string]: number
      }
    }
  }[]
}

export const VisitorReport: React.FC<{ reportResult: VisitorReportResult }> = ({ reportResult: { report } }) => {
  const classes = useReportStyles()
  return (
    <>
      <h2>Spezialreport (3)</h2>
      {report.map((cluster) => (
        <div key={cluster.clusterDate}>
          <h3>{cluster.label}</h3>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>PLZ</th>
                <th>Hersteller</th>
                <th>Besucher</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(cluster.data).map((postalCode) =>
                Object.keys(cluster.data[postalCode]).map((manufacturer) => (
                  <tr key={`${postalCode}_${manufacturer}`}>
                    <th>{postalCode}</th>
                    <td>{manufacturer}</td>
                    <td>{cluster.data[postalCode][manufacturer]}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ))}
    </>
  )
}
