import { getEnvironment } from './envUtils'
import { ReportResult } from '../pages/authenticated/tabs/ReportsTab'
import { ForecastReport } from '../pages/authenticated/tabs/ReportsTab/ForecastReport'

const throwResponseError = (status: string | number, body?: string) => {
  throw new Error(`Error fetching from backend, status: ${status}, message: "${body || '<empty response>'}"`)
}

export const getReport = async (
  token: string,
  reportId: number,
  beginDate: string,
  endDate: string,
  period: string
): Promise<ReportResult> => {
  return fetch(`${getEnvironment().RestEndpoint}/internal/reports/${reportId}/${beginDate}/${endDate}/${period}/`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }).then(async (res) => {
    try {
      return await res.json()
    } catch (bodyParseError) {
      throwResponseError(res.status)
    }
    if (!res.ok) {
      throwResponseError(res.status)
    }
  })
}
