import { AppointmentStatus, TicketType } from '../generated/graphql-types'

export const labels = {
  enums: {
    AppointmentStatus: {
      [AppointmentStatus.ChangeRequested]: 'Änderung angefragt',
      [AppointmentStatus.Registered]: 'Registriert',
      [AppointmentStatus.Created]: 'Erstellt',
      [AppointmentStatus.InvitationSent]: 'Einladung verschickt',
      [AppointmentStatus.Canceled]: 'Abgesagt',
      [AppointmentStatus.Missed]: 'Termin verpasst',
    },
    TicketType: {
      [TicketType.GiftCard]: 'Gutschein',
      [TicketType.Cash]: 'Barkauf',
      [TicketType.Appointment]: 'Termin',
      [TicketType.Online]: 'Online',
      [TicketType.UnknownPark]: 'Fremdpark',
      [TicketType.Unlimited]: 'Lebenslang',
    },
  },
}
