export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export enum PageSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Query = {
  __typename?: 'Query'
  getAppointments: AppointmentsPage
  getAppointment?: Maybe<Appointment>
  getSalesRepresentatives: SalesRepresentativesPage
  getSalesRepresentative?: Maybe<SalesRepresentative>
  getManufacturers: ManufacturersPage
  getManufacturersByPark: Array<Manufacturer>
  getManufacturer?: Maybe<Manufacturer>
  getParks: ParksPage
  getParksByManufacturer: Array<Park>
  getPark?: Maybe<Park>
  getCenterManagers: CenterManagersPage
  getCenterManager?: Maybe<CenterManager>
  getTickets: TicketsPage
  getTicketsForAdmin: TicketsPage
  getTicket?: Maybe<Ticket>
}

export type QueryGetAppointmentsArgs = {
  pageQuery: PageQuery
  dateQuery?: Maybe<DateQuery>
  showAll?: Maybe<Scalars['Boolean']>
}

export type QueryGetAppointmentArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetSalesRepresentativesArgs = {
  pageQuery: PageQuery
}

export type QueryGetSalesRepresentativeArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetManufacturersArgs = {
  pageQuery: PageQuery
}

export type QueryGetManufacturersByParkArgs = {
  parkUuid: Scalars['String']
}

export type QueryGetManufacturerArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetParksArgs = {
  pageQuery: PageQuery
  manufacturerUuid?: Maybe<Scalars['String']>
}

export type QueryGetParksByManufacturerArgs = {
  manufacturerUuid: Scalars['String']
}

export type QueryGetParkArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetCenterManagersArgs = {
  pageQuery: PageQuery
}

export type QueryGetCenterManagerArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type QueryGetTicketsArgs = {
  pageQuery: PageQuery
  dateQuery?: Maybe<DateQuery>
}

export type QueryGetTicketsForAdminArgs = {
  pageQuery: PageQuery
  dateQuery?: Maybe<DateQuery>
}

export type QueryGetTicketArgs = {
  uuid?: Maybe<Scalars['String']>
}

export type PageQuery = {
  pageSize: Scalars['Int']
  page: Scalars['Int']
  sort?: Maybe<PageSort>
  search?: Maybe<Scalars['String']>
}

export type PageSort = {
  dir: PageSortDirection
  prop: Scalars['String']
}

export type DateQuery = {
  beginDate?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
}

export type AppointmentsPage = {
  __typename?: 'AppointmentsPage'
  pageInfo: PageInfo
  appointments: Array<Appointment>
}

export type Appointment = {
  __typename?: 'Appointment'
  uuid: Scalars['String']
  date?: Maybe<Scalars['String']>
  status?: Maybe<AppointmentStatus>
  changeRequestComment?: Maybe<Scalars['String']>
  visitor?: Maybe<Visitor>
  salesRepresentative?: Maybe<SalesRepresentative>
  bill?: Maybe<Scalars['Boolean']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  totalCount: Scalars['Int']
  totalPages: Scalars['Int']
  elementsInPage: Scalars['Int']
}

export enum AppointmentStatus {
  Created = 'CREATED',
  InvitationSent = 'INVITATION_SENT',
  ChangeRequested = 'CHANGE_REQUESTED',
  Registered = 'REGISTERED',
  Canceled = 'CANCELED',
  Missed = 'MISSED',
}

export type Visitor = {
  __typename?: 'Visitor'
  uuid: Scalars['String']
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  address?: Maybe<Address>
  numChildren?: Maybe<Scalars['Int']>
  numAdults?: Maybe<Scalars['Int']>
  appointments?: Maybe<Array<Maybe<Appointment>>>
}

export type Address = {
  __typename?: 'Address'
  street?: Maybe<Scalars['String']>
  streetNumber?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
}

export type SalesRepresentative = {
  __typename?: 'SalesRepresentative'
  uuid: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  manufacturer?: Maybe<Manufacturer>
  park?: Maybe<Park>
}

export type Manufacturer = {
  __typename?: 'Manufacturer'
  uuid: Scalars['String']
  name: Scalars['String']
  address?: Maybe<Address>
}

export type Park = {
  __typename?: 'Park'
  uuid: Scalars['String']
  name?: Maybe<Scalars['String']>
  address?: Maybe<Address>
}

export type SalesRepresentativesPage = {
  __typename?: 'SalesRepresentativesPage'
  pageInfo: PageInfo
  salesRepresentatives: Array<SalesRepresentative>
}

export type ManufacturersPage = {
  __typename?: 'ManufacturersPage'
  pageInfo: PageInfo
  manufacturers: Array<Manufacturer>
}

export type ParksPage = {
  __typename?: 'ParksPage'
  pageInfo: PageInfo
  parks: Array<Park>
}

export type CenterManagersPage = {
  __typename?: 'CenterManagersPage'
  pageInfo: PageInfo
  centerManagers: Array<CenterManager>
}

export type CenterManager = {
  __typename?: 'CenterManager'
  uuid: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  parks: Array<Park>
}

export type TicketsPage = {
  __typename?: 'TicketsPage'
  pageInfo: PageInfo
  tickets: Array<Ticket>
}

export type Ticket = {
  __typename?: 'Ticket'
  uuid: Scalars['String']
  remainingEntries: Scalars['Int']
  expectedEntryDate?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  type: TicketType
  visitor?: Maybe<Visitor>
  isValid: Scalars['Boolean']
  hasIncompleteVisitorData: Scalars['Boolean']
  park?: Maybe<Park>
}

export enum TicketType {
  Unlimited = 'UNLIMITED',
  GiftCard = 'GIFT_CARD',
  Online = 'ONLINE',
  Appointment = 'APPOINTMENT',
  Cash = 'CASH',
  UnknownPark = 'UNKNOWN_PARK',
}

export type Mutation = {
  __typename?: 'Mutation'
  createAppointment: Appointment
  updateAppointment: Appointment
  registerForAppointment: Appointment
  requestChangeForAppointment: Appointment
  cancelAppointment: Appointment
  markAppointmentAsMissed: Appointment
  resendAppointmentInvitation: Appointment
  updateVisitor: Visitor
  createManufacturer: Manufacturer
  updateManufacturer: Manufacturer
  deleteManufacturer: Manufacturer
  createSalesRepresentative: SalesRepresentative
  updateSalesRepresentative: SalesRepresentative
  deleteSalesRepresentative: SalesRepresentative
  resendRegistrationEmailSalesRepresentative: SalesRepresentative
  createCenterManager: CenterManager
  updateCenterManager: CenterManager
  deleteCenterManager: CenterManager
  createPark: Park
  updatePark: Park
  deletePark: Park
  createTicket: Ticket
  printTicketAsPdf: Scalars['String']
  sendTicketEmail: Scalars['Boolean']
  checkinTicket: TicketCheckinResult
}

export type MutationCreateAppointmentArgs = {
  appointment: CreateAppointment
}

export type MutationUpdateAppointmentArgs = {
  appointment: UpdateAppointment
}

export type MutationRegisterForAppointmentArgs = {
  appointment: RegisterForAppointment
}

export type MutationRequestChangeForAppointmentArgs = {
  changeRequest: RequestChangeForAppointment
}

export type MutationCancelAppointmentArgs = {
  uuid: Scalars['String']
}

export type MutationMarkAppointmentAsMissedArgs = {
  uuid: Scalars['String']
}

export type MutationResendAppointmentInvitationArgs = {
  uuid: Scalars['String']
}

export type MutationUpdateVisitorArgs = {
  visitor: UpdateVisitor
}

export type MutationCreateManufacturerArgs = {
  manufacturer: CreateManufacturer
}

export type MutationUpdateManufacturerArgs = {
  manufacturer: UpdateManufacturer
}

export type MutationDeleteManufacturerArgs = {
  manufacturer: DeleteManufacturer
}

export type MutationCreateSalesRepresentativeArgs = {
  salesRepresentative: CreateSalesRepresentative
}

export type MutationUpdateSalesRepresentativeArgs = {
  salesRepresentative: UpdateSalesRepresentative
}

export type MutationDeleteSalesRepresentativeArgs = {
  salesRepresentative: DeleteSalesRepresentative
}

export type MutationResendRegistrationEmailSalesRepresentativeArgs = {
  salesRepresentative: ResendRegistrationEmailSalesRepresentative
}

export type MutationCreateCenterManagerArgs = {
  centerManager: CreateCenterManager
}

export type MutationUpdateCenterManagerArgs = {
  centerManager: UpdateCenterManager
}

export type MutationDeleteCenterManagerArgs = {
  centerManager: DeleteCenterManager
}

export type MutationCreateParkArgs = {
  park: CreatePark
}

export type MutationUpdateParkArgs = {
  park: UpdatePark
}

export type MutationDeleteParkArgs = {
  park: DeletePark
}

export type MutationCreateTicketArgs = {
  ticket: CreateTicket
}

export type MutationPrintTicketAsPdfArgs = {
  uuid: Scalars['String']
}

export type MutationSendTicketEmailArgs = {
  uuid: Scalars['String']
}

export type MutationCheckinTicketArgs = {
  uuid: Scalars['String']
}

export type CreateAppointment = {
  email: Scalars['String']
  date: Scalars['String']
  salesRepresentativeUuid: Scalars['String']
  bill: Scalars['Boolean']
}

export type UpdateAppointment = {
  uuid: Scalars['String']
  date?: Maybe<Scalars['String']>
}

export type RegisterForAppointment = {
  uuid: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  numAdults: Scalars['Int']
  numChildren: Scalars['Int']
  address: AddressInput
}

export type AddressInput = {
  street: Scalars['String']
  streetNumber: Scalars['String']
  postalCode: Scalars['String']
  city: Scalars['String']
}

export type RequestChangeForAppointment = {
  uuid: Scalars['String']
  comment: Scalars['String']
}

export type UpdateVisitor = {
  uuid: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  address: AddressInput
  numChildren: Scalars['Int']
  numAdults: Scalars['Int']
}

export type CreateManufacturer = {
  name: Scalars['String']
  address?: Maybe<AddressInput>
}

export type UpdateManufacturer = {
  uuid: Scalars['String']
  name: Scalars['String']
  address?: Maybe<AddressInput>
}

export type DeleteManufacturer = {
  uuid: Scalars['String']
}

export type CreateSalesRepresentative = {
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  manufacturerUuid: Scalars['String']
  parkUuid: Scalars['String']
}

export type UpdateSalesRepresentative = {
  uuid: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  manufacturerUuid: Scalars['String']
  parkUuid: Scalars['String']
}

export type DeleteSalesRepresentative = {
  uuid: Scalars['String']
}

export type ResendRegistrationEmailSalesRepresentative = {
  uuid: Scalars['String']
}

export type CreateCenterManager = {
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  parkUuids: Array<Scalars['String']>
}

export type UpdateCenterManager = {
  uuid: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  name: Scalars['String']
  parkUuids: Array<Scalars['String']>
}

export type DeleteCenterManager = {
  uuid: Scalars['String']
}

export type CreatePark = {
  name: Scalars['String']
  address?: Maybe<AddressInput>
}

export type UpdatePark = {
  uuid: Scalars['String']
  name: Scalars['String']
  address?: Maybe<AddressInput>
}

export type DeletePark = {
  uuid: Scalars['String']
}

export type CreateTicket = {
  comment: Scalars['String']
  type: TicketType
}

export type TicketCheckinResult = {
  __typename?: 'TicketCheckinResult'
  uuid: Scalars['String']
  success: Scalars['Boolean']
}
